<template>
  <div class="home">
    <homeComp></homeComp>
  </div>
</template>

<script>
// @ is an alias to /src
import homeComp from '@/components/homeComp.vue'

export default {
  name: 'HomeView',
  components: {
    homeComp
  }
}
</script>

<style scoped>
  .home{
    display: flex;
    justify-content: center;
    padding-top: 10%;
  }
</style>
