<template>
    <div class="home">
      <firststepGrooming></firststepGrooming>
    </div>
</template>
  
  <script>
  // @ is an alias to /src
  import firststepGrooming from '@/components/firststepGrooming.vue'
  
  export default {
    name: 'FirststepGroomingView',
    components: {
        firststepGrooming
    }
  }
  </script>
  
  <style scoped>
    .home{
      display: flex;
      justify-content: center;
      /* padding-top: 10%; */
      
    }
  </style>
  