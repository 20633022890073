<template>
    <div>
        <div class="page_loader_cont" v-if="page_loader">
            <div class="spinner_height">
                <spinner-circle :black="true"></spinner-circle>
            </div>
        </div>
        <template v-else-if="role === 'admin'  || role === 'vet'">
            <div class="clinc_cont">
                <div class="right">
                    <div class="row heading_cont">
                        <h3 class="vet_heading">Current Client Number</h3>
                        <div class="waiting_num">{{ waiting_line }} waiting</div>
                    </div>
                    
                    <div class="column btn_home_cont">
                        <div class="row book_num_cont">
                            <div class="book_num" v-if="current_client_num !== null ">{{ current_client_num }}</div>
                            <div class="book_num" v-else>you have no waiting!</div>

                        </div>
                        <button class="btn btn_first_grooming book_now lets_ next_vet_admin" @click="next()">
                            <div class="loading" v-if="loading" >
                                <Spinner/>
                            </div>
                            <div class="btn_txt" v-else >Next</div>
                        </button>
                        <div class="today_note">Remember, treating animals is more difficult than treating humans, because animals don't tell you where it hurts... That's why I'm proud of you :)</div>
                    </div>
                </div>
            </div>
        </template>
        <div v-else>error.</div>
    </div>
</template>

<script>
import request from '../axios_request';
import SpinnerCircle from './SpinnerCircle.vue';
import Spinner from '@/components/Spinner.vue';
import { mapState, mapActions } from 'vuex';
import SocketioService from '@/services/socketio.service.js';

export default {
    components: { 
        SpinnerCircle,
        Spinner
    },
    name: 'VetHome',
    data: function () {
        return {
            page_loader: true,
            loading: false,
            waiting_line: null,
            current_client_num: null,
            current_client_db_id: null
        }
    },
    created() {
        SocketioService.socket.on('vet_line_update', (data) => {
            console.log(data);
            //update current and line
            request
                .post('/api/vet_line/line')
                .then((res)=>{
                    if( res.status === 200 ){
                        this.waiting_line = res.data;
                    }else{
                        console.log('error  ..17');
                    }
                    request
                        .post('/api/vet_line/current_client')
                        .then((res_current_client)=>{
                            console.log('hola', res_current_client);
                            if( res_current_client.status === 200 ){
                                if( res_current_client.data === null ){
                                    this.current_client_num = null;
                                    this.current_client_db_id = null;
                                }else{
                                    this.current_client_num = res_current_client.data.num;
                                    this.current_client_db_id = res_current_client.data.id;
                                }
                            }else{
                            console.log('error  ..18');
                            }
                        })
                        .catch((err_current)=>{
                            console.log(err_current);
                        });
                })
                .catch((err)=>{
                    console.log(err);
                });
            });
    },
    mounted() {
        request
            .post('/api/vet_line/line')
            .then((res)=>{
                if( res.status === 200 ){
                    console.log(res.data);
                    this.waiting_line = res.data;
                }else{
                    console.log('error  ..1');
                }
                request
                    .post('/api/vet_line/current_client')
                    .then((res_current_client)=>{
                        if( res_current_client.status === 200 ){
                            if( res_current_client.data === null ){
                                this.current_client_num = null;
                                this.current_client_db_id = null;
                            }else{
                                this.current_client_num = res_current_client.data.num;
                                this.current_client_db_id = res_current_client.data.id;
                            }
                        }else{
                        console.log('error  ..1');
                        }
                        this.page_loader = false;
                    })
                    .catch((err_current)=>{
                        console.log(err_current);
                        this.page_loader = false;
                    });
            })
            .catch((err)=>{
                console.log(err);
                this.page_loader = false;
            });
    },
    computed: {
        ...mapState([
            'role',
            'logged_in',
            // 'branch_id',
            // 'socket'
        ]),
    },
    methods: {
        ...mapActions([
            "role_",
        ]),
        next(){
            if( this.loading === true ){
                return;
            }else{
                if( this.current_client_num === null ){
                    return;
                }
                this.loading = true;
                var data = {id: this.current_client_db_id}
                request
                    .post('/api/vet_line/next_client', data)
                    .then((res_next_client)=>{
                        if( res_next_client.status === 200 ){
                            console.log(res_next_client.data);
                        }else{
                            console.log('error  ..11');
                        }
                        this.loading = false;
                    })
                    .catch((err_next)=>{
                        console.log(err_next);
                        this.loading = false;
                    });

            }
        }
    }
}
</script>

<style >
.spinner_cont_page{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.spinner_cont_page .spinner_circle{
    height: 100px;
}



.book_num_cont{
    border-radius: 5px;
    border: 3px solid #317BEB;
    padding: 10px 15px;
    width: 100%;
    height: 135px;
    align-items: center;
    justify-content: center;
    border: 5px solid #317BEB;
    background-color: #317beb17;
    height: 250px;
}

.book_num{
    font-size: 45px;
    font-weight: bold;
    color: #317BEB;
}

.vet_waiting{
    margin: 7px 0px;
    font-weight: 500;
}

.right{
    width: 100%;
    max-width: 500px;
}

/* .lets_{
    margin-top: 15px;
    width: 35% !important;
    margin-left: 65%;
} */

.clinc_cont{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.next_vet_admin{
    width: 30%;
    align-self: flex-end;
    margin-top: 15px;
    font-size: 25px;
}

.heading_cont{
    justify-content: space-between;
    align-items: baseline;
}

.waiting_num{
    font-weight: 500;
}

.today_note{
    margin-top: 20px;
    color: #565656;
    font-weight: 500;
    margin-bottom: 50px;
}
</style>