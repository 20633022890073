<template>
    <div class="o-column c-admin_signin">
        <img class="c-admin_signin_logo" :src="linkk + '/pharaohfrog.png'" alt="logo">
        <form method="POST" class="column">
            <input type="text" placeholder="Username" name="name" v-model="name">
            <input type="password" placeholder="Password" name="password" v-model="password">
            <button @click.prevent="login_front()" class="column flex_center btnn">
                <spinner v-if="loading"></spinner>
                <div v-else>Login</div>
            </button>
            <div v-if="error" class="err_single_line">{{ error }}</div>
        </form>
    </div>
</template>

<script>
// @ is an alias to /src
import Spinner from '@/components/Spinner.vue'
import { mapState, mapActions } from 'vuex';
import request from '../axios_request';
// import io from "socket.io-client";

export default {
    name: 'Login_',
    components: {
        Spinner
    },
    data: function () {
        return {
            name: null,
            password: null,
            loading: false,
            error: null,
            balck: false,
            linkk: process.env.VUE_APP_SERVER_ENDPOINT
        };
    },
    methods: {
        ...mapActions([
            "set_logged_in",
            "set_socket",
            "role_",
            'admin_name_',
            'company_logo_'
        ]),
        login_front(){
            if( this.loading ){
                return;
            }

            this.loading = true;
            this.error = false;

            if( !this.name || this.name.length <= 0 ){
                this.error = 'must enter name.';
                this.loading = false;
                return;
            }

            if( !this.password || this.password.length <= 0 ){
                this.error = 'must enter password.';
                this.loading = false;
                return;
            }

            if( this.password && this.password.length < 5 ){
                this.error = 'password must be more then 5 letters.';
                this.loading = false;
                return;
            }


            var data = {
                name: this.name,
                password: this.password
            }

            request.post('/api/system/login', data)
                .then((response)=>{
                    if( response.status === 200 ){
                        localStorage.setItem('token', response.data.token);
                        this.set_logged_in(true);
                        this.role_(response.data.role);
                        this.admin_name_(response.data.name);
                        this.company_logo_(response.data.img);
                        // this is make u dont refreash when u logout and back in
                        request.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
                        if( this.role === 'customer' ){
                            this.$router.push({name: 'home'});
                        }else if( this.role === 'crew' ){
                            this.$router.push({name: 'admin'});
                        }else if( this.role === 'super_admin' ){
                            this.$router.push({name: 'admin'});
                        }
                        
                        

                        // this.set_socket(io.connect("http://localhost:3000",
                        // {
                        //     auth: {
                        //     token: response.data.token,
                        //     },
                        // }
                        // ));
                    }else{
                        this.error = 'error 1';
                    }
                    this.loading = false;
                }).catch(e => {
                    console.log(e);
                    this.loading = false;
                    this.error = 'error 2';
                });
        }
    },
    computed: {
        ...mapState([
            'role',
            // 'logged_in',
            // 'branch_id',
            // 'socket'
        ]),
    }
}
</script>

<style scoped>
/* .login_cont{
    display: flex;
    justify-content: center;
    margin-top: 5%;
}

.login_cont img{
    width: 100%;
    max-width: 250px;
    margin: auto;
    display: flex;
    margin-bottom: 10px;
} */


.c-admin_signin{
    width: 100%;
    max-width: 350px;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.c-admin_signin img{
    height: 170px;
    margin-bottom: 10px;
}

.c-admin_signin_logo{
    margin-bottom: 10px;
}

form{
    width: 100%;
}

input{
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 18px;
    transition: 0.1s;
    outline: none;
    padding: 10px 13px;
    margin-bottom: 10px;
}

button{
    background-color: #317BEB;
    border: 0px;
    padding: 7px 0px;
    color: #F2F2F2;
    font-size: 18px;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500;
    border-radius: 3px;
    cursor: pointer;
    text-align: center;
}

input:focus {
    border: 1px solid #317BEB;
    /* border-right: 0px; */
}

.btnn{
    align-items: center;
    width: 100%;
}


.err_single_line{
    color:red;
    font-size: 17px;
}
</style>