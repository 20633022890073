<template>
  <div class="home">
    <!-- <home-logged v-if="logged_in === true"></home-logged>
    <home-not-logged v-else-if="logged_in === false"></home-not-logged>
    <test v-else></test> -->
    <home-logged ></home-logged>
  </div>
</template>

<script>
import { mapState  } from 'vuex';
// @ is an alias to /src
// import HomeNotLogged from '@/components/HomeNotLogged.vue'
import HomeLogged from '@/components/HomeLogged.vue'
// import Test from '@/components/Test.vue'

export default {
  name: 'Home_',
  components: {
    // HomeNotLogged,
    HomeLogged,
    // Test
  },
  computed: {
    ...mapState([
      'logged_in',
      'role'
    ]),
  }
}
</script>