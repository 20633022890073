<template>
    <div class="home">
      <fifthstepGrooming></fifthstepGrooming>
    </div>
</template>
  
<script>
  // @ is an alias to /src
  import fifthstepGrooming from '@/components/fifthstepGrooming.vue'
  
  export default {
    name: 'FifthstepGroomingView',
    components: {
        fifthstepGrooming
    }
  }
</script>
  
<style scoped>
    .home{
      display: flex;
      justify-content: center;
      padding-top: 5%;
      
    }
</style>
  