<template>
    <div class="row home_comp_cont ">
        <div class="page_loader_cont" v-if="page_loader">
            <div class="spinner_height">
                <spinner-circle :black="true"></spinner-circle>
            </div>
        </div>
        <div v-if="role && role === 'crew' && logged_in === true && page_loader === false">
            <h2>Long time no see</h2>
            <div class="sub_title">Clients last time they were here from 2 week or more</div>
            <div v-if=" sessions === '' || !sessions " ></div>
          <div v-else-if=" sessions && Array.isArray(sessions) && sessions.length === 0 " class="no_result no_result_center">you dont have any lost clients.</div>
          <div v-else>
              <div class="scroll_y">
                  <div class="result_table_cont " v-if="sessions && Array.isArray(sessions) && sessions.length > 0">
                      <table class="c-admin_table"><!--style="direction: ltr"-->
                          <tr class="not_hover">
                              <!-- <th>#</th> -->
                              <th>Parent</th>
                              <th>Phone</th>
                              <!-- <th>Name</th>
                              <th>Type</th>
                              <th>Breed</th>
                              <th>Groomer</th> -->
                              <!-- <th>Status</th> -->
                              
                              <!-- <th v-if="company === 'pets_club'">Total</th> -->
                              <th>Last time seen</th>
                              <!-- <th></th> -->
                              <!-- <th v-if="company !== 'pets_club'"></th> -->
                          </tr>

                          <tr v-for="(session) in sessions" :key="session.id" :class="`tr_${session.status}`" >
                              <!-- <td>{{ table_num(i) }}</td> -->
                              <td  class="parent_name">{{ session.parent_name }}</td>
                              <td  >{{ session.parent_phone }}</td>
                              <!-- <td >{{ session.pet_name }}</td>
                              <td >{{ session.pet_type }}</td>
                              <td>{{ session.pet_breed }}</td>
                              <td >{{ session.groomer_name }}</td> -->
                              <!-- <td>{{ session.status }}</td> -->
                              <!-- <td v-if="company === 'pets_club'">{{ session.services_total }}</td> -->
                              <td>{{ session.createdAt }}</td>
                              <!-- <td class="manage">details</td> -->
                              <!-- <td v-if="company !== 'pets_club'"  class="manage_td">
                                  <div class="manage" @click="manage_btn(i)">
                                      Manage
                                  </div>
                                  
                              </td> -->
                          </tr>
                      </table>
                  </div>
              </div>
          </div>
        </div>

    </div>
</template>

<script>
import request from '../axios_request';
// FirststepGroomingView(customer_acc), login(not_logged)
// adminHome(crew_acc), 
import SpinnerCircle from '@/components/SpinnerCircle.vue';
// import SocketioService from '@/services/socketio.service.js';
import { mapState, mapActions } from 'vuex';
// import { useTimeAgo } from '@vueuse/core';


export default {
    name: "AdminLongTimeNoSeeComp",
    components: {
        SpinnerCircle,
        // FirststepGroomingView
    },
    data: function () {
        return {
            page_loader: true,
            visible: false,
            waiting_branchs: null,
            d_company: process.env.VUE_APP_FUCK_ZIGGY,
            sessions: null,
            show_det: true
        }
    },
    mounted() {
        if( localStorage.getItem('token') ){
            var token = localStorage.getItem('token');
            request.post('/api/system/is_logged', {token})//, config
            .then((response)=>{
            // console.log(response);
                if( response.status === 200 ){
                    // console.log(response.data);
                    this.set_logged_in(true);
                    this.role_(response.data.role);
                    this.admin_name_(response.data.name);
                    this.company_(response.data.company);
                    if( this.company === this.d_company ){
                        this.$router.push({name: 'admin_login'});
                    }
                    //new code here
                    request.post('/api/session/long_time_no_see')
                        .then((r)=>{
                            r.data.forEach((sess_) =>{
                              var old_created_at = sess_.createdAt;
                              let dateStr =new Date(old_created_at)
                              
                                // sess_.createdAt = useTimeAgo(old_created_at);
                                sess_.createdAt = dateStr.toLocaleDateString();
                            });
                            // console.log(r.data);
                            this.sessions = r.data;
                            this.page_loader = false;
                        })
                        .catch((e) => {
                            console.log(e);
                        });


                }else{
                    //not logged
                    this.set_logged_in(false);
                    this.role_(null);
                    localStorage.clear();
                    this.$router.push({name: 'admin_login'});
                }

            }).catch((e) => {
                console.log(e);
                this.set_logged_in(false);
                this.role_(null);
                localStorage.clear();
                this.$router.push({name: 'admin_login'});
            });


        }else{
            this.set_logged_in(false);
            this.role_(null);
            localStorage.clear();
            this.$router.push({name: 'admin_login'});
        }


    },
    methods: {
        ...mapActions([
            "role_",
            "set_logged_in",
            'admin_name_',
            'company_'
        ]),
    //     table_num(i){
    //       // if( i === 0 ){
    //       //     return this.sessions.length;
    //       // }else if( (i + 1) === this.sessions.length ){
    //       //     return 1;
    //       // }else{
    //       //     return i + 1;
    //       // }
    //       return this.sessions.length - i;
    //   },
    close_det(){
      this.show_det = false;
    }
    },
    computed: {
        ...mapState([
            'role',
            'logged_in',
            'company'
            // 'branch_id',
            // 'socket'
        ]),
    }
}
</script>


<style scoped>
h2{
    margin-bottom: 0px;
}

.sub_title{
  margin-bottom: 15px;
}
.branches_cont{
    flex-wrap: wrap;
}
.branch_card{
    min-width: 150px;
    height: 150px;
    background-color: white;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.08);
    padding: 10px;
    border-radius: 5px;
    font-size: 27px;
    font-weight: 400;
    margin-right: 15px;
    margin-top: 15px;
}

hr{
    width: 100%;
    margin: 0px;
    border: 1px solid #ccc;
}

.branch_name{
    font-size: 30px;
    font-weight: 500;
}



.c-admin_order_count_number_count{
  width: 300px;
  height: 150px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.08)
}

.c-admin_order_count_number_count{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #006837;
  font-size: 100px;
  font-weight: 500
}

.c-admin_order_count_number_count span{
  align-self: center;
  font-family: arial
}

.c-admin_order_count_title{
  font-size: 30px;
  margin-bottom: 10px;
  margin-top: 0px;
  font-weight: 500
}

.stat_cont{
  align-items: center;
}

.accounts_total{
  margin: 20px 0px 40px 0px;
}


.result_table_cont {
  width: 100%;
  border: 1px solid #f2f2f2;
  /* background-color: white; */
  /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08); */
  /* padding: 10px; */
  border-radius: 5px;
  /*min-width: 940px;
  max-width: 1450px;*/
}
.result_table_cont table {
  width: 100%;
  border-collapse: collapse;
}
.result_table_cont table tr {
  border-bottom: 1px solid #E6E6E6;
}
.result_table_cont table tr:last-child {
  border-bottom: none;
}
.result_table_cont table tr th {
  text-align: left;
  font-size: 18px;
}
.result_table_cont table tr td {
  font-size: 17px;
  font-weight: 500;
}
.result_table_cont table tr td, .result_table_cont table tr th {
  padding: 10px 0px;
}

.scroll_y {
  overflow-x: auto;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);
  background-color: white;
  border-radius: 5px;
}

.edit_product_table{
  height: 30px;
}

.product_edit_cont{
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  padding: 0px 10px;
  width: 100vw;
  height: 100vh;
  justify-content: center;
}

.product_edit{
  width: 100%;
  max-width: 500px;
  background-color: white;
  padding: 15px;
  border-radius: 3px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 8%);
  border: 1px solid #ccc;
}

.product_edit .secondary {
  margin-top: 10px;
}

.no__{
  width: 100%;
  max-width: 500px;
  margin: auto;
  padding: 10px 15px;
  background-color: #cae6ff;
  border-radius: 3px;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.no_result_center{
  padding: 10px;
  border-radius: 3px;
  width: 100%;
  background-color: #cccccc8c;
  /* display: flex; */
  /* width: 100vw; */
  text-align: center;
  /* color: #673AB7; */
  color: #373737;
  font-weight: 500;
}
/* .pageloading_cont{
  height: 200px;
} */

.spinner_cont_page_{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25vh;
}

.spinner_cont_page_ .spinner_circle{
  height: 100px;
}

.manage{
  color: #317BEB;
  cursor: pointer;
  width: fit-content;
  margin: auto;
}

.sess_info_cont{
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #00000052;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sess_info{
  margin-top: 5%;
  background-color: white;
  width: 90%;
  max-width: 500px;
  padding: 10px;
  border-radius: 3px;
  overflow-y: auto;
  max-height: 85%;
}

.close_cont{
  display: flex;
  flex-direction: column;
}

.close{
  text-align: end;
  color: red;
  font-weight: bold;
  width: fit-content;
  align-self: end;
  cursor: pointer;
}

.groomers_heading{
  margin-bottom: 15px;
  font-weight: bold;
}

.groomer{
  cursor: pointer;
  background-color: #cccccca8;
  padding: 6px 10px;
  border-radius: 50px;
  margin-right: 5px;
  margin-bottom: 7px
}

.service{
  font-size: 20px;
  margin-bottom: 10px;
}

.other{
  border: 1px solid;
  padding: 3px 5px;
  border-radius: 3px;
  background-color: #33333314;
}

hr{
  margin-top: 25px;
  border: 1px solid;
}

.pet_name{
  font-size: 20px;
  text-align: center;
  width: fit-content;
  margin: auto;
  padding: 5px 10px;
  border: 2px solid;
  border-radius: 3px;
}

.pet_info{
  font-size: 20px;
  text-align: center;
  margin-top: 7px;
}

.groomer_selected{
  background-color: #0000ff70;
  cursor: auto;
}

.heading_cont_{
  justify-content: space-between;
  align-items: center;
}
.money{
  color: #317BEB;
  cursor: pointer;
  font-size: 17px;
  font-weight: 500;
}

.money_txt{
  font-size: 25px;
}

.money_info{
  padding: 10px 10px 10px 25px;
}
.groomers_names_cont{
  flex-wrap: wrap;
}

/* tr:nth-child(even){background-color: #f2f2f2;} */

/* .parent_name{
  padding-left: 12px !important;
} */

td, th {
  /* border: 1px solid #ddd; */
  padding: 8px !important;
}

.manage_td{
  margin: auto;
}

tr:hover{
  background-color: #317beb0d;
  /* cursor: pointer; */
}

.not_hover:hover{
  background-color: transparent;
  cursor: auto;
  
}

.no_money{
  text-align: center;
  color: #5d5d5d;
  padding: 15px 0px;
  font-size: 18px;
}

.tr_showering{
  border-left: 5px solid #2196f3;
}

.tr_waiting{
  border-left: 5px solid #ffc107;
}

.tr_finished{
  border-left: 5px solid green;
}

.service{
  cursor: pointer;
}

.pointer{
  cursor: pointer;
}

.date_input{
  width: 100%;
  height: 50px;
  /* background-color: #d2dbf3; */
  background-color: #fff;
  /* border: 1px solid #647193; */
  border: 1px solid #ccc;
  /* border-right: 0px; */
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 20px;
  /* -webkit-transition: 0.3s; */
  transition: 0.1s;
  outline: none;
  height: 50px;
  max-width: 250px;
}

.find_date_btn{
  height: auto;
  width: auto;
  font-size: 19px;
  max-height: 50px;
  height: 50px;
  margin-left: 5px;
}

.error{
  font-weight: 500;
  color: red;
}

.date_form{
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
}

.table_hr{
  margin-top: 25px;
  margin-bottom: 15px;
  border: 1px solid #9a9a9a;
}

.home_comp_cont{
  justify-content: center;
}
</style>