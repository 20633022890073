<template>
    <div class="row home_comp_cont">
        <div class="left">
            <!-- <img class="row img" src="@/assets/logo2.png" alt="logo"> -->
            <img class="row img" :src="linkk + '/' + company_logo" alt="logo">
            <div class=" powered_by_customer">
            powered by 
            <router-link to="/pharaohfrog"  class="ph_link"><b>pharaoh frog</b></router-link>
          </div>
        </div>
        <div class="right">
            <h1 class="hone hone_link">Pet Grooming Service</h1>
            <div class="back_cont">
              <router-link class="col back_btn_mini" class-active="active" to="/" >back</router-link>
              <form class="column btn_home_cont form_">
                  <input type="text" placeholder="Parent Name" class="input" v-model="name">
                  <button :class=" loading ? 'btn btn_first_grooming btn_first_grooming_e no_padding' : 'btn btn_first_grooming btn_first_grooming_e' "
                  @click.prevent="from_step_2()">
                    <div class="loading" v-if="loading" >
                      <Spinner/>
                    </div>
                    <div class="btn_txt" v-else >Next</div>
                  </button>
                  <!-- <button class="btn btn_first_grooming" @click="$router.push('/grooming/step-3')">Next</button> -->
              </form>
              <div v-if="error" class="err_single_line">{{ error }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import request from '../axios_request';
import Spinner from '@/components/Spinner.vue';

export default {
    name: "secondstepGrooming",
    components: {
      Spinner
    },
    data: function () {
      return {
        name: null,
        loading: false,
        error: null,
        linkk: process.env.VUE_APP_SERVER_ENDPOINT,

      };
    },
    created() {
      if( !this.phone ){
        this.$router.push({ name: 'home' });
      }
      this.name = this.parent_name;

      // check if logged in
      if( localStorage.getItem('token') ){
          var token = localStorage.getItem('token');
          request.post('/api/system/is_logged', {token})//, config
          .then((response)=>{
            if( response.status === 200 ){
                this.set_logged_in(true);
                this.role_(response.data.role);
                this.admin_name_(response.data.name);
                this.company_logo_(response.data.img);
                console.log(response);
              }
          }).catch(e => {
              console.log(e);
              //route to login
              this.$router.push({name: 'admin_login'});
          });
      }
    },
    computed: {
      ...mapState([
        'phone',
        'parent_name',
        'company_logo'
      ]),
    },
    methods: {
      ...mapActions([
        "parent_name_",
        "parent_id_",
        'set_logged_in',
        'role_',
        'admin_name_',
        'company_logo_'
      ]),
      from_step_2(){
        if( this.loading ){
          return;
        }
        this.error = null;
        //if same name
        if( this.name && this.parent_name && this.name === this.parent_name ) {
          this.loading = false;
          this.$router.push({ name: 'grooming_step_3' });
          return;
        }
        //checkif we have a name
        if( this.name ){
          //check if length 2 or bigger
          if( this.name.length > 1 ){
            this.loading = true;
            
            var data = {
              "phone": this.phone,
              "name": this.name
            }
            //send it server
            request.post('/api/parent', data)
              .then((response)=>{
                this.loading = false;
                if( response.status === 201 ){
                  //change id in store
                  console.log(response.data);
                  if( response.data.id ){
                    this.parent_name_(data.name);
                    this.parent_id_(response.data.id);
                    this.$router.push({ name: 'grooming_step_3' });
                  }else{
                    console.log('error sending data to server or something.');
                    this.error = 'error sending data to server or something.';
                    this.loading = false;
                  }
                }else{
                  console.log('error sending data to server.');
                  this.error = 'error sending data to server.';
                  this.loading = false;
                }
              })
              .catch(e => {
                if( e.response && e.response.status && e.response.data && e.response.status === 500 && e.response.data.update && e.response.data.update === true ){
                  console.log('name need be updated.');
                  request
                    .post('/api/parent/name_update', data)
                    .then((res_update) => {
                      if( res_update.status === 200 ){
                        if( res_update.data && Array.isArray(res_update.data) && res_update.data[0] && parseInt(res_update.data[0]) === 1 ){
                          this.parent_name_(data.name);
                          this.$router.push({ name: 'grooming_step_3' });
                        }else{
                          this.error = 'error in update name 1.';
                          this.loading = false;
                        }
                      }else{
                        this.error = 'error in update name.';
                        this.loading = false;
                      }
                    })
                    .catch(update_e => {
                      console.log(update_e);
                      this.error = 'error in update name.';
                      this.loading = false;
                    }) 
                }else{
                  console.log(e);
                  this.error = 'error.';
                  this.loading = false;
                }
              });
          }else{
            console.log('name must be 1 chars or more.');
            this.error = 'name must be 1 chars or more.';
            this.loading = false;
          }
        }else{
          console.log('name empty OR something wrong with name var.');
          this.error = 'must enter name.';
          this.loading = false;
        }
      }
    }
}
</script>


<style scoped>
.btn_first_grooming_e{
    font-size: 20px;
    padding: 10px 35px;
    border-radius: 0px 5px 5px 0px;
    width: fit-content;
    border-radius: 3px;
    margin-left: 0px;
    height: 50px;
}

.no_padding{
  padding: 0px 23px;
}

.hone_link{
  margin-bottom: 8px;
}
/* .btn_first_grooming:focus {
  border: 3px solid #555;
} */

  /* .btn_first_grooming{
    outline-color: #fff;
    outline-style: none;
  } */

input{
    /* background-color: #d2dbf3; */
    background-color: #fff;
    /* border: 1px solid #647193; */
    border: 1px solid #ccc;
    /* border-right: 0px; */
    border-radius: 5px;
    padding: 5px 20px;
    font-size: 20px;
    /* -webkit-transition: 0.3s; */
    transition: 0.1s;
    outline: none;
    width: 100%;
    margin-bottom: 5px;
}

.input:focus {
  border: 1px solid #317BEB;
  /* border-right: 0px; */
}

.err_single_line{
  color:red;
  font-size: 17px;
}

.form_{
  align-items: end;
}

/*Small devices (landscape phones, 576px and up)*/
@media (max-width: 800px) {
  .home_comp_cont{
    flex-direction: column;
  }
  .btn_home_cont{
    margin-bottom: 0px;
  }
  .btn_home_cont{
    margin-top: 0px;
  }
  .hone{
    margin-top: 26px;
  }
  input{
    width: 100%;
  }
}

@media (max-width: 500px) {
  .home_comp_cont{
    flex-direction: column;
    padding: 0px 10px;
  }

  .hone{
    font-size: 27px;
    margin-top: 40px;
  } 

  img{
    height: 120px;
    margin-right: 0px;
  }

  .btn_home_cont button{
    font-size: 20px;
  }

  .btn_first_grooming{
    padding: 10px 25px;
  }

  .no_padding{
    padding: 0px 12px;
  }

}
</style>