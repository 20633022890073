<template>
    <header class="row">
        <div class="logo_cont">
            <!-- <router-link to="/admin">
                {{ role }}
            </router-link> -->
            <img @click="$router.push({name:'admin'})" class="row  company_logo" :src="linkk + '/' + company_logo" alt="logo">

            <div v-if="role && local_branch" class="admin_logo" @click="$router.push({name:'admin'})">
                {{ local_branch_v }} {{ role }}
            </div>
        </div>
        <div class="left">
            <!-- <div :class="`${new_notifcation ? 'notification_tx new_' : 'notification_tx'}`"><router-link to="/notification">الاشعارات</router-link></div> -->

            <nav class="main_nav dropdown">
                <ul class="row full_height align_items_center ">
                    <!-- <li v-if="['admin'].includes(role)" ><router-link to="/add_product">اضافة منتج</router-link></li> -->
                    <!-- <li class="vet" v-if="role === 'vet'">Dr.{{ admin_name }}</li>
                    <li class="vet" v-else>{{ admin_name }}</li> -->
                    <li class="menu_li">
                        <img src="@/assets/three_dots.svg" alt="three_dots" class="three_dots" @click="three_dots_icon()">
                        <ul class=" dropdown-content" v-if="show_three_dots_menu">
                            <li @click="$router.push({name:'admin_find_session_by_date'})">
                                Find session by date
                            </li>
                            <li @click="$router.push({name:'admin_find_session_by_phone'})">
                                Find session by phone
                            </li>
                            <li v-if="company !== d_company" @click="$router.push({name:'admin_long_time_no_see'})">
                                long time no see
                            </li>
                            <li @click="$router.push({name:'admin_company_company'})">
                                Branches waiting line
                            </li>
                            <li @click="$router.push({name:'admin_manage_groomers'})">
                                Manage groomers
                            </li>
                            <!-- <li @click="export_()">
                                Export for excel
                            </li> -->
                            <li @click="logout()" class="logout_btn">
                                <spinner v-if="logout_loading" :black="true"></spinner>
                                <div v-else>logout</div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </div>


    </header>
</template>

<script>
import Spinner from '@/components/Spinner.vue'
import { mapActions, mapState  } from 'vuex';
import request from '../axios_request';

export default {
    name: 'AppHeader',
    components: {
        Spinner
    },
    data: function () {
        return {
            new_notifcation: false,
            logout_loading: false,
            show_three_dots_menu: false,
            show_mob_nav_var: false,
            export_loading: false,
            linkk: process.env.VUE_APP_SERVER_ENDPOINT,
            local_branch: null,
            d_company: process.env.VUE_APP_FUCK_ZIGGY


        }
    },
    created(){
      // check if logged in
      if( localStorage.getItem('token') ){
          var token = localStorage.getItem('token');
          request.post('/api/system/is_logged', {token})//, config
          .then((response)=>{
            if( response.status === 200 ){
                this.set_logged_in(true);
                this.role_(response.data.role);
                this.admin_name_(response.data.name);
                this.company_logo_(response.data.img);
                this.company_(response.data.company);
                this.local_branch = response.data.branch;
                console.log(response);
              }
          }).catch(e => {
              console.log(e);
              //route to login
              this.$router.push({name: 'admin_login'});
          });
      }
    },
    methods: {
        show_menu_three(){
            this.show_three_dots_menu = !this.show_three_dots_menu;
        },
        logout(){
            if( this.logout_loading === false ){
                this.logout_loading = true;
                request.post("/api/system/logout")
                    .then(()=>{
                        localStorage.clear();
                        this.set_logged_in(false);
                        this.logout_loading = false;
                        this.$router.push({name: 'admin_login'});
                        // this.show_mob_nav_var = false;
                    }).catch(e => {
                        console.log(e);
                        localStorage.clear();
                        this.set_logged_in(false);
                        this.$router.push({name: 'admin_login'});
                    });
            }
        },
        show_mob_nav(){
            this.show_mob_nav_var = true;
        },
        hide_mob_nav(){
            this.show_mob_nav_var = false;
        },
        ...mapActions([
            'set_logged_in',
            'role_',
            'company_logo_',
            'admin_name_',
            'company_'
        ]),
        three_dots_icon(){
            this.show_three_dots_menu = !this.show_three_dots_menu;
            console.log(this.role);
        },
        reload(){
            // this.$router.push({name: 'admin'});
            this.$router.go();
        },
        export_() {
            if( this.export_loading === true ){
                console.log('cant');
                return;
            }
            this.export_loading = true;
            console.log('hi barbe');
            request.get( "/api/session/export_for_excel", { responseType: 'blob' })
            .then((response) => {
                const blob = new Blob([response.data], { type: 'application/csv' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = 'sessions.csv'
                link.click()
                URL.revokeObjectURL(link.href);
                this.export_loading = false;

                // console.log(res.data);

            }).catch(e => {
                console.log(e);
            });
        }
    },
    computed: {
        ...mapState([
            'role',
            'admin_name',
            'company_logo',
            'company',
            'branch'
        ]),
        local_branch_v(){
            if( this.local_branch ){
                var x = this.local_branch.replace('_', ' ');
                return x;
            }
            return null;
        }
    }
}
</script>

<style scoped>
header{
    width: 100%;
    height: 50px;
    background-color: white;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.08);
}

.logo_cont{
    height: 100%;
    display: flex;
    align-items: center;
}

header a, header nav ul li a{
    font-size: 25px;
    text-decoration: none;
    color: #1a1a1a;
    font-weight: 500;
}

header nav ul li a{
    font-size: 20px;
}

header a:hover{
    /* text-decoration: underline; */
    color: #000000d1
}

/* .logo_cont a:hover{
    font-weight: bold;
} */

header nav {
    font-size: 16px;
    font-weight: normal;
    height: 100%;
}

nav ul{
    padding: 0px;
    margin: 0px;
    list-style: none;
}

nav ul li{
    /* margin-right: 20px; */
    height: fit-content;
    display: flex;
}

nav ul li:first-child{
    margin-right: 0px;
}

nav *{
    font-weight: 500;
    font-size: 20px;
}
/* header .logo_cont img{
    height: 90%;
    width: 30px;
} */

.header_icon{
    width: 20px;
    margin-left: 5px;
}

.drop_down_menu{
    position: absolute;
    background-color: white;
    left: 0px;
    padding: 0px 0px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.08);
    /* padding: 7px 0px; */
    border: 1px solid #11111121;
    width: max-content;
    border-radius: 0px 0px 3px 3px;
    /* margin-left: 10px; */
}

.drop_down_menu_cont{
    position: relative;
    height: 100%;
}

.drop_down_menu_heading{
    height: 100%;
    align-items: center;
}

.drop_down_menu * {
    margin: 0px;
}

.drop_down_menu li {
    padding: 10px 15px;
}

.drop_down_menu li:hover {
    background-color: #cccccc78;
}

.three_dots{
    height: 70%;
    width: auto;
    cursor: pointer;
}

.header_icon_three{
    height: 50%;
    width: auto;
}

.mob_nav{
    /* display:none; */
    height: 100%;
    align-items: center;
    position: fixed;
    background-color: white;
    top: 0;
    left: 0px;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.mob_nav ul{
    width: 100%;
    align-items: center;
}

.ham_icon{
    height: 40%;
    display:none
}

.close_icon{
    height: 20px;
    position: fixed;
    top: 10px;
    left: 10px;
}

.new_ a{
    color: #008700 !important;
    background: aliceblue;
}

.left{
    display: flex;
    height: 100%;
    align-items: center;
    margin: 0px;
}

.notification_tx{
    margin-left: 20px;
    font-size: 20px;
    display: flex;
    align-items: center;
}

.notification_tx a{
    font-size: 20px;
}

.mob_role_cont{
    height: 100%;
    align-items: center;
}

.mob_role_cont img{
    margin-right: 20px;
}

.mob_nav ul li{
    margin-right: 0px !important;
}

.mob_role_cont{
    display: none;
}

.three_dots{
    height: 25px;
}

.main_nav{
    justify-content: center;
    align-items: center;
    display: flex;
}

.dropdown {
    position: relative;
    /* display: inline-block; */
}

.dropdown-content {
    position: absolute;
    /* background-color: aquamarine; */
    right: 0px;
    top: 100%;
    border-radius: 0px 3px;
    background-color: #3333;
    background-color: white;
    border: 1px solid #cccccc54;
    border-radius: 3px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);
    width: max-content;
}

.dropdown-content li{
    padding: 10px 20px;
    cursor: pointer;
    color: #393939;
    border-bottom: 1px solid #ccc;
}

.dropdown-content li:last-child{
    border-bottom: none;
}

.dropdown-content li:hover{
    background-color: #cccccc14;
}

.admin_logo{
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}

.vet{
    margin-right: 20px !important;
    font-weight: 500;
}

.menu_li{
    margin: 0px !important;
}

.logout_btn{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 0px;
}

.company_logo{
    height: 90%;
    margin-right: 10px;
    cursor: pointer;
}

/* Extra small devices (phones, 600px and down) */
/* @media only screen and (max-width: 600px) {
    .main_nav{
        display:none !important
    }
    .ham_icon{
        display: flex;
    }
    .mob_role_cont{
        display: flex;
    }
} */
</style>