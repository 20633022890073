<template>
    <div class="row home_comp_cont">
      <div class="page_loader_cont" v-if="page_loader">
          <div class="spinner_height">
              <spinner-circle :black="true"></spinner-circle>
          </div>
      </div>
      <div v-if="show_d_prompt" class="delete_cont">
        <div class="delete_cont_2">
          <div class="delete_heading">Are you sure you want to delete '{{d_selected_name}}' ?</div>
          <div  class="row btn_del_cont">
            <button @click="close_d_prompt()" class="pop_btn cencel_btn">Cencel</button>
            <button class="pop_btn delete_btn">
              <red-spinner v-if="d_loading" :red="true"></red-spinner>
              <div v-else @click="delete_pet()">Delete</div>
            </button>
          </div>
          <div class="error_msg d_error_msg" v-show="d_error">{{ d_error }}</div>

        </div>
      </div>
        <div class="left">
            <!-- <img class="row img" src="@/assets/logo2.png" alt="logo"> -->
            <img class="row img" :src="linkk + '/' + company_logo" alt="logo">
            <div class=" powered_by_customer">
            powered by 
            <router-link to="/pharaohfrog"  class="ph_link"><b>pharaoh frog</b></router-link>
          </div>
            <button class="add_pet_2" @click="$router.push('/step-3-logged')">
              <div class="btn_txt" >Add Pet</div>
            </button>
            <div class="not_my_phone" @click="$router.push({name: 'home'})">go back to phone step?</div>
            <div class="pets_list"  v-if="check_if_any_pet_selected">
              <h4>Your Pets</h4>
              <!-- <hr> -->
              <ul>
                <li v-for="pet in selected_pets_2" :key="pet.id" @click="select_pet(parseInt(pet.id))"
                :class=" current_id && (parseInt(current_pet.id) === parseInt(pet.id)) ? 'pet_selected' : '' ">
                  <div class="row list_item_div">
                    <div>{{ pet.name }}</div>
                    <!-- <img class="row img" src="@/assets/dog_list.svg" alt="dog_list"> -->
                    <img @click.stop="open_d_prompt(pet.id, pet.name)" class="row img delete_icon" src="@/assets/delete-outline.svg" alt="dog_list">
                  </div>
                </li>
              </ul>
            </div>
        </div>
        <div class="right column">
            <h1 class="hone hone_3">Pet Grooming Service</h1>
            <div class="row btn_home_cont column">
              <div class="check_list_cont check_list_cont_m" v-if="current_pet && typeof current_pet === 'object' && current_pet !== null">


                  <div class="row mr_top_20">
                    <input type="checkbox" id="shower"  v-model="current_pet.shower" @change="shower(current_pet.id)">
                    <label class="row checkmark" for="shower" >Shower</label>
                  </div>
                  <div class="row check_list_cont mr_top_20">
                    <input type="checkbox" id="medical_shower"  v-model="current_pet.medical_shower" @change="shower_m(current_pet.id)">
                    <label class="checkmark" for="medical_shower" >Medical Shower</label>
                  </div>
                  <div class="row check_list_cont mr_top_20" v-if="company === 'pets_club'">
                    <input type="checkbox" id="chemical_shower"  v-model="current_pet.chemical_shower" @change="shower_c(current_pet.id)">
                    <label class="checkmark" for="chemical_shower" >Chemical Shower</label>
                  </div>

                <hr>
                  <div class="row  mr_top_20 ">
                    <input type="checkbox" id="hair_cut"  v-model="current_pet.hair_cut" @change="hair_c()">
                    <label class="row   checkmark" for="hair_cut" >Hair Cut</label>
                  </div>
                  <div class="row check_list_cont mr_top_20">
                    <input type="checkbox" id="hair_trimming"  v-model="current_pet.hair_trimming" @change="hair_t()">
                    <label class="checkmark" for="hair_trimming" >Hair Trimming</label>
                  </div>
                <hr>


                  <div class="row check_list_cont mr_top_20">
                    <input type="checkbox" id="nail_trimming"  v-model="current_pet.nail_trimming" @change="nail_t()">
                    <label class="checkmark" for="nail_trimming" >Nail Trimming</label>
                  </div>
                  <div class="row check_list_cont " v-if=" current_pet && current_pet.type && current_pet.type === 'dog'">
                    <input type="checkbox" id="anal_gland"  v-model="current_pet.anal_gland"  @change="anal_gland_()">
                    <label class="checkmark" for="anal_gland" >Anal Gland Expression</label>
                  </div>


              </div>

              <textarea v-if="current_pet && typeof current_pet === 'object' && current_pet !== null" placeholder="describe how you want the hair cut or trimming or add health issues or add notes " v-model="current_pet.other" @change="other(current_pet.id)"></textarea>
              <div class="error_msg d_error_msg" v-show="error && error_id && current_pet.id === parseInt(error_id)">{{ error }}</div>


              <div class="row btns_cont">
                <button class="btn back_btn btn_first_grooming" @click="$router.push('/step-3')">back</button>
                <!-- <button class="btn btn_first_grooming" @click="$router.push('/grooming/step-2')">Next</button> -->
                <button class="btn btn_first_grooming"  @click="from_step_4()">Next</button>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
//on created load selected pets full
// restrict if dont have selected pets
//select the first pet
import request from '../axios_request';
// import { mapState } from 'vuex';
import RedSpinner from '@/components/RedSpinner.vue';
import SpinnerCircle from '@/components/SpinnerCircle.vue';

import { mapState, mapActions } from 'vuex';


export default {
  name: "fourthstepGrooming",
    components: {
      SpinnerCircle,
      RedSpinner
    },
  data: function (){
    return{
      selected_pets_full: null,

      current_id: null,
      page_loader: true,
      show_d_prompt: false,
      d_id: null,
      d_selected_name: null,
      d_error: null,
      d_loading: false,
      error: null,
      error_id: null,
      linkk: process.env.VUE_APP_SERVER_ENDPOINT,

    }
  },
  created() {
    if( !this.parent_id || this.parent_id === null ){
      this.$router.push({ name: 'home' });
      return;
    }

      // check if logged in
      if( localStorage.getItem('token') ){
          var token = localStorage.getItem('token');
          request.post('/api/system/is_logged', {token})//, config
          .then((response)=>{
            if( response.status === 200 ){
                this.set_logged_in(true);
                this.role_(response.data.role);
                this.admin_name_(response.data.name);
                this.company_logo_(response.data.img);
                this.company_(response.data.company);
                console.log(response);
              }
          }).catch(e => {
              console.log(e);
              //route to login
              this.$router.push({name: 'admin_login'});
          });
      }


    var old_pets = this.pets;
    var temp_service_holder = {
      shower: false,
      medical_shower: false,
      hair_cut: false,
      hair_trimming: false,
      nail_trimming: false,
      anal_gland: false,
      other: null,
      current: false,
      selected: false
    }
    //get selected pets full
    request.post('/api/pet/get_by_parent', {parent_id: this.parent_id})
      .then((res)=>{
          if( res.status === 200 ){
            //check if new pets arr not empty
            if( res.data && Array.isArray(res.data) && res.data.length > 0 ){
              res.data.forEach(function (pet) {
                Object.assign(pet, temp_service_holder);
                //check if its in old (if its in old merge else add empty services)
                if( old_pets && Array.isArray(old_pets) && old_pets.length > 0 ){
                  old_pets.forEach(function (pet_old) {
                    if( parseInt(pet.id) === parseInt(pet_old.id) ){
                      Object.assign(pet, pet_old);
                    }
                  });
                }
              });
            }else{
              res.data = [];
            }
            // add current id
            var c_id = null;
            if( res.data && Array.isArray(res.data) && res.data .length > 0 ){
              res.data.forEach(function (pet__) {
                if( pet__.selected && pet__.selected === true ){
                  if( c_id === null ){
                    c_id = parseInt(pet__.id);
                  }
                }
              });
            }
            this.current_id = c_id;
            //save all in store
            this.pets_(res.data);
            console.log(this.check_if_any_pet_selected);
            this.page_loader = false;
          }else{
            // means error
            console.log('error created');
            this.$router.push({ name: 'home' });
          }
      })
      .catch(e => {
        // means error
        console.log(e);
        this.$router.push({ name: 'home' });
      });
  },


  computed: {
    ...mapState([
      'pet_sessions',
      'selected_pets',

      'phone',
      'parent_id',
      'pets',
      'company_logo',
      'company'
    ]),
    check_if_any_pet_selected(){
      var result = false;
      if( this.pets && Array.isArray(this.pets) && this.pets.length > 0 ){
        this.pets.forEach(function (pet) {
          if( pet.selected && pet.selected === true ){
            result =  true;
          }
        });
      }
      return result;
    },
    selected_pets_2(){
      return this.pets.filter(pet => pet.selected && pet.selected === true)
    },
    current_pet(){
      if( this.current_id === null ){
        return null;
      }
      var arr = this.pets;

      var index = arr.findIndex(pet => parseInt(pet.id) === parseInt(this.current_id) );

      return arr[index];
    }
  },
  methods: {
    ...mapActions([
      "pet_sessions_",
      "selected_pets_",

      'pets_',
      'set_logged_in',
      'role_',
      'admin_name_',
      'company_logo_',
      'company_'

    ]),
    hair_c(){
      if(this.current_pet){
        if( this.current_pet.hair_cut && this.current_pet.hair_cut === true && this.current_pet.hair_trimming === true  ){
          this.current_pet.hair_trimming = false;
        }
      }
        this.error_id = null;
        this.error = null;
    },

    hair_t(){
      if(this.current_pet){
        if( this.current_pet.hair_trimming && this.current_pet.hair_trimming === true && this.current_pet.hair_cut === true  ){
          this.current_pet.hair_cut = false;
        }
      }
      this.error_id = null;
      this.error = null;
    },
    shower(){
      if( this.company === 'pets_club' ){
        if(this.current_pet){
          if( (this.current_pet.medical_shower && this.current_pet.medical_shower === true && this.current_pet.shower === true) 
          || (this.current_pet.chemical_shower && this.current_pet.chemical_shower === true && this.current_pet.shower === true) ){
            this.current_pet.medical_shower = false;
            this.current_pet.chemical_shower = false;
          }


          this.error_id = null;
          this.error = null;
        }
      }else{
        if(this.current_pet){
          if( this.current_pet.shower && this.current_pet.shower === true && this.current_pet.medical_shower === true  ){
            this.current_pet.shower = false;
          }

          this.error_id = null;
          this.error = null;
        }
      }
    },

    shower_m(){
      if( this.company === 'pets_club' ){
        if(this.current_pet){
          if( (this.current_pet.shower && this.current_pet.shower === true && this.current_pet.medical_shower === true) 
          || (this.current_pet.chemical_shower && this.current_pet.chemical_shower === true && this.current_pet.medical_shower === true) ){
            this.current_pet.shower = false;
            this.current_pet.chemical_shower = false;
          }


          this.error_id = null;
          this.error = null;
        }
      }else{
        if(this.current_pet){
          if( this.current_pet.shower && this.current_pet.shower === true && this.current_pet.medical_shower === true  ){
            this.current_pet.shower = false;
          }

          this.error_id = null;
          this.error = null;
        }
      }
    },

    shower_c(){
      if(this.current_pet){
        if( (this.current_pet.shower && this.current_pet.shower === true && this.current_pet.chemical_shower === true) 
        || (this.current_pet.medical_shower && this.current_pet.medical_shower === true && this.current_pet.chemical_shower === true) ){
          this.current_pet.shower = false;
          this.current_pet.medical_shower = false;
        }


        this.error_id = null;
        this.error = null;
      }
    },

    nail_t(){
      this.error_id = null;
      this.error = null;
    },
    anal_gland_(){
      this.error_id = null;
      this.error = null;
    },
    other(){
      this.error_id = null;
      this.error = null;
    },
    select_pet(id){
      this.current_id = id;
      // console.log(this.current_pet);
    },
    open_d_prompt(id, name){
      this.show_d_prompt = true;
      this.d_id = parseInt(id);
      this.d_selected_name = name;
      this.d_error = null;
    },
    close_d_prompt(){
      if( this.d_loading ){
        return;
      }
      this.show_d_prompt = false;
      this.d_id = null;
      this.d_selected_name = null;
    },

      delete_pet(){
        if( !this.check_if_any_pet_selected ){
          return;
        }

        var arr = this.pets;

        console.log(this.d_id);

        var index = arr.findIndex(pet => parseInt(pet.id) === parseInt(this.d_id) );
        var selected_index = this.selected_pets_2.findIndex(pet => parseInt(pet.id) === parseInt(this.d_id) );

        var temp_service_holder = {
          shower: false,
          medical_shower: false,
          hair_cut: false,
          hair_trimming: false,
          nail_trimming: false,
          anal_gland: false,
          other: null,
          current: false,
          selected: false
        }

        Object.assign(arr[index], temp_service_holder);

        this.pets_(arr);
          console.log(this.selected_pets_2);


        //!this.check_if_any_pet_selected this.selected_pets_2[0]
        if( this.check_if_any_pet_selected && this.current_id === this.d_id ){
          if( this.selected_pets_2[selected_index]  ){//1
            console.log('+1');
            this.current_id = this.selected_pets_2[selected_index].id;
          }else{
            if( this.selected_pets_2[selected_index - 1] ){
              console.log('-1');
              this.current_id = this.selected_pets_2[selected_index - 1].id;
            }else{
              console.log('0');
              this.current_id = this.selected_pets_2[0].id;
            }
          }
        }else{
          if( !this.check_if_any_pet_selected ){
            this.$router.push({ name: 'step_3_logged' });
          }
        }

        this.show_d_prompt = false;
        this.d_id = null;
        this.d_selected_name = null;
      },

      from_step_4(){
        if( !this.check_if_any_pet_selected ){
          this.$router.push({ name: 'step_3_logged' });
        }

        this.error_id = null;
        this.error = null;
        //check if there is a pet with no Service
        //selected_pets, pet_sessions
        // ()
        var pets_holder = this.selected_pets_2;
        var pets_with_no_service_arr = [];
        pets_holder.forEach(function(pet) {
          var have_service = false;
          console.log(have_service);
          for (const service in pet) {
            // console.log(`${property}: ${object[property]}`);
            if( typeof pet[service] === "boolean" && pet[service] === true && service !== 'other' 
            && service !== 'current' && service !== 'selected'){
              // shower: false,
              // medical_shower: false,
              // hair_cut: false,
              // hair_trimming: false,
              // nail_trimming: false,
              // anal_gland: false
          // console.log(service);

              have_service = true;
              // if( !pets_with_no_service_arr.includes(pet['id']) ){
              //   pets_with_no_service_arr.push(pet['id']);
              // }
              
              // return;
              // console.log(session_ok_arr.sort().toString(), selected_pets_holder.sort().toString());
            }
            // else{
            //   pets_with_no_service_arr.push(pet['id']);
            // }
          }
          if( !have_service ){
            if( !pets_with_no_service_arr.includes(pet['id']) ){
              pets_with_no_service_arr.push(pet['id']);
            }
          }
        });

        // this.$router.push({ name: 'grooming_step_5' });
        if( pets_with_no_service_arr.length === 0 ){
          this.$router.push({ name: 'grooming_step_5' });
          console.log('lets goo.');
        }else{
          this.current_id = pets_with_no_service_arr[0];
          this.error_id = pets_with_no_service_arr[0];
          this.error = 'must choose a service for this pet.';
        }
      }
  }
}
</script>


<style scoped>
input[type='checkbox']{
  width: 20px;
  height: 20px;
}

.row.mr_top_20{
  align-items: baseline;
}

.home_comp_cont{
  padding: 0px 20px 25px 20px !important;
  padding-bottom: 10%;
  
}

.btn_first_grooming{
    font-size: 20px;
    padding: 10px 35px;
    border-radius: 5px;
}
/* .btn_first_grooming:focus {
  border: 3px solid #555;
} */

  /* .btn_first_grooming{
    outline-color: #fff;
    outline-style: none;
  } */



.pet_name_cont{
  align-items: end;
  margin-bottom: 5px;
}


.label_img_cont{
  border: 2px solid #365889;
  border: 2px solid #c7c7c7;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 70px;
  background-color: lightgrey;
}

.mr_top_20{
  margin-top: 20px;
}

.label_img{
  height: 30px;
  width: auto;
}

.btns_cont{
  justify-content: space-between;
}

.mr{
  margin-left: 10px;
}

.label{
  font-size: 15px;
  color: #2C4260;
}

.next_to_input{
  width: 30vw;
  margin-right: 10px;
}

textarea{
  display: block;
  width: 100%;
  padding: 5px 15px;

    /* background-color: #d2dbf3; */
    background-color: #fff;
    /* border: 1px solid #647193; */
    border: 1px solid #ccc;
    /* border-right: 0px; */
    border-radius: 5px;
    font-size: 20px;
    /* -webkit-transition: 0.3s; */
    transition: 0.1s;
    outline: none;
    min-height: 110px;
    font-family: inherit;
    resize: vertical;
    margin-bottom: 20px;
}

.full_width{
  width: 100%;
}

.mr_bottom_15{
  margin-bottom: 25px;
}
.hone_3{
  margin-bottom: 20px;
}

.back_btn{
  border-width: 2px;
}

input:focus, textarea:focus {
  border: 1px solid #317BEB;
}

.list_item_div{
  justify-content: space-between;
}

.list_item_div img{
  height: 25px;
}

.right{
  margin-left: 80px;
}

.left img{
  margin-right: 0px;
}

.pets_list ul{
  list-style: none;
  padding: 0px 0px;
  margin-top: 0px;
  border-top: 1px solid #c7c7c7;
}

.pets_list ul li{
  /* border-bottom: 1px solid; */
  border-bottom: 1px solid #c7c7c7;
  padding-bottom: 16px;
  padding-top: 16px;
  cursor: pointer;
}

hr{
  margin: 0px;
  border: 1px solid #c7c7c7;
}

.check_list_cont{
  align-items: center;
  margin-bottom: 20px;
}


input[type=radio]{
  height: 20px;
  width: 20px;
}

/* input[type=checkbox]:before {
	font-family: "FontAwesome";
  content: "\f00c";
  font-size: 15px;
  color: transparent !important;
  background: #fef2e0;
  display: block;
  width: 15px;
  height: 15px;
  border: 1px solid black;
  margin-right: 7px;
} */

/* input[type=checkbox]:checked:before {

	color: black !important;
} */

.checkmark{
  font-size: 28px;
  margin-left: 10px;
}

label{
  width: fit-content;
}

.check_list_cont_m{
  margin-bottom: 15px;
}

.pet_selected{
  background-color: #317beb3d;
  padding: 0px 10px;
}

.add_pet_2 {
  cursor: pointer;
  padding: 7px 5px;
  /* font-weight: bold; */
  font-size: 20px;
  font-family: 'Poppins', sans-serif !important;
  color: #317BEB;
  /* font-size: 20px; */
  margin-bottom: 10px;
  border: 2px solid;
  /* background-color: #317beb26; */
  border-radius: 3px;
  /* padding: 7px 10px; */
  margin-top: 30px;
  font-size: 15.3px;
  font-weight: 500;
  padding: 7px 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add_pet_2:hover {
    /* background-color: #317beb40; */
    background-color: #317beb26;
}

/*Small devices (landscape phones, 576px and up)*/
@media (max-width: 800px) {
  .home_comp_cont{
    flex-direction: column;
    
  }
  .btn_home_cont{
    margin-bottom: 0px;
  }
  .btn_home_cont{
    margin-top: 0px;
  }
  .hone{
    margin-top: 26px;
  }
  input, .next_to_input{
    width: 100%;
  }
  .right{
    margin-left: 0px;
  }
  .left{
    margin-right: 0px
  }

  .not_my_phone{
    display: inline-block;
    margin-top: 5px;
  }
}

@media (max-width: 500px) {
  .home_comp_cont{
    flex-direction: column;
    padding: 0px 10px 25px 10px;
  }

  .hone{
    font-size: 27px;
    margin-top: 40px;
  } 

  img{
    height: 120px;
    margin-right: 0px;
  }

  .btn_home_cont button{
    font-size: 20px;
  }

  .btn_first_grooming{
    padding: 10px 25px;
  }

  .no_padding{
    padding: 0px 12px;
  }

  .left{
    width: 100%;
  }

  .not_my_phone{
    display: inline-block;
    margin-top: 5px;
  }

}
</style>