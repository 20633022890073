<template>
    <main class="spinner_cont_page column main__">
        <!-- <img src="@/assets/logo2.png" alt="logo" class="logo"> -->
        <!-- <img  :src="linkk + '/' + company_logo" alt="logo"> -->
        <img class="row success" src="@/assets/success.svg" alt="success">

        <div class="row">
            <h1>session added successfully</h1>
        </div>
        <button  class="column flex_center btnn" @click="lets_go()">Ok ({{ countDown }})</button>
    </main>
</template>

<script>
// import request from '../axios_request';
// import SpinnerCircle from '../components/SpinnerCircle.vue';
// import SpinnerCircle from './SpinnerCircle.vue';
// import { mapState, mapActions } from 'vuex';


export default {
    name: "SuccessSess",
    // components: { 
    //     SpinnerCircle
    // },
    data: function () {
        return {
            black: true,
            countDown: 5
        }
    },
    created () {
            this.countDownTimer()
    },
    methods:{
        countDownTimer () {
            if (this.countDown > 1) {
                setTimeout(() => {
                    this.countDown -= 1
                    this.countDownTimer()
                    if( this.countDown === 1 ){
                        this.lets_go();
                    }
                }, 1000)
            }
        },
        lets_go(){
              let r = this.$router.resolve({
                name: 'home'
              });
              window.location.assign(r.href)
        }
    }
}
</script>

<style scoped>

.main__{
    z-index: 100;
}
.spinner_cont_page{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.spinner_cont_page .spinner_circle{
    height: 100px;
}

img{
    margin-right: 10px;
    width: 25%;
}

.logo{
    margin-right: 0px;
    height: 145px;
}

button{
    background-color: #317BEB;
    border: 0px;
    padding: 7px 0px;
    color: #F2F2F2;
    font-size: 18px;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500;
    border-radius: 3px;
    cursor: pointer;
    text-align: center;
    align-self: end;
    padding: 10px 40px;
    margin-top: 10px;
}

@media (max-width: 500px) {
  h1{
    font-size: 23px;
  }
}
</style>