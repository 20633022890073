<template>
  <div>
      <div class="page_loader_cont" v-if="page_loader">
          <div class="spinner_height">
              <spinner-circle :black="true"></spinner-circle>
          </div>
      </div>
      <template v-else-if="role && role === 'crew'">
          <div class="row heading_cont_">
              <h3>Find sessions by date</h3>
              <div class="money" @click="open_money()" v-if="sessions && Array.isArray(sessions) && sessions.length > 0 && company !== 'pets_club'">money</div>
          </div>
          <form class="date_form">
            <input type="date" class="date_input" id="birthday" name="date" v-model="date">
            <!-- <button class="btn find_date_btn">
              <div>Find</div>
            </button> -->
            <button class="btn find_date_btn" @click.prevent="find_by_date()">
                <div class="loading" v-if="date_loading" >
                  <Spinner/>
                </div>
                <div class="btn_txt" v-else >Find</div>
              </button>
          </form>
          <div v-if="date_err" class="error">error</div>

          <hr class="table_hr" v-if="sessions && Array.isArray(sessions) && sessions.length > 0">
          
          
          <div v-if=" sessions === '' || !sessions " ></div>
          <div v-else-if=" sessions && Array.isArray(sessions) && sessions.length === 0 " class="no_result no_result_center">we dont have any grooming sessions.</div>
          <div v-else>
              <div class="scroll_y">
                  <div class="result_table_cont " v-if="sessions && Array.isArray(sessions) && sessions.length > 0">
                      <table class="c-admin_table"><!--style="direction: ltr"-->
                          <tr class="not_hover">
                              <th>#</th>
                              <th>Parent</th>
                              <th>Phone</th>
                              <th>Name</th>
                              <th>Type</th>
                              <th>Groomer</th>
                              <th>Status</th>
                              <th>Breed</th>
                              <th v-if="company !== 'pets_club'"></th>
                              <th v-if="company === 'pets_club'">Commission</th>
                              <th v-if="company === 'pets_club'">Total</th>
                          </tr>

                          <tr v-for="(session, i) in sessions" :key="session.id" :class="`tr_${session.status}`" >
                              <td>{{ table_num(i) }}</td>
                              <td  class="parent_name">{{ session.parent_name }}</td>
                              <td  >{{ session.parent_phone }}</td>
                              <td >{{ session.pet_name }}</td>
                              <td >{{ session.pet_type }}</td>
                              <td >{{ session.groomer_name }}</td>
                              <td>{{ session.status }}</td>
                              <td>{{ session.pet_breed }}</td>
                              <td v-if="company === 'pets_club'">{{ session.groomer_commission }}</td>
                              <td v-if="company === 'pets_club'">{{ session.services_total }}</td>
                              <td   class="manage_td" v-if="company !== 'pets_club'">
                                  <div class="manage" @click="manage_btn(i)">
                                      Manage
                                  </div>
                                  
                              </td>
                          </tr>
                      </table>
                  </div>
              </div>
          </div>
      </template>
      <VetHome v-else-if="role === 'vet'"></VetHome>
      <div v-else>error</div>


      <div class="sess_info_cont" v-if="show_sess_info">
          
          <div class=" sess_info">
              <div class="close_cont">
                  <div class="close" @click="close()">close</div>
              </div>
              <div class="groomers">
                  <div class="pet_name" v-if="current_sess">{{ current_sess.pet_name }}</div>
                  <!-- <div class="groomers_heading">pet info</div> -->
                  <div class="pet_info">{{ current_sess.pet_type }} - {{ current_sess.pet_sex }} - {{ current_sess.pet_breed }} - ({{ current_sess.pet_birthday }})</div>
                  <!-- <div class="pet_sex">{{ current_sess.pet_sex }}</div>
                  <div class="pet_breed">{{ current_sess.pet_breed }}</div>
                  <div class="pet_birthday">{{ current_sess.pet_birthday }}</div> -->
              </div>
              <hr>
              <div class="groomers">
                  <div class="groomers_heading">services</div>
                  <div class=" groomers_names_cont" v-if="current_sess !== null">
                      <div class="service other" v-if="current_sess.health_condition">{{ current_sess.health_condition }}</div>
                      <div class="service other" v-if="current_sess.behavior_notes">{{ current_sess.behavior_notes }}</div>

                      <div class="service" v-if="current_sess.shower">shower</div>
                      <div class="service" v-if="current_sess.medical_shower">medical shower</div>
                      <div class="service" v-if="current_sess.hair_cut">hair cut</div>
                      <div class="service" v-if="current_sess.hair_trimming">hair trimming</div>
                      <div class="service" v-if="current_sess.nail_trimming">nail trimming</div>
                      <div class="service" v-if="current_sess.anal_gland">anal gland</div>
                      <div class="service other" v-if="current_sess.other">{{ current_sess.other }}</div>


                      



                  </div>
              </div>
              <hr>
              <div class="groomers">
                  <!-- <div class="pet_name" v-if="current_sess">{{ current_sess.pet_name }}</div> -->
                  <div class="groomers_heading">choose groomer</div>
                  <div class="row groomers_names_cont" v-if="groomers && Array.isArray(groomers) && groomers.length > 0">
                      <div 
                      :class="`${groomer.id === current_sess.groomer_id ? 'groomer groomer_selected' : 'groomer'}`"
                      v-for="groomer in groomers" :key="groomer.id" @click="select_groomer(groomer.id, groomer.name, )">
                          <spinner :black='true' v-if="groomer_id_loading !== null && groomer_id_loading === groomer.id"></spinner>
                          <div v-else>{{ groomer.name }}</div>
                      </div>
                  </div>
              </div>
              <hr>
              <!-- <div class="groomers_heading">choose groomer</div> -->
              
              <div class="groomers">
                  <div class="groomers_heading">change status</div>
                  <div class="row groomers_names_cont" v-if="status_ && Array.isArray(status_) && status_.length > 0">
                      <div 
                      :class="`${status === current_sess.status ? 'groomer groomer_selected' : 'groomer'}`"
                      v-for="(status, index) in status_" :key="index" @click="select_status(status)">
                          <spinner :black='true' v-if="status_txt_loading !== null && status_txt_loading === status"></spinner>
                          <div v-else>{{ status }}</div>
                      </div>
                  </div>
              </div>





              

              <hr>
              <div class="groomers">
                  <div class="groomers_heading">change services</div>
                  <div class="row groomers_names_cont" >
                      <div 
                      :class="`${current_sess.shower === true ? 'groomer groomer_selected service' : 'groomer service'}`"
                      @click="select_service('shower')">
                          <spinner :black='true' v-if="service_loading !== null && service_loading === 'shower'"></spinner>
                          <div v-else>shower</div>
                      </div>

                      <div 
                      :class="`${current_sess.medical_shower === true ? 'groomer groomer_selected service' : 'groomer service'}`"
                      @click="select_service('medical_shower')">
                          <spinner :black='true' v-if="service_loading !== null && service_loading === 'medical_shower'"></spinner>
                          <div v-else>medical shower</div>
                      </div>

                      <div 
                      :class="`${current_sess.hair_cut === true ? 'groomer groomer_selected service' : 'groomer service'}`"
                      @click="select_service('hair_cut')">
                          <spinner :black='true' v-if="service_loading !== null && service_loading === 'hair_cut'"></spinner>
                          <div v-else>hair cut</div>
                      </div>

                      <div 
                      :class="`${current_sess.hair_trimming === true ? 'groomer groomer_selected service' : 'groomer service'}`"
                      @click="select_service('hair_trimming')">
                          <spinner :black='true' v-if="service_loading !== null && service_loading === 'hair_trimming'"></spinner>
                          <div v-else>hair trimming</div>
                      </div>

                      <div 
                      :class="`${current_sess.nail_trimming === true ? 'groomer groomer_selected service' : 'groomer service'}`"
                      @click="select_service('nail_trimming')">
                          <spinner :black='true' v-if="service_loading !== null && service_loading === 'nail_trimming'"></spinner>
                          <div v-else>nail trimming</div>
                      </div>

                      <div v-if="current_sess.pet_type !== null && current_sess.pet_type === 'dog'"
                      :class="`${current_sess.anal_gland === true ? 'groomer groomer_selected service' : 'groomer service'}`"
                      @click="select_service('anal_gland')">
                          <spinner :black='true' v-if="service_loading !== null && service_loading === 'anal_gland'"></spinner>
                          <div v-else>anal gland</div>
                      </div>


                  </div>
              </div>

              <hr v-if="current_sess.pet_type === 'dog'">
              <div class="groomers" v-if="current_sess.pet_type === 'dog'">
                  <div class="groomers_heading">Breed Size</div>
                  <div class="row groomers_names_cont" >
                      <div 
                      :class="`${current_sess.giant === true ? 'groomer groomer_selected pointer' : 'groomer pointer'}`" @click="select_breed_size(current_sess.giant)">
                          <spinner :black='true' v-if="breed_size_loading !== null && breed_size_loading === true"></spinner>
                          <div v-else>giant</div>
                      </div>
                  </div>
              </div>


          </div>
      </div>

      <!-- show money cont -->
      <div class="sess_info_cont" v-if="show_money_info">
          
          <div class=" sess_info money_info">
              <div class="close_cont">
                  <div class="close" @click="close_money()">close</div>
              </div>
              <div v-if="typeof groomer_money === 'object' && groomer_money !== null && Object.keys(groomer_money).length > 0 " >
                  <div v-for="(money, grommer_name) in groomer_money" :key="grommer_name">
                      <p class="money_txt">
                          {{ grommer_name }} : {{money}}
                      </p>
                  </div>
              </div>
              <div v-else class="no_money">you have no money.</div>


          </div>
      </div>
      <AdminFooter></AdminFooter>
  </div>
</template>

<script>
// @ is an alias to /src
import request from '../axios_request';
import { mapState, mapActions } from 'vuex';
import SpinnerCircle from './SpinnerCircle.vue';
import Spinner from '@/components/Spinner.vue';
import VetHome from '@/components/VetHome.vue';
import dayjs from '../dayjs';
import SocketioService from '@/services/socketio.service.js';
import AdminFooter from '@/components/AdminFooter.vue';

// import request from '../axios_request';

export default {

  name: 'AdminFindByDateComp',
  components: {
      SpinnerCircle,
      Spinner,
      VetHome,
      AdminFooter
  },
  data: function () {
      return {
          page_loader: true,
          date: null,
          date_err: false,
          date_loading: false,
          sessions: null,
          show_sess_info: false,
          groomers: null,
          groomer_id_loading: null,
          current_sess_id: null,
          status_: ['waiting', 'showering', 'finished'],
          status_txt_loading: null,
          breed_size_loading: null,
          show_money_info: false,
          timer: null,
          service_loading: null
      }
  },
  mounted() {
      // this.logged_in;
      // 
      if( localStorage.getItem('token') ){
          var token = localStorage.getItem('token');
          request.post('/api/system/is_logged', {token})//, config
          .then((response)=>{
          // console.log(response);
              if( response.status === 200 ){
                  // console.log(response.data);
                  this.set_logged_in(true);
                  this.role_(response.data.role);
                  this.admin_name_(response.data.name);
                  if( response.data.role === 'vet' ){
                      this.page_loader = false;
                      return;
                  }
                  //load all groomers
                  request
                    .get('/api/groomer/get_all')//, config
                    .then((grommers_res) => {
                        this.groomers = grommers_res.data;
                        // console.log(grommers_res.data);
                        // load all groomers
                        
                        this.page_loader = false;
                    })
                    .catch((grommers_e) => {
                        console.log(grommers_e);
                        this.grommers_e = false;
                        this.page_loader = false;
                    });
                  return;
              }
              //not logged
              this.set_logged_in(false);
              this.role_(null);
              localStorage.clear();
              this.$router.push({name: 'admin_login'});
          }).catch((e) => {
              console.log(e);
              this.set_logged_in(false);
              this.role_(null);
              localStorage.clear();
              this.$router.push({name: 'admin_login'});
          });

          /// use socket
          SocketioService.socket.on('new_session', (data) => {
            console.log(data);
              if( !this.date || this.date === null ){
                // this.date_err = false;
                console.log(this.date);
                return;
              }
              request
                .post('/api/session/get_sess_by_date', {date_: this.date})//, config
                .then((res) => {
                  this.sessions = res.data;
                })
                .catch((e) => {
                  console.log(e);
                  this.sessions = '';
                });
          });

          /// use socket
          SocketioService.socket.on('sess_update', (data) => {
              console.log(data);
              if( !this.date || this.date === null ){
                // this.date_err = false;
                console.log(this.date);
                return;
              }
              request
                .post('/api/session/get_sess_by_date', {date_: this.date})//, config
                .then((res) => {
                  this.sessions = res.data;
                })
                .catch((e) => {
                  console.log(e);
                  this.sessions = '';
                });
          });


      }else{
          this.set_logged_in(false);
          this.role_(null);
          localStorage.clear();
          this.$router.push({name: 'admin_login'});
      }


  },
  methods: {
      ...mapActions([
          "role_",
          "set_logged_in",
          'admin_name_'
      ]),
      time_(t) {
          return dayjs(t).fromNow(false);
      },
      table_num(i){
          // if( i === 0 ){
          //     return this.sessions.length;
          // }else if( (i + 1) === this.sessions.length ){
          //     return 1;
          // }else{
          //     return i + 1;
          // }
          return this.sessions.length - i;
      },
      birthday(t){
          var today = new Date();
          var birthDate = new Date(t);
          var age = today.getFullYear() - birthDate.getFullYear();
          var m = today.getMonth() - birthDate.getMonth();
          console.log(m);
          age = age * 12 + m;
          if( age === 0 ){
              const diffInMs   = today - birthDate;
              const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
              return diffInDays;
          }else{
              return age;
          }
      },
      manage_btn(i){
          
          this.show_sess_info = true;
          this.current_sess_id = i;
          console.log(this.current_sess);
      },
      close(){
          this.show_sess_info = false;
          this.current_sess_id = null;
      },
      select_groomer(id, name){
          if( this.groomer_id_loading !== null ){
              return;
          }
          var sess_id = this.current_sess.id;
          this.groomer_id_loading = id;
          if( this.groomer_id_loading === this.current_sess.groomer_id ){
              console.log('already me.');
              this.groomer_id_loading = null;
              return;
          }



          request
              .post('/api/session/update', {
                  sess_id,
                  groomer_id: id,
                  groomer_name: name
              })//, config
              .then((sess_u) => {
                  console.log(sess_u);
                  //load the sess again
                  request
                      .post('/api/session/get_by_id', {
                          id: sess_id
                      })//, config
                      .then((sess_n) => {
                          // here
                          // this.current_sess = sess_n.data;
                          this.sessions[this.current_sess_id] = sess_n.data;
                          this.groomer_id_loading = null;
                      })
                      .catch((sess_n_err) => {
                          console.log(sess_n_err);
                          this.groomer_id_loading = null;
                      });
              })
              .catch((sess_u_err) => {
                  console.log(sess_u_err);
                  this.groomer_id_loading = null;
              });
      },
      select_status(status){
          console.log(status);
          if( this.status_txt_loading !== null ){
              return;
          }
          var sess_id = this.current_sess.id;
          this.status_txt_loading = status;
          if( this.status_txt_loading === this.current_sess.status ){
              console.log('already same status.');
              this.status_txt_loading = null;
              return;
          }



          request
              .post('/api/session/update', {
                  sess_id,
                  status
              })//, config
              .then((sess_u) => {
                  console.log(sess_u);
                  //load the sess again
                  request
                      .post('/api/session/get_by_id', {
                          id: sess_id
                      })//, config
                      .then((sess_n) => {
                          // here
                          // this.current_sess = sess_n.data;
                          this.sessions[this.current_sess_id] = sess_n.data;
                          this.status_txt_loading = null;
                      })
                      .catch((sess_n_err) => {
                          console.log(sess_n_err);
                          this.status_txt_loading = null;
                      });
              })
              .catch((sess_u_err) => {
                  console.log(sess_u_err);
                  this.status_txt_loading = null;
              });
      },
      select_service(service){
          var sess_id = this.current_sess.id;
          if( this.service_loading === null ){
              

              // shower select
              if( service === 'shower' && this.current_sess.shower === false ){
                  this.service_loading = service;
                  //deselct medi
                  //and select shower
                  request
                      .post('/api/session/update', {
                          sess_id,
                          service
                      })//, config
                      .then((sess_u) => {
                          console.log(sess_u);
                          //load the sess again
                          request
                              .post('/api/session/get_by_id', {
                                  id: sess_id
                              })//, config
                              .then((sess_n) => {
                                  // here
                                  // this.current_sess = sess_n.data;
                                  this.sessions[this.current_sess_id] = sess_n.data;
                                  this.service_loading = null;
                              })
                              .catch((sess_n_err) => {
                                  console.log(sess_n_err);
                                  this.service_loading = null;
                              });
                      })
                      .catch((sess_u_err) => {
                          console.log(sess_u_err);
                          this.service_loading = null;
                      });

              //remove shower
              }else if( service === 'shower' && this.current_sess.shower === true ){
                  //['shower', 'medical_shower', 'hair_cut', 'hair_trimming', 'nail_trimming', 'anal_gland']
                  if(  this.current_sess.medical_shower === true || 
                  this.current_sess.hair_cut === true ||
                  this.current_sess.hair_trimming === true ||
                  this.current_sess.nail_trimming === true ||
                  this.current_sess.anal_gland === true){

                      this.service_loading = service;
                      var service_r = service;

                      request
                              .post('/api/session/update', {
                                  sess_id,
                                  service_r
                              })//, config
                              .then((sess_u) => {
                                  console.log(sess_u);
                                  //load the sess again
                                  request
                                      .post('/api/session/get_by_id', {
                                          id: sess_id
                                      })//, config
                                      .then((sess_n) => {
                                          // here
                                          // this.current_sess = sess_n.data;
                                          this.sessions[this.current_sess_id] = sess_n.data;
                                          this.service_loading = null;
                                      })
                                      .catch((sess_n_err) => {
                                          console.log(sess_n_err);
                                          this.service_loading = null;
                                      });
                              })
                              .catch((sess_u_err) => {
                                  console.log(sess_u_err);
                                  this.service_loading = null;
                              });


                  }
              }else if( service === 'medical_shower' && this.current_sess.medical_shower === false ){
                  this.service_loading = service;
                  //deselct shower
                  //and select medical_shower
                  request
                      .post('/api/session/update', {
                          sess_id,
                          service
                      })//, config
                      .then((sess_u) => {
                          console.log(sess_u);
                          //load the sess again
                          request
                              .post('/api/session/get_by_id', {
                                  id: sess_id
                              })//, config
                              .then((sess_n) => {
                                  // here
                                  // this.current_sess = sess_n.data;
                                  this.sessions[this.current_sess_id] = sess_n.data;
                                  this.service_loading = null;
                              })
                              .catch((sess_n_err) => {
                                  console.log(sess_n_err);
                                  this.service_loading = null;
                              });
                      })
                      .catch((sess_u_err) => {
                          console.log(sess_u_err);
                          this.service_loading = null;
                      });

              //remove medical_shower
              }else if( service === 'medical_shower' && this.current_sess.medical_shower === true ){
                  //['shower', 'medical_shower', 'hair_cut', 'hair_trimming', 'nail_trimming', 'anal_gland']
                  if(  this.current_sess.shower === true || 
                  this.current_sess.hair_cut === true ||
                  this.current_sess.hair_trimming === true ||
                  this.current_sess.nail_trimming === true ||
                  this.current_sess.anal_gland === true){

                      this.service_loading = service;
                      service_r = service;

                      request
                              .post('/api/session/update', {
                                  sess_id,
                                  service_r
                              })//, config
                              .then((sess_u) => {
                                  console.log(sess_u);
                                  //load the sess again
                                  request
                                      .post('/api/session/get_by_id', {
                                          id: sess_id
                                      })//, config
                                      .then((sess_n) => {
                                          // here
                                          // this.current_sess = sess_n.data;
                                          this.sessions[this.current_sess_id] = sess_n.data;
                                          this.service_loading = null;
                                      })
                                      .catch((sess_n_err) => {
                                          console.log(sess_n_err);
                                          this.service_loading = null;
                                      });
                              })
                              .catch((sess_u_err) => {
                                  console.log(sess_u_err);
                                  this.service_loading = null;
                              });


                  }
              }else if( service === 'hair_cut' && this.current_sess.hair_cut === false ){
                  this.service_loading = service;
                  //deselct hair_trimming
                  //and select hair_cut
                  request
                      .post('/api/session/update', {
                          sess_id,
                          service
                      })//, config
                      .then((sess_u) => {
                          console.log(sess_u);
                          //load the sess again
                          request
                              .post('/api/session/get_by_id', {
                                  id: sess_id
                              })//, config
                              .then((sess_n) => {
                                  // here
                                  // this.current_sess = sess_n.data;
                                  this.sessions[this.current_sess_id] = sess_n.data;
                                  this.service_loading = null;
                              })
                              .catch((sess_n_err) => {
                                  console.log(sess_n_err);
                                  this.service_loading = null;
                              });
                      })
                      .catch((sess_u_err) => {
                          console.log(sess_u_err);
                          this.service_loading = null;
                      });

              //remove hair_cut
              }else if( service === 'hair_cut' && this.current_sess.hair_cut === true ){
                  //['shower', 'medical_shower', 'hair_cut', 'hair_trimming', 'nail_trimming', 'anal_gland']
                  if(  this.current_sess.shower === true || 
                  this.current_sess.medical_shower === true ||
                  this.current_sess.hair_trimming === true ||
                  this.current_sess.nail_trimming === true ||
                  this.current_sess.anal_gland === true){

                      this.service_loading = service;
                      service_r = service;

                      request
                              .post('/api/session/update', {
                                  sess_id,
                                  service_r
                              })//, config
                              .then((sess_u) => {
                                  console.log(sess_u);
                                  //load the sess again
                                  request
                                      .post('/api/session/get_by_id', {
                                          id: sess_id
                                      })//, config
                                      .then((sess_n) => {
                                          // here
                                          // this.current_sess = sess_n.data;
                                          this.sessions[this.current_sess_id] = sess_n.data;
                                          this.service_loading = null;
                                      })
                                      .catch((sess_n_err) => {
                                          console.log(sess_n_err);
                                          this.service_loading = null;
                                      });
                              })
                              .catch((sess_u_err) => {
                                  console.log(sess_u_err);
                                  this.service_loading = null;
                              });


                  }
              }else if( service === 'hair_trimming' && this.current_sess.hair_trimming === false ){
                  this.service_loading = service;
                  //deselct hair_cut
                  //and select hair_trimming
                  request
                      .post('/api/session/update', {
                          sess_id,
                          service
                      })//, config
                      .then((sess_u) => {
                          console.log(sess_u);
                          //load the sess again
                          request
                              .post('/api/session/get_by_id', {
                                  id: sess_id
                              })//, config
                              .then((sess_n) => {
                                  // here
                                  // this.current_sess = sess_n.data;
                                  this.sessions[this.current_sess_id] = sess_n.data;
                                  this.service_loading = null;
                              })
                              .catch((sess_n_err) => {
                                  console.log(sess_n_err);
                                  this.service_loading = null;
                              });
                      })
                      .catch((sess_u_err) => {
                          console.log(sess_u_err);
                          this.service_loading = null;
                      });

              //remove hair_trimming
              }else if( service === 'hair_trimming' && this.current_sess.hair_trimming === true ){
                  //['shower', 'medical_shower', 'hair_cut', 'hair_trimming', 'nail_trimming', 'anal_gland']
                  if(  this.current_sess.shower === true || 
                  this.current_sess.medical_shower === true ||
                  this.current_sess.hair_cut === true ||
                  this.current_sess.nail_trimming === true ||
                  this.current_sess.anal_gland === true){

                      this.service_loading = service;
                      service_r = service;

                      request
                              .post('/api/session/update', {
                                  sess_id,
                                  service_r
                              })//, config
                              .then((sess_u) => {
                                  console.log(sess_u);
                                  //load the sess again
                                  request
                                      .post('/api/session/get_by_id', {
                                          id: sess_id
                                      })//, config
                                      .then((sess_n) => {
                                          // here
                                          // this.current_sess = sess_n.data;
                                          this.sessions[this.current_sess_id] = sess_n.data;
                                          this.service_loading = null;
                                      })
                                      .catch((sess_n_err) => {
                                          console.log(sess_n_err);
                                          this.service_loading = null;
                                      });
                              })
                              .catch((sess_u_err) => {
                                  console.log(sess_u_err);
                                  this.service_loading = null;
                              });


                  }
              }else if( service === 'nail_trimming' && this.current_sess.nail_trimming === false ){
                  this.service_loading = service;
                  //deselct hair_cut
                  //and select hair_trimming
                  request
                      .post('/api/session/update', {
                          sess_id,
                          service
                      })//, config
                      .then((sess_u) => {
                          console.log(sess_u);
                          //load the sess again
                          request
                              .post('/api/session/get_by_id', {
                                  id: sess_id
                              })//, config
                              .then((sess_n) => {
                                  // here
                                  // this.current_sess = sess_n.data;
                                  this.sessions[this.current_sess_id] = sess_n.data;
                                  this.service_loading = null;
                              })
                              .catch((sess_n_err) => {
                                  console.log(sess_n_err);
                                  this.service_loading = null;
                              });
                      })
                      .catch((sess_u_err) => {
                          console.log(sess_u_err);
                          this.service_loading = null;
                      });

              //remove nail_trimming
              }else if( service === 'nail_trimming' && this.current_sess.nail_trimming === true ){
                  //['shower', 'medical_shower', 'hair_cut', 'hair_trimming', 'nail_trimming', 'anal_gland']
                  if(  this.current_sess.shower === true || 
                  this.current_sess.medical_shower === true ||
                  this.current_sess.hair_cut === true ||
                  this.current_sess.hair_trimming === true ||
                  this.current_sess.anal_gland === true){

                      this.service_loading = service;
                      service_r = service;

                      request
                              .post('/api/session/update', {
                                  sess_id,
                                  service_r
                              })//, config
                              .then((sess_u) => {
                                  console.log(sess_u);
                                  //load the sess again
                                  request
                                      .post('/api/session/get_by_id', {
                                          id: sess_id
                                      })//, config
                                      .then((sess_n) => {
                                          // here
                                          // this.current_sess = sess_n.data;
                                          this.sessions[this.current_sess_id] = sess_n.data;
                                          this.service_loading = null;
                                      })
                                      .catch((sess_n_err) => {
                                          console.log(sess_n_err);
                                          this.service_loading = null;
                                      });
                              })
                              .catch((sess_u_err) => {
                                  console.log(sess_u_err);
                                  this.service_loading = null;
                              });


                  }
              }else if( service === 'anal_gland' && this.current_sess.anal_gland === false ){
                  this.service_loading = service;
                  //deselct hair_cut
                  //and select hair_trimming
                  request
                      .post('/api/session/update', {
                          sess_id,
                          service
                      })//, config
                      .then((sess_u) => {
                          console.log(sess_u);
                          //load the sess again
                          request
                              .post('/api/session/get_by_id', {
                                  id: sess_id
                              })//, config
                              .then((sess_n) => {
                                  // here
                                  // this.current_sess = sess_n.data;
                                  this.sessions[this.current_sess_id] = sess_n.data;
                                  this.service_loading = null;
                              })
                              .catch((sess_n_err) => {
                                  console.log(sess_n_err);
                                  this.service_loading = null;
                              });
                      })
                      .catch((sess_u_err) => {
                          console.log(sess_u_err);
                          this.service_loading = null;
                      });

              //remove anal_gland
              }else if( service === 'anal_gland' && this.current_sess.anal_gland === true ){
                  //['shower', 'medical_shower', 'hair_cut', 'hair_trimming', 'nail_trimming', 'anal_gland']
                  if(  this.current_sess.shower === true || 
                  this.current_sess.medical_shower === true ||
                  this.current_sess.hair_cut === true ||
                  this.current_sess.hair_trimming === true ||
                  this.current_sess.nail_trimming === true){

                      this.service_loading = service;
                      service_r = service;

                      request
                              .post('/api/session/update', {
                                  sess_id,
                                  service_r
                              })//, config
                              .then((sess_u) => {
                                  console.log(sess_u);
                                  //load the sess again
                                  request
                                      .post('/api/session/get_by_id', {
                                          id: sess_id
                                      })//, config
                                      .then((sess_n) => {
                                          // here
                                          // this.current_sess = sess_n.data;
                                          this.sessions[this.current_sess_id] = sess_n.data;
                                          this.service_loading = null;
                                      })
                                      .catch((sess_n_err) => {
                                          console.log(sess_n_err);
                                          this.service_loading = null;
                                      });
                              })
                              .catch((sess_u_err) => {
                                  console.log(sess_u_err);
                                  this.service_loading = null;
                              });


                  }
              }
          }
      },
      close_money(){
          this.show_money_info = false;
      },
      open_money(){
          this.show_money_info = true;
          console.log(this.groomer_money, this.finished_sess);
      },
      select_breed_size(giant){
          var sess_id = this.current_sess.id;
          if( this.breed_size_loading === true ){
              return;
          } 
          console.log(sess_id);
          this.breed_size_loading = true;
          giant = !giant;
          request
              .post('/api/session/update_breed_size', {
                  sess_id,
                  giant
              })//, config
              .then(() => {
                  request
                      .post('/api/session/get_by_id', {
                          id: sess_id
                      })//, config
                      .then((sess_n) => {
                          // here
                          // this.current_sess = sess_n.data;
                          this.sessions[this.current_sess_id] = sess_n.data;
                          this.breed_size_loading = null;
                      })
                      .catch((sess_n_err) => {
                          console.log(sess_n_err);
                          this.breed_size_loading = null;
                      });
              })
              .catch((sess_n_err) => {
                  console.log(sess_n_err);
                  this.breed_size_loading = null;
              });
      },
      find_by_date(){
        if( this.date_loading && this.date_loading === true ){
          return;
        }
        if( !this.date || this.date === null ){
          // this.date_err = false;
          console.log(this.date);
          return;
        }
        this.date_loading = true;
        this.date_err = false;
        request
          .post('/api/session/get_sess_by_date', {date_: this.date})//, config
          .then((res) => {
              this.sessions = res.data;
              this.date_loading = false;
          })
          .catch((e) => {
              console.log(e);
              this.date_loading = false;
              this.date_err = true;
          });
      }
  },
  computed: {
      ...mapState([
            'role',
            'logged_in',
            'company'

          // 'branch_id',
          // 'socket'
      ]),
      current_sess(){
          if( this.current_sess_id !== null ){
              return this.sessions[this.current_sess_id];
          }
          return null;
      },
      finished_sess(){
          return this.sessions.filter( session => 
                  session.status === 'finished'
              );
      },
      groomer_money(){
          var obj = {};
          this.finished_sess.forEach((session) => {
              // dog shower
              if( session.pet_type === 'dog' && session.shower === true ){
                  if( session.groomer_name && session.groomer_name !== null ){
                      if(obj[session.groomer_name] && obj[session.groomer_name] !== null ){
                          obj[session.groomer_name] += 20;
                      }else{
                          obj[session.groomer_name] = 20;
                      }
                      //if giant
                      if( session.giant && session.giant === true ){
                          obj[session.groomer_name] += 20;
                      }
                  }
              }

              // dog hair cut
              if( session.pet_type === 'dog' && session.hair_cut === true ){
                  if( session.groomer_name && session.groomer_name !== null ){
                      if(obj[session.groomer_name] && obj[session.groomer_name] !== null ){
                          obj[session.groomer_name] += 20;
                      }else{
                          obj[session.groomer_name] = 20;
                      }
                      //if giant
                      if( session.giant && session.giant === true ){
                          obj[session.groomer_name] += 20;
                      }
                  }
              }

              // dog medical shower
              
              if( session.pet_type === 'dog' && session.medical_shower === true ){
                  if( session.groomer_name && session.groomer_name !== null ){
                      if(obj[session.groomer_name] && obj[session.groomer_name] !== null ){
                          obj[session.groomer_name] += 40;
                      }else{
                          obj[session.groomer_name] = 40;
                      }
                  }
              }

              //herrre
              //hair_trimming
              if( session.pet_type === 'dog' && session.hair_trimming === true ){
                  if( session.groomer_name && session.groomer_name !== null ){
                      if(obj[session.groomer_name] && obj[session.groomer_name] !== null ){
                          obj[session.groomer_name] += 10;
                      }else{
                          obj[session.groomer_name] = 10;
                      }
                  }
              }


              //////-----------------------
              // cat shower
              
                  if( session.pet_type === 'cat' && session.shower === true ){
                      if( session.groomer_name && session.groomer_name !== null ){
                          if(obj[session.groomer_name] && obj[session.groomer_name] !== null ){
                              obj[session.groomer_name] += 20;
                          }else{
                              obj[session.groomer_name] = 20;
                          }
                      }
                  }


              // cat hair cut
              
                  if( session.pet_type === 'cat' && session.hair_cut === true ){
                      if( session.groomer_name && session.groomer_name !== null ){
                          if(obj[session.groomer_name] && obj[session.groomer_name] !== null ){
                              obj[session.groomer_name] += 20;
                          }else{
                              obj[session.groomer_name] = 20;
                          }
                      }
                  }


              // cat medical shower
              if( session.pet_type === 'cat' && session.medical_shower === true ){
                  if( session.groomer_name && session.groomer_name !== null ){
                      if(obj[session.groomer_name] && obj[session.groomer_name] !== null ){
                          obj[session.groomer_name] += 40;
                      }else{
                          obj[session.groomer_name] = 40;
                      }
                  }

              }


              //herrre
              //hair_trimming
              if( session.pet_type === 'cat' && session.hair_trimming === true ){
                  if( session.groomer_name && session.groomer_name !== null ){
                      if(obj[session.groomer_name] && obj[session.groomer_name] !== null ){
                          obj[session.groomer_name] += 10;
                      }else{
                          obj[session.groomer_name] = 10;
                      }
                  }
              }
          });
          return obj;
      }
  }
}
</script>

<style scoped>


.c-admin_order_count_number_count{
  width: 300px;
  height: 150px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.08)
}

.c-admin_order_count_number_count{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #006837;
  font-size: 100px;
  font-weight: 500
}

.c-admin_order_count_number_count span{
  align-self: center;
  font-family: arial
}

.c-admin_order_count_title{
  font-size: 30px;
  margin-bottom: 10px;
  margin-top: 0px;
  font-weight: 500
}

.stat_cont{
  align-items: center;
}

.accounts_total{
  margin: 20px 0px 40px 0px;
}


.result_table_cont {
  width: 100%;
  border: 1px solid #f2f2f2;
  /* background-color: white; */
  /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08); */
  /* padding: 10px; */
  border-radius: 5px;
  min-width: 940px;
  max-width: 1450px;
}
.result_table_cont table {
  width: 100%;
  border-collapse: collapse;
}
.result_table_cont table tr {
  border-bottom: 1px solid #E6E6E6;
}
.result_table_cont table tr:last-child {
  border-bottom: none;
}
.result_table_cont table tr th {
  text-align: left;
  font-size: 18px;
}
.result_table_cont table tr td {
  font-size: 17px;
  font-weight: 500;
}
.result_table_cont table tr td, .result_table_cont table tr th {
  padding: 10px 0px;
}

.scroll_y {
  overflow-x: auto;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);
  background-color: white;
  border-radius: 5px;
}

.edit_product_table{
  height: 30px;
}

.product_edit_cont{
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  padding: 0px 10px;
  width: 100vw;
  height: 100vh;
  justify-content: center;
}

.product_edit{
  width: 100%;
  max-width: 500px;
  background-color: white;
  padding: 15px;
  border-radius: 3px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 8%);
  border: 1px solid #ccc;
}

.product_edit .secondary {
  margin-top: 10px;
}

.no__{
  width: 100%;
  max-width: 500px;
  margin: auto;
  padding: 10px 15px;
  background-color: #cae6ff;
  border-radius: 3px;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.no_result_center{
  padding: 10px;
  border-radius: 3px;
  width: 100%;
  background-color: #cccccc8c;
  /* display: flex; */
  /* width: 100vw; */
  text-align: center;
  /* color: #673AB7; */
  color: #373737;
  font-weight: 500;
}
/* .pageloading_cont{
  height: 200px;
} */

.spinner_cont_page_{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25vh;
}

.spinner_cont_page_ .spinner_circle{
  height: 100px;
}

.manage{
  color: #317BEB;
  cursor: pointer;
  width: fit-content;
  margin: auto;
}

.sess_info_cont{
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #00000052;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sess_info{
  margin-top: 5%;
  background-color: white;
  width: 90%;
  max-width: 500px;
  padding: 10px;
  border-radius: 3px;
  overflow-y: auto;
  max-height: 85%;
}

.close_cont{
  display: flex;
  flex-direction: column;
}

.close{
  text-align: end;
  color: red;
  font-weight: bold;
  width: fit-content;
  align-self: end;
  cursor: pointer;
}

.groomers_heading{
  margin-bottom: 15px;
  font-weight: bold;
}

.groomer{
  cursor: pointer;
  background-color: #cccccca8;
  padding: 6px 10px;
  border-radius: 50px;
  margin-right: 5px;
  margin-bottom: 7px
}

.service{
  font-size: 20px;
  margin-bottom: 10px;
}

.other{
  border: 1px solid;
  padding: 3px 5px;
  border-radius: 3px;
  background-color: #33333314;
}

hr{
  margin-top: 25px;
  border: 1px solid;
}

.pet_name{
  font-size: 20px;
  text-align: center;
  width: fit-content;
  margin: auto;
  padding: 5px 10px;
  border: 2px solid;
  border-radius: 3px;
}

.pet_info{
  font-size: 20px;
  text-align: center;
  margin-top: 7px;
}

.groomer_selected{
  background-color: #0000ff70;
  cursor: auto;
}

.heading_cont_{
  justify-content: space-between;
  align-items: center;
}
.money{
  color: #317BEB;
  cursor: pointer;
  font-size: 17px;
  font-weight: 500;
}

.money_txt{
  font-size: 25px;
}

.money_info{
  padding: 10px 10px 10px 25px;
}
.groomers_names_cont{
  flex-wrap: wrap;
}

/* tr:nth-child(even){background-color: #f2f2f2;} */

/* .parent_name{
  padding-left: 12px !important;
} */

td, th {
  /* border: 1px solid #ddd; */
  padding: 8px !important;
}

.manage_td{
  margin: auto;
}

tr:hover{
  background-color: #317beb0d;
  /* cursor: pointer; */
}

.not_hover:hover{
  background-color: transparent;
  cursor: auto;
  
}

.no_money{
  text-align: center;
  color: #5d5d5d;
  padding: 15px 0px;
  font-size: 18px;
}

.tr_showering{
  border-left: 5px solid #2196f3;
}

.tr_waiting{
  border-left: 5px solid #ffc107;
}

.tr_finished{
  border-left: 5px solid green;
}

.service{
  cursor: pointer;
}

.pointer{
  cursor: pointer;
}

.date_input{
  width: 100%;
  height: 50px;
  /* background-color: #d2dbf3; */
  background-color: #fff;
  /* border: 1px solid #647193; */
  border: 1px solid #ccc;
  /* border-right: 0px; */
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 20px;
  /* -webkit-transition: 0.3s; */
  transition: 0.1s;
  outline: none;
  height: 50px;
  max-width: 250px;
}

.find_date_btn{
  height: auto;
  width: auto;
  font-size: 19px;
  max-height: 50px;
  height: 50px;
  margin-left: 5px;
}

.error{
  font-weight: 500;
  color: red;
}

.date_form{
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
}

.table_hr{
  margin-top: 25px;
  margin-bottom: 15px;
  border: 1px solid #9a9a9a;
}
</style>