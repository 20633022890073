<template>
    <footer>
        <div class="row footer_cont">
            <div class="contact_us">contact us? <a href="tel:+201094602675">+20 109 460 2675</a></div>
            <div class="row powered_by">
            <div class="txtt">
                powered by 
                <b>pharaoh frog</b>
            </div>
            <img class="pharaohfrog_logo" src="@/assets/pharaohfrog.png" alt="pharaohfrog">
            </div>
        </div>
    </footer>
</template>

<script>

export default {
    name: 'AdminFooter',
}
</script>

<style scoped>
.footer_cont{
    justify-content: space-between;
    margin-top: 10px;
}

.pharaohfrog_logo{
    height: 25px;
    margin-left: 5px;
}
/* .powered_by{
    margin-top: 5px;
} */

a{
    text-decoration: none;
}

a:hover{
    text-decoration: underline;
}

/*Small devices (landscape phones, 576px and up)*/
/* @media (max-width: 800px) {

} */

@media (max-width: 520px) {
    .footer_cont{
        flex-direction: column;
    }
    .powered_by{
        order: 1;
        margin-bottom: 3px;
    }
    .contact_us{
        order: 2;
    }
}
</style>