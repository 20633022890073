import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import FirststepGroomingView from '../views/FirststepGroomingView.vue'
import SecondstepGroomingView from '../views/secondstepGroomingView.vue'
import ThirdstepGroomingView from '../views/thirdstepGroomingView.vue'
import FourthstepGroomingView from '../views/fourthstepGroomingView.vue'
import FifthstepGroomingView from '../views/fifthstepGroomingView.vue'
import ThirdstepLoggedGrooming from '../views/thirdstepLoggedGroomingView.vue'
import AdminManageGroomers from '../views/AdminManageGroomers.vue'
import SuccessSessV from '../views/SuccessSessV.vue'


// import FirststepClinicView from '../views/FirststepClinicView.vue'
// import SecondstepClinicView from '../views/SecondstepClinicView.vue'

import AdminHomeView from '../views/adminHomeView.vue'
import AdminLoginView from '../views/adminLoginView.vue'

import AdminFindByDateView from '../views/AdminFindByDateView.vue'
import AdminFindByPhoneView from '../views/AdminFindByPhoneView.vue'
import AdminCompanyLineView from '../views/AdminCompanyLineView.vue'
import AdminLongTimeNoSeeView from '../views/AdminLongTimeNoSeeView.vue'


import pharaohfrog from '../components/pharaohfrog.vue'






import Nprogress from 'nprogress'
import 'nprogress/nprogress.css'

Nprogress.configure({ showSpinner: false });


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },


  {
    path: '/grooming',
    name: 'grooming',
    component: FirststepGroomingView
  },
  {
    path: '/step-2',
    name: 'grooming_step_2',
    component: SecondstepGroomingView
  },
  {
    path: '/step-3',
    name: 'grooming_step_3',
    component: ThirdstepGroomingView
  },
  {
    path: '/step-4',
    name: 'grooming_step_4',
    component: FourthstepGroomingView
  },
  {
    path: '/step-5',
    name: 'grooming_step_5',
    component: FifthstepGroomingView
  },
  {
    path: '/step-3-logged',
    name: 'step_3_logged',
    component: ThirdstepLoggedGrooming
  },

  {
    path: '/sess',
    name: 'sess',
    component: SuccessSessV
  },


  // {
  //   path: '/clinic',
  //   name: 'clinic_home',
  //   component: FirststepClinicView
  // },
  // {
  //   path: '/clinic/booked',
  //   name: 'clinic_second',
  //   component: SecondstepClinicView
  // },

  {
    path: '/pharaohfrog',
    name: 'pharaohfrog',
    component: pharaohfrog
  },

  {
    path: '/admin',
    name: 'admin',
    component: AdminHomeView
  },
  {
    path: '/login',
    name: 'admin_login',
    component: AdminLoginView
  },
  {
    path: '/admin/find-session-by-date',
    name: 'admin_find_session_by_date',
    component: AdminFindByDateView
  },
  {
    path: '/admin/find-session-by-phone',
    name: 'admin_find_session_by_phone',
    component: AdminFindByPhoneView
  },
  {
    path: '/admin/manage-groomers',
    name: 'admin_manage_groomers',
    component: AdminManageGroomers
  },
  {
    path: '/admin/branches-line',
    name: 'admin_company_company',
    component: AdminCompanyLineView
  },
  {
    path: '/admin/long-time-no-see',
    name: 'admin_long_time_no_see',
    component: AdminLongTimeNoSeeView
  },
  {
    // path: "*",
    path: "/:catchAll(.*)",
    redirect: '/'
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

// const router = createRouter({
//   history: createWebHashHistory(),
//   routes
// })

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

//router loader
router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // console.log(from);
    // Start the route progress bar.
    Nprogress.start()
  }
  next()
})

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  Nprogress.done()
})

router.beforeEach((to, from, next) => {
  if (to.name === 'admin_login' ) {
    if (localStorage.getItem('token')) {
      next('/');
    }else{
      next();
    }
  }else{
    next();
  }
})

export default router
