<template>
  <div class="row home_comp_cont">
    <div class="page_loader_cont" v-if="page_loader">
      <div class="spinner_height">
        <spinner-circle :black="true"></spinner-circle>
      </div>
    </div>
    <div v-if="show_reset_form" class="delete_cont">
        <div class="delete_cont_2">
          <div class="delete_heading">If you go to manage pets page you will lose the pet data you have entered right now !</div>
          <div  class="row btn_del_cont">
            <button @click="close_reset_form_prompt()" class="pop_btn cencel_btn">Cencel</button>
            <button class="pop_btn delete_btn">
              <div @click="go_back_()">Continue</div>
            </button>
          </div>
        </div>
      </div>
    <div v-if="show_d_prompt" class="delete_cont">
      <div class="delete_cont_2">
        <div class="delete_heading">Are you sure you want to delete '{{ d_selected_name }}' ?</div>
        <div class="row btn_del_cont">
          <button @click="close_d_prompt()" class="pop_btn cencel_btn">Cencel</button>
          <button class="pop_btn delete_btn">
            <red-spinner v-if="d_loading" :red="true"></red-spinner>
            <div v-else @click="delete_pet()">Delete</div>
          </button>
        </div>
        <div class="error_msg d_error_msg" v-show="d_error">{{ d_error }}</div>

      </div>

    </div>
    <div class="left">
      <!-- <img class="row img" src="@/assets/logo2.png" alt="logo"> -->
      <img class="row img" :src="linkk + '/' + company_logo" alt="logo">
      <div class=" powered_by_customer">
            powered by 
            <router-link to="/pharaohfrog"  class="ph_link"><b>pharaoh frog</b></router-link>
          </div>

      <!-- <button class="add_pet_2" >Save & Add New Pet</button> -->
      <button class="add_pet_2" @click.prevent="from_step_3(2)">
        <div class="loading" v-if="loading && btn_num === 2">
          <Spinner :black="true" />
        </div>
        <div class="btn_txt" v-else>Save & Add New Pet</div>
      </button>
      <div class="phone_step not_my_phone" @click="$router.push({ name: 'home' })">go back to phone step?</div>
      <div class="pets_list" v-if="check_if_any_pet_selected">
        <h4>Your Pets</h4>
        <!-- <hr> -->
        <ul>
          <li v-for="pet in selected_pets_2" :key="pet.id">
            <div class="row list_item_div">
              <div>{{ pet.name }}</div>
              <!-- <img class="row img" src="@/assets/dog_list.svg" alt="dog_list"> -->
              <img @click="open_d_prompt(pet.id, pet.name)" class="row img delete_icon" src="@/assets/delete-outline.svg"
                alt="dog_list">
            </div>
          </li>
        </ul>
      </div>

    </div>
    <div class="right column">
      <h1 class="hone hone_3">Pet Grooming Service</h1>
      <div class="row btn_home_cont column">
        <form @keyup.enter="from_step_3()">
          <div class="row pet_name_cont">
            <input type="text" placeholder="Pet Name" class="3_input next_to_input" v-model="name">
            <div class="coulmn">
              <div class="label" @click="choose_type('dog')">Dog</div>
              <div @click="choose_type('dog')"
                :class="type && type === 'dog' ? 'label_img_cont selected' : 'label_img_cont'">
                <img class="label_img" src="@/assets/dog.svg">
              </div>
            </div>
            <div class="coulmn mr">
              <div class="label" @click="choose_type('cat')">Cat</div>
              <div @click="choose_type('cat')"
                :class="type && type === 'cat' ? 'label_img_cont selected' : 'label_img_cont'">
                <img class="label_img" src="@/assets/cat.svg">
              </div>
            </div>
          </div>

          <div class="row mr_bottom_25 pet_name_cont pet_breed_cont">
            <input type="text" placeholder="Breed" class="3_input next_to_input" v-model="breed">
            <div class="column">
              <div class="label" @click="choose_sex('male')">Male</div>
              <div @click="choose_sex('male')"
                :class="sex && sex === 'male' ? 'label_img_cont selected' : 'label_img_cont'">
                <img class="label_img" src="@/assets/male.svg">
              </div>
            </div>
            <div class="coulmn mr">
              <div class="label" @click="choose_sex('female')">Female</div>
              <div @click="choose_sex('female')"
                :class="sex && sex === 'female' ? 'label_img_cont selected' : 'label_img_cont'">
                <img class="label_img" src="@/assets/female.svg">
              </div>
            </div>
          </div>

          <!-- <input type="text" placeholder="Age" class="3_input mr_bottom_25 full_width"> -->
          <div class="column birthday_cont">
            <label for="birthday" class="label birthday_label">Pet birthday</label>
            <!-- <input type="date" id="birthday" name="birthday" v-model="birthday"> -->
            <div class="date_cont row">
              <!-- day -->
              <select v-if="day_" v-model="day_" aria-label="Day" name="birthday_day" id="day" title="Day" class="_9407 _5dba _9hk6 _8esg"
                aria-describedby="js_8b" tabindex="0">
                <option v-for="(index) in 31" :value="index" :key="index" >{{index}}</option>
              </select>
              <!-- month -->
              <select  v-model="month_" aria-label="Month" name="birthday_month" id="month" title="Month" class="_9407 _5dba _9hk6 _8esg">
                <option value="01" >Jan</option>
                <option value="02" >Feb</option>
                <option value="03" >Mar</option>
                <option value="04" >Apr</option>
                <option value="05" >May</option>
                <option value="06" >Jun</option>
                <option value="07" >Jul</option>
                <option value="08" >Aug</option>
                <option value="09" >Sep</option>
                <option value="10" >Oct</option>
                <option value="11" >Nov</option>
                <option value="12" >Dec</option>
              </select>
              <!-- year -->
              <select v-if="current_year && current_year !== null" v-model="current_year" aria-label="Year" name="birthday_year" id="year" title="Year" class="year_select">
                <option v-for="i in years(current_year_r)" :key="i"  >{{i}}</option>
              </select>
            </div>

          </div>

          <!-- <textarea placeholder="Health Issue / Condition" class="mr_bottom_25" v-model="health_condition"></textarea> -->

          <!-- <textarea placeholder="Behavior Notes" class="mr_bottom_25" v-model="behavior_notes"></textarea> -->
        </form>

        <div class="error_msg" v-show="error">{{ error }}</div>


        <div class="row btns_cont">
          <button class="btn back_btn btn_first_grooming" @click="back_btn()">Manage Pets</button>
          <!-- <button class="btn btn_first_grooming" @click="$router.push('/grooming/step-2')">Next</button> -->
          <!-- <button class="btn btn_first_grooming" @click="$router.push('/grooming/step-4')">Next</button> -->

          <button :class="(loading && btn_num === 1) ? 'btn btn_first_grooming no_padding' : 'btn btn_first_grooming'"
            @click.prevent="from_step_3(1)">
            <div class="loading" v-if="loading && btn_num === 1">
              <Spinner />
            </div>
            <div class="btn_txt" v-else>Next</div>
          </button>
          <!-- <button class="btn btn_first_grooming" @click="from_step_3()">Next</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapState } from 'vuex';
import request from '../axios_request';
import Spinner from '@/components/Spinner.vue';
import RedSpinner from '@/components/RedSpinner.vue';
import SpinnerCircle from '@/components/SpinnerCircle.vue';
import { mapState, mapActions } from 'vuex';

export default {
  name: "thirdstepGrooming",
  components: {
    Spinner,
    RedSpinner,
    SpinnerCircle
  },
  data: function () {
    return {
      name: null,
      type: null,
      breed: null,
      sex: null,
      birthday: null,
      health_condition: null,
      behavior_notes: null,
      error: null,
      loading: false,
      d_loading: false,
      d_selected_name: null,
      d_id: null,
      btn_num: null,
      show_d_prompt: false,
      d_error: null,
      current_year: new Date().getFullYear(),
      current_year_r:new Date().getFullYear(),
      day_: new Date().getDate(),
      month_: new Date().getMonth() + 1,

      selected_pets_full: null,
      show_reset_form: false,

      page_loader: true,
      linkk: process.env.VUE_APP_SERVER_ENDPOINT,

    };
  },
  created() {
    console.log(this.day_);
    if(this.month_ <= 9){
      this.month_.toString();
      var x = null;
      x = "" + 0 + this.month_; 
      console.log(x);
      this.month_ = x;
      // var x = JSON.parse(JSON.stringify(this.form));
    }
    console.log(this.month_);
    if (!this.parent_id || this.parent_id === null) {
      this.$router.push({ name: 'home' });
      return;
    }

      // check if logged in
      if( localStorage.getItem('token') ){
          var token = localStorage.getItem('token');
          request.post('/api/system/is_logged', {token})//, config
          .then((response)=>{
            if( response.status === 200 ){
                this.set_logged_in(true);
                this.role_(response.data.role);
                this.admin_name_(response.data.name);
                this.company_logo_(response.data.img);
                console.log(response);
              }
          }).catch(e => {
              console.log(e);
              //route to login
              this.$router.push({name: 'admin_login'});
          });
      }

    // if (!this.current_year_r || this.current_year_r === null) {
    //   var currentTime = new Date();
    //   this.current_year_r = Number(currentTime.getFullYear());
    //   this.current_year = Number(currentTime.getFullYear());
    // }
    // this.day_ = new Date().getDate();


    var old_pets = this.pets;
    var temp_service_holder = {
      shower: false,
      medical_shower: false,
      hair_cut: false,
      hair_trimming: false,
      nail_trimming: false,
      anal_gland: false,
      other: null,
      current: false,
      selected: false
    }
    //get selected pets full
    request.post('/api/pet/get_by_parent', { parent_id: this.parent_id })
      .then((res) => {
        if (res.status === 200) {
          //check if new pets arr not empty
          if (res.data && Array.isArray(res.data) && res.data.length > 0) {
            res.data.forEach(function (pet) {
              Object.assign(pet, temp_service_holder);
              //check if its in old (if its in old merge else add empty services)
              if (old_pets && Array.isArray(old_pets) && old_pets.length > 0) {
                old_pets.forEach(function (pet_old) {
                  if (parseInt(pet.id) === parseInt(pet_old.id)) {
                    Object.assign(pet, pet_old);
                  }
                });
              }
            });
          } else {
            res.data = [];
          }
          //save all in store
          this.pets_(res.data);
          console.log(this.check_if_any_pet_selected);
          this.page_loader = false;
        } else {
          // means error
          console.log('error created');
          this.$router.push({ name: 'home' });
        }
      })
      .catch(e => {
        // means error
        console.log(e);
        this.$router.push({ name: 'home' });
      });
  },

  computed: {
    ...mapState([
      'phone',
      'parent_name',
      'parent_id',
      'pets',

      'selected_pets',
      'pet_sessions',
      'company_logo'
    ]),
    check_if_any_pet_selected() {
      var result = false;
      if (this.pets && Array.isArray(this.pets) && this.pets.length > 0) {
        this.pets.forEach(function (pet) {
          if (pet.selected && pet.selected === true) {
            result = true;
          }
        });
      }
      return result;
    },
    selected_pets_2() {
      return this.pets.filter(pet => pet.selected && pet.selected === true)
    }
  },
  methods: {
    ...mapActions([
      "selected_pets_",
      'pet_sessions_',

      'pets_',

      'set_logged_in',
      'role_',
      'admin_name_',
      'company_logo_'
    ]),
    getNumbers:function(start,stop){
            return new Array(stop-start).fill(start).map((n,i)=>n+i);
    },

    years:function(current_year_){
      var arr = [];
      for (let index = current_year_; index >= (current_year_ - 40); index--) {
          arr.push(index);
      }
      return arr;
    },

    from_step_3(btn_num) {
      //dont send if loading
      if (this.loading) {
        return;
      }

      // reset error
      this.error = null;

      // if i select pets and i want to go
      if (btn_num === 1 && !this.name && !this.type && !this.breed &&
        !this.sex && !this.birthday && !this.health_condition && !this.behavior_notes &&
        this.check_if_any_pet_selected) {
        this.$router.push({ name: 'grooming_step_4' });
      }

      //check name
      if (!this.name || this.name.length <= 0) {
        this.error = 'name must not be empty.';
        return;
      }

      //check type
      if (!this.type || this.type.length <= 0) {
        this.error = 'must choose type.';
        return;
      }

      //check breed
      if (!this.breed || this.breed.length <= 0) {
        this.error = 'must enter breed.';
        return;
      }

      //check sex
      if (!this.sex || this.sex.length <= 0) {
        this.error = "must choose your pet's sex.";
        return;
      }

      //check birthday
      // if (!this.birthday || this.birthday.length <= 0) {
      //   this.error = "must enter birthday.";
      //   return;
      // }

      // check if the date is vaild
      // console.log(this.day_);
      var dayy = this.day_;
      dayy.toString();
      // console.log(dayy.toString().length);
      if( dayy.toString().length === 1 ){
        dayy = `0${dayy}`
        // this.day_ = dayy;
        // console.log('jjj');
      }
      // console.log(this.day_);

      this.birthday = `${this.current_year}-${this.month_}-${dayy}`; //2023-03-09

      // check if the date is not in the future
      // now = false
      // future = true
      // back = false

      var d_now = new Date();
      var d_inp = new Date(this.birthday);

      // if(this.day_ !== null){
      //   console.log(d_now.setHours(0,0,0,0) <= d_inp.setHours(0,0,0,0));
      //   return;
      // }

      if( d_now.setHours(0,0,0,0) <= d_inp.setHours(0,0,0,0) ){
        this.error = "birthday cant be in future or today.";
        return;
      }
      // else{
      //   
      //   return;
      // }



      //check birthday is not today
      const date_ = new Date();
      let day = date_.getDate();
      let month = date_.getMonth() + 1;
      let year = date_.getFullYear();
      let currentDate = `${year}-${month}-${day}`;

      if ( this.birthday === currentDate ) {
        this.error = "birthday cant be today";
        return;
      }






      this.loading = true;
      this.btn_num = btn_num;

      var data = {
        "parent_id": this.parent_id,
        "name": this.name,
        "type": this.type,
        "breed": this.breed,
        "sex": this.sex,
        "birthday": this.birthday,
        "health_condition": this.health_condition,
        "behavior_notes": this.behavior_notes
      }
      //send it server
      request.post('/api/pet', data)
        .then((response) => {
          this.loading = false;
          if (response.status === 201) {
            var arr = this.pets;
            var new_pet = response.data;
            var temp_service_holder = {
              shower: false,
              medical_shower: false,
              hair_cut: false,
              hair_trimming: false,
              nail_trimming: false,
              anal_gland: false,
              other: null,
              current: false,
              selected: true
            }
            Object.assign(new_pet, temp_service_holder);
            arr.push(new_pet);
            this.pets_(arr);
            //clear form
            this.name = null;
            this.type = null;
            this.breed = null;
            this.sex = null;
            this.birthday = null;
            this.health_condition = null;
            this.behavior_notes = null;
            //stop loading
            this.loading = false;
            if (this.btn_num === 1) {
              this.$router.push({ name: 'grooming_step_4' });
            }


          } else {
            console.log('error sending data to server.');
            this.error = "error sending data to server.";
            this.loading = false;
          }
        })
        .catch(e => {
          console.log(e);
          if( e.response.data.errors[0].msg && e.response.data.errors[0].msg === 'Must be a valid date' ){
            this.error = "must enter a vaild date.";
          }else{
            this.error = "error.";
          }
          
          this.loading = false;
        });
    },

    choose_type(type) {
      this.type = type;
    },

    choose_sex(sex) {
      this.sex = sex;
    },

    open_d_prompt(id, name) {
      this.show_d_prompt = true;
      this.d_id = parseInt(id);
      this.d_selected_name = name;
      this.d_error = null;
    },

    close_d_prompt() {
      if (this.d_loading) {
        return;
      }
      this.show_d_prompt = false;
      this.d_id = null;
      this.d_selected_name = null;
    },

    delete_pet() {
      if (!this.check_if_any_pet_selected) {
        return;
      }

      var arr = this.pets;

      console.log(this.d_id);

      var index = arr.findIndex(pet => parseInt(pet.id) === parseInt(this.d_id));

      arr[index].selected = false;

      this.pets_(arr);

      this.show_d_prompt = false;
      this.d_id = null;
      this.d_selected_name = null;
    },
    back_btn() {
      // $router.push('/grooming/step-2')
      if( this.name !== null || this.breed !== null ){
        if( (this.name !== null && this.name.length > 0) || (this.breed !== null && this.breed.length > 0) ){
          console.log('dod');
          //show are u sure msg herre
          this.show_reset_form = true;
        }else{
          this.$router.push({ name: 'step_3_logged' });
        }
      }else{
        this.$router.push({ name: 'step_3_logged' });
      }
      
    },
    go_back_(){
      this.$router.push({ name: 'step_3_logged' });
    },
    close_reset_form_prompt(){
      this.show_reset_form = false;
    }
  }
}
</script>


<style scoped>
.home_comp_cont {
  padding-bottom: 10%;
}

.btn_first_grooming {
  font-size: 20px;
  padding: 10px 35px;
  border-radius: 5px;
}

/* .btn_first_grooming:focus {
border: 3px solid #555;
} */

/* .btn_first_grooming{
  outline-color: #fff;
  outline-style: none;
} */

form {
  width: 100%;
  max-width: 500px;
}

input {
  /* background-color: #d2dbf3; */
  background-color: #fff;
  /* border: 1px solid #647193; */
  border: 1px solid #ccc;
  /* border-right: 0px; */
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 20px;
  /* -webkit-transition: 0.3s; */
  transition: 0.1s;
  outline: none;
  height: 50px;
}

.input:focus {
  border: 1px solid #317BEB;
  border-right: 0px;
}

.pet_name_cont {
  align-items: end;
  margin-bottom: 5px;
}


.label_img_cont {
  border: 2px solid #365889;
  border: 2px solid #c7c7c7;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 70px;
  background-color: lightgrey;
  cursor: pointer;
}

.label_img {
  height: 30px;
  width: auto;
}

.btns_cont {
  justify-content: space-between;
}

.mr {
  margin-left: 10px;
}

.label {
  font-size: 15px;
  color: #2C4260;
  display: inline-block;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
}

.next_to_input {
  width: 30vw;
  margin-right: 10px;
}

textarea {
  display: block;
  width: 100%;
  padding: 5px 15px;

  /* background-color: #d2dbf3; */
  background-color: #fff;
  /* border: 1px solid #647193; */
  border: 1px solid #ccc;
  /* border-right: 0px; */
  border-radius: 5px;
  font-size: 20px;
  /* -webkit-transition: 0.3s; */
  transition: 0.1s;
  outline: none;
  min-height: 70px;
  font-family: inherit;
  resize: vertical;
}

.full_width {
  width: 100%;
}

.mr_bottom_25 {
  margin-bottom: 25px;
}

.hone_3 {
  margin-bottom: 15px;
}

.back_btn {
  border-width: 2px;
}

input:focus,
textarea:focus {
  border: 1px solid #317BEB;
}

.birthday_cont {
  margin-bottom: 30px;
}

.pet_breed_cont {
  margin-top: 20px;
  margin-bottom: 20px;
}

.birthday_label {
  margin-bottom: 5px;
}

.selected {
  border-color: #365889;
  background-color: #36588942;
}

.no_padding {
  padding: 0px 23px;
}

.add_pet_2 {
  cursor: pointer;
  padding: 7px 5px;
  /* font-weight: bold; */
  font-size: 20px;
  font-family: 'Poppins', sans-serif !important;
  color: #317BEB;
  /* font-size: 20px; */
  margin-bottom: 10px;
  border: 2px solid;
  /*background-color: #317beb26;*/
  border-radius: 3px;
  /* padding: 7px 10px; */
  margin-top: 30px;
  font-size: 15.3px;
  font-weight: 500;
  padding: 7px 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add_pet_2:hover {
  /* background-color: #317beb40;*/
  background-color: #317beb26;

}

.left {
  margin-right: 80px;
  /* margin-right: 150px; */
}

.list_item_div {
  justify-content: space-between;
}

.list_item_div img {
  height: 25px;
}

/* .right{
margin-left: 80px;
} */

.left img {
  margin-right: 0px;
}

.pets_list ul {
  list-style: none;
  padding: 0px 0px;
  margin-top: 0px;
  border-top: 1px solid #c7c7c7;
}

.pets_list ul li {
  /* border-bottom: 1px solid; */
  border-bottom: 1px solid #c7c7c7;
  padding-bottom: 16px;
  padding-top: 16px;
}

hr {
  margin: 0px;
  border: 1px solid #c7c7c7;
}

.check_list_cont {
  align-items: center;
  margin-bottom: 20px;
}

/*sidebar*/
.delete_icon {
  cursor: pointer;
}

.delete_cont {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: #317beb69;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

select{
  background-color: #fff;
  /* border: 1px solid #647193; */
  border: 1px solid #ccc;
  /* border-right: 0px; */
  border-radius: 5px;
  padding: 0px 8px;
  font-size: 20px;
  /* -webkit-transition: 0.3s; */
  transition: 0.1s;
  outline: none;
  height: 50px;
  width: 100%;
  margin-right: 10px;
}

.year_select{
  margin-right: 0px;
}

.delete_cont_2 {
  background-color: #fff;
  width: fit-content;
  max-width: 500px;
  border-radius: 3px;
  padding: 10px 20px;
}

.btn_del_cont {
  justify-content: space-between;
  margin-top: 25px;
}

.delete_heading {
  font-size: 19px;
}

.pop_btn {
  border: none;
  background-color: transparent;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.pop_btn:hover {
  text-decoration: underline;
}

.cencel_btn {
  color: #365889;
}

.delete_btn {
  color: red;
}

.d_error_msg {
  margin-top: 15px;
}

.phone_step {
  color: #5d8ced;
  font-weight: 500;
}

.phone_step:hover {
  text-decoration: underline;
  cursor: pointer;
}

/*Small devices (landscape phones, 576px and up)*/
@media (max-width: 800px) {
  .home_comp_cont {
    flex-direction: column;
  }

  .btn_home_cont {
    margin-bottom: 0px;
  }

  .btn_home_cont {
    margin-top: 0px;
  }

  .hone {
    margin-top: 26px;
  }

  input,
  .next_to_input {
    width: 100%;
  }

  .left{
    margin-right: 0px;
    width: 100%;
  }

  .not_my_phone{
    display: inline-block;
    margin-top: 5px;
  }
}

@media (max-width: 500px) {
  .home_comp_cont {
    flex-direction: column;
    padding: 0px 10px 25px 10px;
  }

  .hone {
    font-size: 27px;
    margin-top: 40px;
  }

  img {
    height: 120px;
    margin-right: 0px;
  }

  .btn_home_cont button {
    font-size: 20px;
  }

  .btn_first_grooming {
    padding: 10px 25px;
  }

  .no_padding {
    padding: 0px 12px;
  }

  .left{
    width: 100%;
  }

  .not_my_phone{
    display: inline-block;
    margin-top: 5px;
  }
}

</style>