<template>
    <div :class="`${flex ? 'spinner flexo' : 'spinner' }`">
        <div
        :class="`${flex ? 'bounce1 pinko' : 'bounce1' }`"
        :style="`${red ? 'background-color: red;' : ''}`"></div>
        <div
        :class="`${flex ? 'bounce2 pinko' : 'bounce2' }`"
        :style="`${red ? 'background-color: red;' : ''}`"></div>
        <div 
        :class="`${flex ? 'bounce3 pinko' : 'bounce3' }`"
        :style="`${red ? 'background-color: red;' : ''}`"></div>
    </div>
</template>

<script>

export default {
    name: "Spinner_cx",
    props: {
      red: Boolean,
      flex: {
        type: Boolean,
        required: false
      },
    }
}
</script>


<style scoped>
.flexo{
  display: flex;
  width: auto !important;
}

.pinko{
  background-color: #db3e8d !important;
}

.spinner {
  /* margin: 100px auto 0; */
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 12px;
  height: 12px;
  background-color: #f1f1f1;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}
</style>