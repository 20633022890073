<template>
    <div class="row home_comp_cont ">
        <div class="page_loader_cont" v-if="page_loader">
            <div class="spinner_height">
                <spinner-circle :black="true"></spinner-circle>
            </div>
        </div>
        <div v-if="role && role === 'crew' && logged_in === true">
            <h2>Branches waiting line</h2>
            <div v-if="waiting_branchs && Array.isArray(waiting_branchs) === true && waiting_branchs.length > 0" class="row branches_cont">
                <div  v-for="(branch) in waiting_branchs" :key="branch.branch"   class="column branch_card">
                    <div class="branch_name">{{ remove_dash(branch.branch) }}</div>
                    <hr>
                    <div class="wait">{{ branch.line }}</div>
                </div>
            </div>
            <div v-else >
                <div v-if="waiting_branchs && waiting_branchs.msg">{{ waiting_branchs.msg }}</div>
                <div v-else>Error</div>
            </div>
        </div>
    </div>
</template>

<script>
import request from '../axios_request';
// FirststepGroomingView(customer_acc), login(not_logged)
// adminHome(crew_acc), 
import SpinnerCircle from '@/components/SpinnerCircle.vue';
import SocketioService from '@/services/socketio.service.js';
import { mapState, mapActions } from 'vuex';

export default {
    name: "AdminCompanyLineComp",
    components: {
        SpinnerCircle,
        // FirststepGroomingView
    },
    data: function () {
        return {
            page_loader: true,
            visible: false,
            waiting_branchs: null
        }
    },
    mounted() {
        if( localStorage.getItem('token') ){
            var token = localStorage.getItem('token');
            request.post('/api/system/is_logged', {token})//, config
            .then((response)=>{
            // console.log(response);
                if( response.status === 200 ){
                    // console.log(response.data);
                    this.set_logged_in(true);
                    this.role_(response.data.role);
                    this.admin_name_(response.data.name);

                    //loop in branch here
                    request.post('/api/session/company_line')//, config
                    .then((comp_line_res)=>{
                        this.waiting_branchs = comp_line_res.data;
                        this.page_loader = false;
                    })
                    .catch((e) => {
                        console.log(e);
                        this.page_loader = false;
                    });


                    /// use socket
                    SocketioService.socket.on('new_session', () => {
                        //loop in branch here
                        request.post('/api/session/company_line')//, config
                        .then((comp_line_res)=>{
                            this.waiting_branchs = comp_line_res.data;
                        })
                        .catch((e) => {
                            console.log(e);
                        });
                    });

                    /// use socket
                    SocketioService.socket.on('sess_update', () => {
                        //loop in branch here
                        request.post('/api/session/company_line')//, config
                        .then((comp_line_res)=>{
                            this.waiting_branchs = comp_line_res.data;
                        })
                        .catch((e) => {
                            console.log(e);
                        });
                    });

                }else{
                    //not logged
                    this.set_logged_in(false);
                    this.role_(null);
                    localStorage.clear();
                    this.$router.push({name: 'admin_login'});
                }

            }).catch((e) => {
                console.log(e);
                this.set_logged_in(false);
                this.role_(null);
                localStorage.clear();
                this.$router.push({name: 'admin_login'});
            });


        }else{
            this.set_logged_in(false);
            this.role_(null);
            localStorage.clear();
            this.$router.push({name: 'admin_login'});
        }


    },
    methods: {
        ...mapActions([
            "role_",
            "set_logged_in",
            'admin_name_'
        ]),
        remove_dash(txt){
            if( txt.includes("_") === true ){
                txt = txt.replaceAll("_", " ");
            }
            return txt;
        }
    },
    computed: {
        ...mapState([
            'role',
            'logged_in',
            // 'branch_id',
            // 'socket'
        ]),
    }
}
</script>


<style scoped>
h2{
    margin-bottom: 10px;
}
.branches_cont{
    flex-wrap: wrap;
}
.branch_card{
    min-width: 150px;
    height: 150px;
    background-color: white;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.08);
    padding: 10px;
    border-radius: 5px;
    font-size: 27px;
    font-weight: 400;
    margin-right: 15px;
    margin-top: 15px;
}

hr{
    width: 100%;
    margin: 0px;
    border: 1px solid #ccc;
}

.branch_name{
    font-size: 30px;
    font-weight: 500;
}
</style>