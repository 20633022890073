<template>
    <div class="home">
      <secondstepGrooming></secondstepGrooming>
    </div>
</template>
  
  <script>
  // @ is an alias to /src
  import secondstepGrooming from '@/components/secondstepGrooming.vue'
  
  export default {
    name: 'secondstepGroomingView',
    components: {
        secondstepGrooming
    }
  }
  </script>
  
  <style scoped>
    .home{
      display: flex;
      justify-content: center;
      padding-top: 10%;
      
    }
  </style>
  