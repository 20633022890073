<template>
    <div>
        <div class="page_loader_cont" v-if="page_loader">
          <div class="spinner_height">
              <spinner-circle :black="true"></spinner-circle>
          </div>
        </div>




        <template v-else-if="role && role === 'crew'">
          <!-- show add new groomer -->
          <div class="sess_info_cont" v-if="show_add_groomer">
            
            <div class=" sess_info">
                <div class="close_cont">
                    <div class="close" @click="close_add_groomer()">close</div>
                </div>
                <!-- <hr> -->
                <div class="groomers">
                    <div class="groomers_heading">Add new groomer</div>
                    <input class="edit_cont_input" v-model="new_groomer_name" type="text" placeholder="groomer's name">
                </div>
                <!-- <div class="error error_msg d_error_msg">error</div> -->
                <div class="error_msg d_error_msg" v-show="add_err">{{ add_err }}</div>

                <div class="row btns_cont">
                    <!-- <button class="btn back_btn">cencel</button> -->
                    <div></div>
                    <!-- <button class="btn btn_first_grooming">update</button> -->

                    <button :class=" add_groomer_loading ? 'btn btn_first_grooming no_padding' : 'btn btn_first_grooming' "
                    @click="add_groomer()">
                      <div class="loading" v-if="add_groomer_loading" >
                        <Spinner/>
                      </div>
                      <div class="btn_txt" v-else >Add</div>
                    </button>

                </div>
            </div>
        </div>


          <!-- show update groomer -->
          <div class="sess_info_cont" v-if="show_update_groomer">
            
            <div class=" sess_info">
                <div class="close_cont">
                    <div class="close" @click="close_update_groomer()">close</div>
                </div>
                <!-- <hr> -->
                <div class="groomers" v-if="temp_groomer_update !== null">
                    <div class="groomers_heading">Update groomer data</div>
                    <input class="edit_cont_input" v-model="temp_groomer_update.name" type="text" placeholder="groomer name">
                </div>
                <!-- <div class="error error_msg d_error_msg">error</div> -->
                <div class="error_msg d_error_msg" v-show="update_err">{{ update_err }}</div>

                <div class="row btns_cont">
                    <!-- <button class="btn back_btn">cencel</button> -->
                    <div></div>
                    <!-- <button class="btn btn_first_grooming">update</button> -->

                    <button :class=" update_groomer_loading ? 'btn btn_first_grooming no_padding' : 'btn btn_first_grooming' "
                    @click="update_groomer()">
                      <div class="loading" v-if="update_groomer_loading" >
                        <Spinner/>
                      </div>
                      <div class="btn_txt" v-else >Update</div>
                    </button>

                </div>
            </div>
        </div>


        <!-- show delete cont -->
        <div class="sess_info_cont" v-if="show_groomer_delete">
            
            <div class=" sess_info">
                <div class="close_cont">
                    <div class="close" @click="close_delete()">close</div>
                </div>
                <!-- <div class="error error_msg d_error_msg">error</div> -->
                <div class="error_msg d_error_msg" v-show="d_d_error">{{ d_d_error }}</div>
                <div class="delete_title">
                    <div> are you sure you want to delete <b>{{groomers[current_delete_id].name}}</b> ?</div>
                </div>
                <div class="row btns_cont">
                    <!-- <button class="btn back_btn">cencel</button> -->
                    
                    <!-- <button class="btn btn_first_grooming">update</button> -->
                    <div></div>

                    <button :class=" delete_groomer_loading ? 'btn btn_first_grooming delete_btn no_padding' : 'btn btn_first_grooming delete_btn' "
                    @click="delete_groomer()">
                      <div class="loading" v-if="delete_groomer_loading" >
                        <Spinner/>
                      </div>
                      <div class="btn_txt delete_txt" v-else >Delete</div>
                    </button>

                </div>
            </div>
        </div>

          <div class="heading_cont_">
              <div class="row heading_cont_">
                <h3>All groomers</h3>
                <div class="money" @click="open_add_new()">Add new groomer</div>
              </div>
                <div class="scroll_y" v-if="groomers && Array.isArray(groomers) && groomers.length > 0">
                    <div class="result_table_cont " >
                        <table>
                            <tr class="no_hover">
                                <th>Name</th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr v-for="(groomer, i) in groomers" :key="groomer.id" >
                                <td  class="parent_name">{{ groomer.name }}</td>
                                <td   class="icon_t">
                                    <div class="" >
                                        <img @click="open_update_groomer(i)" src="@/assets/edit.svg" alt="editt" class="edit_icon" >
                                    </div>
                                </td>
                                <td   class="icon_t">
                                    <div class="" >
                                        <img @click="open_delete(i)" src="@/assets/delete.svg" alt="editt" class="edit_icon" >
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div v-else class="no_result no_result_center">you did not have any groomers.</div>
          </div>
        </template>
        <AdminFooter></AdminFooter>
    </div>
</template>
<script>
// @ is an alias to /src
import SpinnerCircle from './SpinnerCircle.vue';
import { mapActions, mapState  } from 'vuex';
import request from '../axios_request';
import Spinner from '@/components/Spinner.vue';
import AdminFooter from '@/components/AdminFooter.vue';

// import io from "socket.io-client";

export default {
    name: 'AdminManageGroomersComp',
    components: {
      SpinnerCircle,
      Spinner,
      AdminFooter
    },
    data: function () {
        return {
            // name: null,
            // password: null,
            // loading: false,
            // error: null,
            page_loader: true,
            groomers: null,
            show_groomer_delete: false,
            current_delete_id: null,
            delete_groomer_loading: false,
            d_d_error: null,
            show_add_groomer: false,
            new_groomer_name: null,
            add_err: null,
            add_groomer_loading: false,

            show_update_groomer: false,
            update_groomer_name: null,
            update_err: null,
            update_groomer_loading: false,

            temp_groomer_update: null,
        };
    },    

    mounted() {
      // this.logged_in;
      // 
      if( localStorage.getItem('token') ){
          var token = localStorage.getItem('token');
          request.post('/api/system/is_logged', {token})//, config
          .then((response)=>{
          // console.log(response);
              if( response.status === 200 ){
                  // console.log(response.data);
                  this.set_logged_in(true);
                  this.role_(response.data.role);
                  this.admin_name_(response.data.name);
                  if( response.data.role === 'vet' ){
                      this.page_loader = false;
                      return;
                  }
                  //load all groomers
                  request
                    .get('/api/groomer/get_all')//, config
                    .then((grommers_res) => {
                        this.groomers = grommers_res.data;
                        // console.log(grommers_res.data);
                        // load all groomers
                        
                        this.page_loader = false;
                    })
                    .catch((grommers_e) => {
                        console.log(grommers_e);
                        this.grommers_e = false;
                        this.page_loader = false;
                    });
                  return;
              }
              //not logged
              this.set_logged_in(false);
              this.role_(null);
              localStorage.clear();
              this.$router.push({name: 'admin_login'});
          }).catch((e) => {
              console.log(e);
              this.set_logged_in(false);
              this.role_(null);
              localStorage.clear();
              this.$router.push({name: 'admin_login'});
          });





      }else{
          this.set_logged_in(false);
          this.role_(null);
          localStorage.clear();
          this.$router.push({name: 'admin_login'});
      }


  },
  computed: {
      ...mapState([
          'role',
        //   'logged_in',
          // 'branch_id',
          // 'socket'
      ]),
    },

    methods: {
        ...mapActions([
            "set_logged_in",
            "set_socket",
            "role_",
            'admin_name_'
        ]),
        open_delete(i){
            this.show_groomer_delete = true;
            this.current_delete_id = i;
        },
        close_delete(){
            if( this.delete_groomer_loading && this.delete_groomer_loading === true ){
                return;
            }
            this.d_d_error = null;
            this.show_groomer_delete = false;
        },
        close_add_groomer(){
          if( this.add_groomer_loading && this.add_groomer_loading === true ){
                return;
            }
            this.add_err = null;
            this.show_add_groomer = false;
            this.new_groomer_name = null;
        },
        open_add_new(){
          this.show_add_groomer = true;
        },
        close_update_groomer(){
          if( this.update_groomer_loading && this.update_groomer_loading === true ){
                return;
            }
            this.update_err = null;
            this.show_update_groomer = false;
            this.temp_groomer_update = null;
        },
        open_update_groomer(i){
          this.show_update_groomer = true;
          this.temp_groomer_update = JSON.parse(JSON.stringify(this.groomers[i]));
          this.temp_groomer_update.fake_id = i;
        },
        update_groomer(){
          if( this.update_groomer_loading && this.update_groomer_loading === true ){
                return;
            }

            this.update_err = null;
            this.update_groomer_loading = true;

            if( !this.temp_groomer_update || !this.temp_groomer_update.name || this.temp_groomer_update.name.length === 0 ){
              this.update_err = 'must enter name';
              this.update_groomer_loading = false;
              return;
            }

            if( this.temp_groomer_update && this.temp_groomer_update.name && this.groomers && this.groomers.length > 0 && this.temp_groomer_update.name === this.groomers[this.temp_groomer_update.fake_id].name ){
              this.update_err = 'you did not change anything.';
              this.update_groomer_loading = false;
              return;
            }

            request
                .post('/api/groomer/update_groomer', {id: this.temp_groomer_update.id, name: this.temp_groomer_update.name})//, config
                .then((d) => {
                  console.log(d);
                  if(d && d.status && d.status === 200){
                    // load all groomers
                    request
                      .get('/api/groomer/get_all')//, config
                      .then((grommers_res) => {
                          this.groomers = grommers_res.data;

                          this.update_err = null;
                          this.update_groomer_loading = false;
                          this.close_update_groomer();
                      })
                      .catch((grommers_e) => {
                          console.log(grommers_e);

                          this.update_err = 'error';
                          this.update_groomer_loading = false;  
                      });
                  }else{
                    this.update_err = 'error';
                    this.update_groomer_loading = false;  
                  }

                })
                .catch((update_groomer_err) => {
                    console.log(update_groomer_err);
                    this.update_err = 'error';
                    this.update_groomer_loading = false;
                });
        },
        add_groomer(){
          if( this.add_groomer_loading && this.add_groomer_loading === true ){
                return;
            }
            this.add_err = null;
            this.add_groomer_loading = true;

            if(!this.new_groomer_name || this.new_groomer_name.length === 0){
              this.add_err = 'must enter name.';
              this.add_groomer_loading = false;
              return
            }
            request
                .post('/api/groomer/add_groomer', {name: this.new_groomer_name})//, config
                .then((d) => {
                  console.log(d);
                  if(d && d.status && d.status === 201){
                    // load all groomers
                    request
                      .get('/api/groomer/get_all')//, config
                      .then((grommers_res) => {
                          this.groomers = grommers_res.data;

                          this.add_err = null;
                          this.add_groomer_loading = false;
                          this.close_add_groomer();
                      })
                      .catch((grommers_e) => {
                          console.log(grommers_e);

                          this.add_err = 'error';
                          this.add_groomer_loading = false;  
                      });
                  }else{
                    this.add_err = 'error';
                    this.add_groomer_loading = false;  
                  }

                })
                .catch((add_groomer_err) => {
                    console.log(add_groomer_err);
                    this.add_err = 'error';
                    this.add_groomer_loading = false;
                });
        },
        delete_groomer(){
            if( this.delete_groomer_loading && this.delete_groomer_loading === true ){
                return;
            }
            this.d_d_error = null;
            this.delete_groomer_loading = true;
            request
                .post('/api/groomer/delete_groomer', {id: this.groomers[this.current_delete_id].id})//, config
                .then(() => {
                  // load all groomers
                  request
                    .get('/api/groomer/get_all')//, config
                    .then((grommers_res) => {
                        this.groomers = grommers_res.data;

                        this.d_d_error = null;
                        this.delete_groomer_loading = false;
                        this.close_delete();
                    })
                    .catch((grommers_e) => {
                        console.log(grommers_e);

                        this.d_d_error = 'error';
                        this.delete_groomer_loading = false;  
                    });
                })
                .catch((delete_groomer_err) => {
                    console.log(delete_groomer_err);
                    this.d_d_error = 'error';
                    this.delete_groomer_loading = false;
                });
        }
    },
}
</script>

<style scoped>
.c-admin_order_count_number_count{
  width: 300px;
  height: 150px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.08)
}

.c-admin_order_count_number_count{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #006837;
  font-size: 100px;
  font-weight: 500
}

.c-admin_order_count_number_count span{
  align-self: center;
  font-family: arial
}

.c-admin_order_count_title{
  font-size: 30px;
  margin-bottom: 10px;
  margin-top: 0px;
  font-weight: 500
}

.stat_cont{
  align-items: center;
}

.accounts_total{
  margin: 20px 0px 40px 0px;
}


.result_table_cont {
  width: 100%;
  border: 1px solid #f2f2f2;
  /* background-color: white; */
  /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08); */
  /* padding: 10px; */
  border-radius: 5px;
  min-width: 940px;
  max-width: 1450px;
  display: contents;
}
.result_table_cont table {
  width: 100%;
  border-collapse: collapse;
}
.result_table_cont table tr {
  border-bottom: 1px solid #E6E6E6;
}
.result_table_cont table tr:last-child {
  border-bottom: none;
}
.result_table_cont table tr th {
  text-align: left;
  font-size: 18px;
}
.result_table_cont table tr td {
  font-size: 17px;
  font-weight: 500;
}
.result_table_cont table tr td, .result_table_cont table tr th {
  padding: 10px 0px;
}

.scroll_y {
  overflow-x: auto;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);
  background-color: white;
  border-radius: 5px;
}

.edit_product_table{
  height: 30px;
}

.product_edit_cont{
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  padding: 0px 10px;
  width: 100vw;
  height: 100vh;
  justify-content: center;
}

.product_edit{
  width: 100%;
  max-width: 500px;
  background-color: white;
  padding: 15px;
  border-radius: 3px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 8%);
  border: 1px solid #ccc;
}

.product_edit .secondary {
  margin-top: 10px;
}

.no__{
  width: 100%;
  max-width: 500px;
  margin: auto;
  padding: 10px 15px;
  background-color: #cae6ff;
  border-radius: 3px;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.no_result_center{
  padding: 10px;
  border-radius: 3px;
  width: 100%;
  background-color: #cccccc8c;
  /* display: flex; */
  /* width: 100vw; */
  text-align: center;
  /* color: #673AB7; */
  color: #373737;
  font-weight: 500;
}
/* .pageloading_cont{
  height: 200px;
} */

.spinner_cont_page_{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25vh;
}

.spinner_cont_page_ .spinner_circle{
  height: 100px;
}

.manage{
  color: #317BEB;
  cursor: pointer;
  width: fit-content;
  margin: auto;
}

.sess_info_cont{
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #00000052;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sess_info{
  margin-top: 5%;
  background-color: white;
  width: 90%;
  max-width: 500px;
  padding: 10px;
  border-radius: 3px;
  overflow-y: auto;
  max-height: 85%;
}

.close_cont{
  display: flex;
  flex-direction: column;
}

.close{
  text-align: end;
  color: red;
  font-weight: bold;
  width: fit-content;
  align-self: end;
  cursor: pointer;
}

.groomers_heading{
  margin-bottom: 15px;
  font-weight: bold;
}

.groomer{
  cursor: pointer;
  background-color: #cccccca8;
  padding: 6px 10px;
  border-radius: 50px;
  margin-right: 5px;
  margin-bottom: 7px
}

.service{
  font-size: 20px;
  margin-bottom: 10px;
}

.other{
  border: 1px solid;
  padding: 3px 5px;
  border-radius: 3px;
  background-color: #33333314;
}

hr{
  margin-top: 25px;
  border: 1px solid;
}

.pet_name{
  font-size: 20px;
  text-align: center;
  width: fit-content;
  margin: auto;
  padding: 5px 10px;
  border: 2px solid;
  border-radius: 3px;
}

.pet_info{
  font-size: 20px;
  text-align: center;
  margin-top: 7px;
}

.groomer_selected{
  background-color: #0000ff70;
  cursor: auto;
}

.heading_cont_{
  justify-content: space-between;
  align-items: center;
}
.money{
  color: #317BEB;
  cursor: pointer;
  font-size: 17px;
  font-weight: 500;
}

.money_txt{
  font-size: 25px;
}

.money_info{
  padding: 10px 10px 10px 25px;
}
.groomers_names_cont{
  flex-wrap: wrap;
}

/* tr:nth-child(even){background-color: #f2f2f2;} */

/* .parent_name{
  padding-left: 12px !important;
} */

td, th {
  /* border: 1px solid #ddd; */
  padding: 8px !important;
}

.manage_td{
  margin: auto;
}

tr:hover{
  background-color: #317beb0d;
  /* cursor: pointer; */
}

.not_hover:hover{
  background-color: transparent;
  cursor: auto;
  
}

.no_money{
  text-align: center;
  color: #5d5d5d;
  padding: 15px 0px;
  font-size: 18px;
}

.tr_showering{
  border-left: 5px solid #2196f3;
}

.tr_waiting{
  border-left: 5px solid #ffc107;
}

.tr_finished{
  border-left: 5px solid green;
}

.service{
  cursor: pointer;
}

.pointer{
  cursor: pointer;
}

.date_input{
  width: 100%;
  height: 50px;
  /* background-color: #d2dbf3; */
  background-color: #fff;
  /* border: 1px solid #647193; */
  border: 1px solid #ccc;
  /* border-right: 0px; */
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 20px;
  /* -webkit-transition: 0.3s; */
  transition: 0.1s;
  outline: none;
  height: 50px;
  max-width: 250px;
}

.find_date_btn{
  height: auto;
  width: auto;
  font-size: 19px;
  max-height: 50px;
  height: 50px;
  margin-left: 5px;
}

.error{
  font-weight: 500;
  color: red;
}

.date_form{
  margin-bottom: 20px;
}

.table_hr{
  margin-top: 25px;
  margin-bottom: 15px;
  border: 1px solid #9a9a9a;
}

.edit_icon{
    height: 30px;
    display: flex;
    cursor: pointer;
}

.icon_t{
    width: 50px;
}


.no_hover{
  background-color: white !important
}

/* delete cont stuff */

.delete_txt{
  color: whitesmoke;
}

.btns_cont{
  justify-content: space-between;
  margin-top: 10px;
}

.delete_btn{
  background-color: red;
}

.btn_first_grooming{
  font-size: 20px;
  padding: 10px 35px;
  border-radius: 5px;
}

button{
  padding: 5px 15px !important;
  font-size: 18px;
}

.delete_title{
    font-size: 25px;
    font-weight: 500;
    /* text-align: center; */
    margin: 25px 0px;
}

.edit_cont_input{
    height: 40px;
    width: 100%;
    display: flex;
    margin: 15px 0px;
}

input{
    display: block;
    width: 100%;
    padding: 5px 10px;
    /* background-color: #d2dbf3; */
    background-color: #fff;
    /* border: 1px solid #647193; */
    border: 2px solid #ccc;
    /* border-right: 0px; */
    border-radius: 5px;
    font-size: 18px;
    /* -webkit-transition: 0.3s; */
    transition: 0.1s;
    outline: none;
    min-height: 40px;
    font-family: inherit;
    /* resize: vertical; */
    /* margin-bottom: 20px; */
    background-color: #cccccc08;
}

input:focus{
    border: 2px solid #317BEB;
    background-color: white;
}
</style>