<template>
    <div class="home_not_logged">
        <login></login>
    </div>
</template>

<script>
// @ is an alias to /src
// import AppHeader from '@/components/AppHeader.vue'
import Login from '@/components/Login.vue'

// import { mapActions, mapState, mapGetters  } from 'vuex';

export default {
    name: 'HomeNotLogged',
    components: {
        Login
    },
    methods: {},
}
</script>

<style scoped>
    .home_not_logged{
        align-items: center;
        justify-content: center;
        display: flex;
        margin-top: 12vh;
    }
</style>