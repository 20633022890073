<template>
    <div class="home_3">
      <thirdstepGrooming></thirdstepGrooming>
    </div>
</template>
  
<script>
  // @ is an alias to /src
  import thirdstepGrooming from '@/components/thirdstepGrooming.vue'
  
  export default {
    name: 'thirdstepGroomingView',
    components: {
        thirdstepGrooming
    }
  }
</script>
  
  <style scoped>
    .home_3{
      display: flex;
      justify-content: center;
      padding-top: 7vh;
      
    }
  </style>
  