<template>
    <div class="row home_comp_cont">
      <div class="page_loader_cont" v-if="page_loader">
          <div class="spinner_height">
              <spinner-circle :black="true"></spinner-circle>
          </div>
      </div>

      <SuccessSess v-if="go"></SuccessSess>

      <div v-if="show_d_prompt" class="delete_cont">
        <div class="delete_cont_2">
          <div class="delete_heading">Are you sure you want to delete '{{d_selected_name}}' ?</div>
          <div  class="row btn_del_cont">
            <button @click="close_d_prompt()" class="pop_btn cencel_btn">Cencel</button>
            <button class="pop_btn delete_btn">
              <red-spinner v-if="d_loading" :red="true"></red-spinner>
              <div v-else @click="delete_pet()">Delete</div>
            </button>
          </div>
          <div class="error_msg d_error_msg" v-show="d_error">{{ d_error }}</div>
        </div>
      </div>

        <div class="left" v-if="go === false">
            <!-- <img class="row img" src="@/assets/logo2.png" alt="logo"> -->
            <!-- <img class="row img" :src="linkk + '/' + 'logo2.png'" alt="logo"> -->
            <img class="row img" :src="linkk + '/' + company_logo" alt="logo">
            <div class=" powered_by_customer">
            powered by 
            <router-link to="/pharaohfrog"  class="ph_link"><b>pharaoh frog</b></router-link>
          </div>
            <button class="add_pet_2" @click="$router.push('/step-3-logged')">
              <div class="btn_txt" >Add Pet</div>
            </button>
            <div class="not_my_phone" @click="$router.push({name: 'home'})">go back to phone step?</div>


            <div class="pets_list" v-if="check_if_any_pet_selected">
              <h4>Your Pets</h4>
              <!-- <hr> -->
              <ul>
                <li v-for="pet in selected_pets_2" :key="pet.id">
                  <div class="row list_item_div">
                    <div v-if="pet.name">{{ pet.name }}</div>
                    <div v-else >{{ pet.pet_name }}</div>
                    <!-- <img class="row img" src="@/assets/dog_list.svg" alt="dog_list"> -->
                    <img v-if="pet.name" @click="open_d_prompt(pet.id, pet.name)" class="row img delete_icon" src="@/assets/delete-outline.svg" alt="dog_list">
                    <img v-else @click="open_d_prompt(pet.pet_id, pet.pet_name)" class="row img delete_icon" src="@/assets/delete-outline.svg" alt="dog_list">

                  </div>
                </li>
              </ul>
            </div>
            <!-- <button class="btn back_btn btn_first_grooming add_pet small_add_pet" @click="$router.push('/step-3')">Add Another Pet</button> -->
        </div>
        <div class="right" v-if="go === false">
            <h1 class="hone">Pet Grooming Service</h1>
            <!-- <div class="note">If I left my pet for more than 1 hour after grooming <br>I will be charged for 25 EGP for every hour until pickup.</div> -->
            <div class="note">If I left my pet for more than 1 hour after grooming I will be charged for 25 EGP for every hour until pickup.</div>
            <div class="error_msg" v-show="error">{{error }}</div>
            
            <div class="row btns_cont">
                <button class="btn back_btn btn_first_grooming" @click="$router.push('/step-4')">Back</button>
                <!-- <button class="btn btn_first_grooming" @click="$router.push('/grooming/step-2')">Next</button> -->
                <!-- <button class="btn btn_first_grooming"  @click="lets_go()">Agree & lets go</button> -->


              <button :class=" loading ? 'btn btn_first_grooming no_padding' : 'btn btn_first_grooming' "
              @click.prevent="lets_go()">
                <div class="loading" v-if="loading">
                  <Spinner/>
                </div>
                <div class="btn_txt" v-else >Agree & lets go</div>
              </button>

              </div>
        </div>
    </div>
  </template>
  
<script>
import request from '../axios_request';
import Spinner from '@/components/Spinner.vue';
import RedSpinner from '@/components/RedSpinner.vue';
import SpinnerCircle from '@/components/SpinnerCircle.vue';
import SuccessSess from '@/components/SuccessSess.vue';

import { mapState, mapActions } from 'vuex';

export default {
  name: 'fifthstepGrooming',
  components: {
    Spinner,
    SpinnerCircle,
    RedSpinner,
    SuccessSess
  },
  data: function () {
    return {
      loading: false,
      selected_pets_full: null,
      page_loader: true,
      show_d_prompt: false,
      d_id: null,
      d_selected_name: null,
      d_error: null,
      d_loading: false,
      error: null,
      go: false,
      linkk: process.env.VUE_APP_SERVER_ENDPOINT,
    }
  },
  created() {
    if( !this.parent_id || this.parent_id === null ){
      this.$router.push({ name: 'home' });
      return;
    }

      // check if logged in
      if( localStorage.getItem('token') ){
          var token = localStorage.getItem('token');
          request.post('/api/system/is_logged', {token})//, config
          .then((response)=>{
            if( response.status === 200 ){
                this.set_logged_in(true);
                this.role_(response.data.role);
                this.admin_name_(response.data.name);
                this.company_logo_(response.data.img);
                console.log(response);
              }
          }).catch(e => {
              console.log(e);
              //route to login
              this.$router.push({name: 'admin_login'});
          });
      }

    var old_pets = this.pets;
    var temp_service_holder = {
      shower: false,
      medical_shower: false,
      hair_cut: false,
      hair_trimming: false,
      nail_trimming: false,
      anal_gland: false,
      other: null,
      current: false,
      selected: false
    }
    //get selected pets full
    request.post('/api/pet/get_by_parent', {parent_id: this.parent_id})
      .then((res)=>{
          if( res.status === 200 ){
            //check if new pets arr not empty
            if( res.data && Array.isArray(res.data) && res.data.length > 0 ){
              res.data.forEach(function (pet) {
                Object.assign(pet, temp_service_holder);
                //check if its in old (if its in old merge else add empty services)
                if( old_pets && Array.isArray(old_pets) && old_pets.length > 0 ){
                  old_pets.forEach(function (pet_old) {
                    if( parseInt(pet.id) === parseInt(pet_old.id) ){
                      Object.assign(pet, pet_old);
                    }
                  });
                }
              });
            }else{
              res.data = [];
            }
            //save all in store
            this.pets_(res.data);
            console.log(this.check_if_any_pet_selected);
            if( !this.check_if_any_pet_selected ){
              this.$router.push({ name: 'step_3_logged' });
              return;
            }

            if( !this.check_if_all_have_services ){
              this.$router.push({ name: 'step_3_logged' });
              return;
            }

            //check if all have services


            this.page_loader = false;
          }else{
            // means error
            console.log('error created');
            this.$router.push({ name: 'home' });
          }
      })
      .catch(e => {
        // means error
        console.log(e);
        this.$router.push({ name: 'home' });
      });
  },
  computed: {
      ...mapState([
        'selected_pets',
        'phone',
        'parent_name',
        'parent_id',
        'pet_sessions',
        'pets',
        'company_logo'
      ]),
      check_if_any_pet_selected(){
        var result = false;
        if( this.pets && Array.isArray(this.pets) && this.pets.length > 0 ){
          this.pets.forEach(function (pet) {
            if( pet.selected && pet.selected === true ){
              result =  true;
            }
          });
        }
        return result;
      },
      selected_pets_2(){
      return this.pets.filter(pet => pet.selected && pet.selected === true)
      },

      check_if_all_have_services(){
        if( this.pets && Array.isArray(this.pets) && this.pets.length > 0 ){
          var pets_holder = this.selected_pets_2;
          var pets_with_no_service_arr = [];
          pets_holder.forEach(function(pet) {
            var have_service = false;
            console.log(have_service);
            for (const service in pet) {
              if( typeof pet[service] === "boolean" && pet[service] === true && service !== 'other' 
              && service !== 'current' && service !== 'selected'){

                have_service = true;

              }
            }
            if( !have_service ){
              if( !pets_with_no_service_arr.includes(pet['id']) ){
                pets_with_no_service_arr.push(pet['id']);
              }
            }
          });

          if( pets_with_no_service_arr.length === 0 ){
            return true;
          }else{
            return false;
          }
        }else{
          return false;
        }

      }
    },
    methods: {
      ...mapActions([
        "selected_pets_",
        'pet_sessions_',
        'pets_',
        'set_logged_in',
        'role_',
        'admin_name_',
        'company_logo_'
      ]),
      open_d_prompt(id, name){
        this.show_d_prompt = true;
        this.d_id = parseInt(id);
        this.d_selected_name = name;
        this.d_error = null;
      },

      close_d_prompt(){
        if( this.d_loading ){
          return;
        }
        this.show_d_prompt = false;
        this.d_id = null;
        this.d_selected_name = null;
      },
      delete_pet(){
        if( !this.check_if_any_pet_selected ){
          return;
        }

        var arr = this.pets;

        console.log(this.d_id);

        var index = arr.findIndex(pet => parseInt(pet.id) === parseInt(this.d_id) );
        var selected_index = this.selected_pets_2.findIndex(pet => parseInt(pet.id) === parseInt(this.d_id) );

        var temp_service_holder = {
          shower: false,
          medical_shower: false,
          hair_cut: false,
          hair_trimming: false,
          nail_trimming: false,
          anal_gland: false,
          other: null,
          current: false,
          selected: false
        }

        Object.assign(arr[index], temp_service_holder);

        this.pets_(arr);
          console.log(this.selected_pets_2);


        //!this.check_if_any_pet_selected this.selected_pets_2[0]
        if( this.check_if_any_pet_selected && this.current_id === this.d_id ){
          if( this.selected_pets_2[selected_index]  ){//1
            console.log('+1');
            this.current_id = this.selected_pets_2[selected_index].id;
          }else{
            if( this.selected_pets_2[selected_index - 1] ){
              console.log('-1');
              this.current_id = this.selected_pets_2[selected_index - 1].id;
            }else{
              console.log('0');
              this.current_id = this.selected_pets_2[0].id;
            }
          }
        }else{
          if( !this.check_if_any_pet_selected ){
            this.$router.push({ name: 'step_3_logged' });
          }
        }

        this.show_d_prompt = false;
        this.d_id = null;
        this.d_selected_name = null;
      },
      lets_go(){
        if( this.loading ){
          return;
        }

        this.loading = true;
        this.error = null;
            console.log(this.selected_pets_2);


        var sent_pets =  structuredClone(this.selected_pets_2);
        var parent_phone_ = this.phone;
        var parent_name_ = this.parent_name;
        var parent_id_ =  this.parent_id;
        var parent_obj = {
          parent_id: parent_id_,
          parent_name: parent_name_,
          parent_phone: parent_phone_
        }
        sent_pets.forEach(function (selected_pet_){
          delete selected_pet_.createdAt;
          delete selected_pet_.updatedAt;
          
          var pet_idd = selected_pet_.id;
          delete selected_pet_.id;
          selected_pet_.pet_id = pet_idd;
          var pet_name_ = selected_pet_.name;
          delete selected_pet_.name;
          selected_pet_.pet_name = pet_name_;
          var pet_type_ = selected_pet_.type;
          delete selected_pet_.type;
          selected_pet_.pet_type = pet_type_;
          var pet_breed_ = selected_pet_.breed;
          delete selected_pet_.breed;
          selected_pet_.pet_breed = pet_breed_;
          var pet_sex_ = selected_pet_.sex;
          delete selected_pet_.sex;
          selected_pet_.pet_sex = pet_sex_;
          var pet_birthday_ = selected_pet_.birthday;
          delete selected_pet_.birthday;
          selected_pet_.pet_birthday = pet_birthday_;
          Object.assign(selected_pet_, parent_obj);
        });


        //check if there is a pet with no Service

        if( this.check_if_all_have_services ){
          request.post('/api/session', {'pet_sessions': sent_pets})
          .then((res__) => {
          if( res__.status === 201 ){
              // localStorage.setItem('my_pets_count', sent_pets.length);
              console.log('added');
              // this.$router
              // .push({ name: 'home'
              // })
              // .then(() => { this.$router.go() })
              // this.$router.go('/');

              //mark
              // let r = this.$router.resolve({
              //   name: 'home'
              // });
              // window.location.assign(r.href)

              this.go = true;


              //this.loading = false;
          }else{
              console.log('error 00');
              this.loading = false;

          }
          })
          .catch((err__)=>{
          console.log(err__);
          this.loading = false;
          });
        }
        //selected_pets, pet_sessions
        // ()
        //var petss = this.pets;
        //this.pets_(petss);
        //console.log(this.selected_pets_2);
      }
    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
.small_add_pet{
  display: none;
  width: 100%;
}
.home_comp_cont{
  padding-bottom: 10%;
      padding-left: 30px;
    padding-right: 30px;
}
.btn_first_grooming{
    font-size: 20px;
    padding: 10px 35px;
    border-radius: 5px;
}
/* .btn_first_grooming:focus {
  border: 3px solid #555;
} */

  /* .btn_first_grooming{
    outline-color: #fff;
    outline-style: none;
  } */



.pet_name_cont{
  align-items: end;
  margin-bottom: 5px;
}


.label_img_cont{
  border: 2px solid #365889;
  border: 2px solid #c7c7c7;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 70px;
  background-color: lightgrey;
}

.label_img{
  height: 30px;
  width: auto;
}

.btns_cont{
  justify-content: space-between;
}

.mr{
  margin-left: 10px;
}

.label{
  font-size: 15px;
  color: #2C4260;
}

.next_to_input{
  width: 30vw;
  margin-right: 10px;
}

textarea{
  display: block;
  width: 100%;
  padding: 5px 15px;

    /* background-color: #d2dbf3; */
    background-color: #fff;
    /* border: 1px solid #647193; */
    border: 1px solid #ccc;
    /* border-right: 0px; */
    border-radius: 5px;
    font-size: 20px;
    /* -webkit-transition: 0.3s; */
    transition: 0.1s;
    outline: none;
    min-height: 70px;
    font-family: inherit;
    resize: vertical;
    margin-bottom: 20px;
}

.full_width{
  width: 100%;
}

.mr_bottom_15{
  margin-bottom: 25px;
}
.hone_3{
  margin-bottom: 20px;
}

.back_btn{
  border-width: 2px;
}

input:focus, textarea:focus {
  border: 1px solid #317BEB;
}

.list_item_div{
  justify-content: space-between;
}

.list_item_div img{
  height: 25px;
}

.right{
  margin-left: 80px;
  max-width: 500px;
}

/* .left{
      min-width: 200px;
} */

.left img{
  margin-right: 0px;
}

.pets_list ul{
  list-style: none;
  padding: 0px 0px;
  margin-top: 0px;
  border-top: 1px solid #c7c7c7;
}

.pets_list ul li{
  /* border-bottom: 1px solid; */
  border-bottom: 1px solid #c7c7c7;
  padding-bottom: 16px;
  padding-top: 16px;
}

hr{
  margin: 0px;
  border: 1px solid #c7c7c7;
}

.check_list_cont{
  align-items: center;
  margin-bottom: 20px;
}


input[type=checkbox]{
  height: 20px;
  width: 20px;
}

/* input[type=checkbox]:before {
	font-family: "FontAwesome";
  content: "\f00c";
  font-size: 15px;
  color: transparent !important;
  background: #fef2e0;
  display: block;
  width: 15px;
  height: 15px;
  border: 1px solid black;
  margin-right: 7px;
} */

/* input[type=checkbox]:checked:before {

	color: black !important;
} */

.checkmark{
  font-size: 28px;
  margin-left: 10px;
}

label{
  width: fit-content;
}

.check_list_cont_m{
  margin-bottom: 15px;
}



  .btn_first_grooming{
  font-size: 20px;
  padding: 10px 35px;
  border-radius: 5px;
}

.note{
  font-size: 20px;
  margin-bottom: 35vh;
}

.back_btn{
  border-width: 2px;
}

.btns_cont{
  justify-content: space-between;
  /*margin-top: 35vh;*/
}

.add_pet{
  margin-top: 30px;
}

.big_add_pet{
      font-size: 18px;
    padding: 10px 5px;
    width: 100%;
}

/*Small devices (landscape phones, 576px and up)*/
@media (max-width: 800px) {
.small_add_pet{
  display: block;
}
.note{
  width: 100%;
}
.big_add_pet{
  display: none;
}
.btns_cont{
  margin-top: 30px;
}
  .home_comp_cont{
    flex-direction: column;
      padding-left: 0px;
    padding-right: 0px;
  }
  .btn_home_cont{
    margin-bottom: 0px;
  }
  .btn_home_cont{
    margin-top: 0px;
  }
  .hone{
    margin-top: 26px;
  }
  input, .next_to_input{
    width: 100%;
  }
  .right{
    margin-left: 0px;
  }
  .left{
    margin-right: 0px;
  }
  /* .add_pet_2{
    display: none;
  } */

  .not_my_phone{
    display: inline-block;
    margin-top: 5px;
  }
}

@media (max-width: 500px) {
  .home_comp_cont{
    flex-direction: column;
    padding: 0px 10px 25px 10px;
  }

  .hone{
    font-size: 27px;
    margin-top: 40px;
  } 

  img{
    height: 120px;
    margin-right: 0px;
  }

  .btn_home_cont button{
    font-size: 20px;
  }

  .btn_first_grooming{
    padding: 10px 25px;
  }

  .no_padding{
    padding: 0px 12px;
  }
  .left{
    margin-right: 0px;
  }

  .add_pet_2{
    display: none;
  }

  .not_my_phone{
    display: inline-block;
    margin-top: 5px;
  }
}

</style>
