<template>
    <div>
        <div class="page_loader_cont" v-if="page_loader">
            <div class="spinner_height">
                <spinner-circle :black="true"></spinner-circle>
            </div>
        </div>
        <template v-else-if="role && role === 'crew'">
            <div class="row heading_cont_">
                <h3>All Grooming Sessions</h3>
                <div class="money" @click="open_money()">money</div>
            </div>
            
            <div v-if=" sessions === '' || !sessions " class="no_result no_result_center">some error happend.</div>
            <div v-else-if=" sessions && Array.isArray(sessions) && sessions.length === 0 " class="no_result no_result_center">we dont have any grooming sessions.</div>
            <div v-else>
                <div class="scroll_y">
                    <div class="result_table_cont " v-if="sessions && Array.isArray(sessions) && sessions.length > 0">
                        <table class="c-admin_table"><!--style="direction: ltr"-->
                            <tr class="not_hover">
                                <th>#</th>
                                <th>Parent</th>
                                <th>Phone</th>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Groomer</th>
                                <th>Status</th>
                                <th>Breed</th>
                                <th>created</th>
                                <th v-if="company === 'pets_club'">Total</th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>

                            <tr v-for="(session, i) in sessions" :key="session.id" :class="`tr_${session.status}`" >
                                <td>{{ table_num(i) }}</td>
                                <td  class="parent_name">{{ session.parent_name }}</td>
                                <td  >{{ session.parent_phone }}</td>
                                <td >{{ session.pet_name }}</td>
                                <td >{{ session.pet_type }}</td>
                                <td >{{ session.groomer_name }}</td>
                                <td>{{ session.status }}</td>
                                <td>{{ session.pet_breed }}</td>

                                <td>{{ session.createdAt }}</td>
                                <td v-if="company === 'pets_club'">{{ session.services_total }}</td>

                                <td   class="">
                                    <div class="" >
                                        <img @click="open_edit_sess(i)" src="@/assets/edit.svg" alt="editt" class="edit_icon" >
                                    </div>
                                </td>
                                <td   class="">
                                    <div class="" >
                                        <img @click="open_delete(i)" src="@/assets/delete.svg" alt="editt" class="edit_icon" >
                                    </div>
                                </td>
                                <td   class="manage_td">
                                    <div class="manage" @click="manage_btn(i)">
                                        Manage
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </template>
        <VetHome v-else-if="role === 'vet'"></VetHome>
        <template v-else-if="role === 'super_admin'" >
            <div class="column super_form_cont">
                <h2>The World Is Yours</h2>
                <form class="super_form" enctype="multipart/form-data">
                    <input type="text" placeholder="username" v-model="super_username">
                    <input type="text" placeholder="password" v-model="super_password">
                    <input type="text" placeholder="role" v-model="super_role">
                    <input type="file" accept="image/*" @change="uploadImage($event)" ref="img" class="none" id="file-input" name="img">
                    <!-- <input type="file" id="imgg" name="img"> -->
                    <input type="text" placeholder="company" v-model="super_company">
                    <input type="text" placeholder="branch" v-model="super_branch">
                    <button @click.prevent="save_user()">
                        <div v-if="super_loading === true">Loading...</div>
                        <div v-else>Save</div>
                    </button>
                </form>
            </div>

        </template>
        <div v-else>error</div>


        <div class="sess_info_cont" v-if="show_sess_info">
            
            <div class=" sess_info">
                <div class="close_cont">
                    <div class="close" @click="close()">close</div>
                </div>
                <div class="groomers">
                    <div class="pet_name" v-if="current_sess">{{ current_sess.pet_name }}</div>
                    <!-- <div class="groomers_heading">pet info</div> -->
                    <div class="pet_info">{{ current_sess.pet_type }} - {{ current_sess.pet_sex }} - {{ current_sess.pet_breed }} - ({{ current_sess.pet_birthday }})</div>
                    <!-- <div class="pet_sex">{{ current_sess.pet_sex }}</div>
                    <div class="pet_breed">{{ current_sess.pet_breed }}</div>
                    <div class="pet_birthday">{{ current_sess.pet_birthday }}</div> -->
                </div>
                <hr>
                <div class="groomers">
                    <div class="groomers_heading">services</div>
                    <div class=" groomers_names_cont" v-if="current_sess !== null">
                        <div class="service other no_poienter" v-if="current_sess.health_condition">{{ current_sess.health_condition }}</div>
                        <div class="service other no_poienter" v-if="current_sess.behavior_notes">{{ current_sess.behavior_notes }}</div>

                        <div class="service no_poienter" v-if="current_sess.shower">shower</div>
                        <div class="service no_poienter" v-if="current_sess.medical_shower">medical shower</div>
                        <div class="service no_poienter" v-if="current_sess.chemical_shower && company === 'pets_club'">chemical shower</div>
                        <div class="service no_poienter" v-if="current_sess.hair_cut">hair cut</div>
                        <div class="service no_poienter" v-if="current_sess.hair_trimming">hair trimming</div>
                        <div class="service no_poienter" v-if="current_sess.nail_trimming">nail trimming</div>
                        <div class="service no_poienter" v-if="current_sess.anal_gland">anal gland</div>
                        <div class="service other no_poienter" v-if="current_sess.other">{{ current_sess.other }}</div>
                        
                        <div class="row groomer_input" v-if="company === 'pets_club' ">
                            <input type="number" placeholder="Services total" name="groomer_commission"  class="groomer_commission" v-model="service_t">
                            <button class="groomer_commission_btn" @click="add_service_total()">
                                <div class="loading" v-if="service_t_loading" >
                                    <Spinner/>
                                </div>
                                <div class="btn_txt" v-else >Enter</div>
                            </button>
                        </div>
                        <div class="err" v-if="service_err === true">Error</div>
                        <div class="good" v-if="service_good === true ">Saved</div>



                        



                    </div>
                </div>
                <hr>
                <div class="groomers">
                    <!-- <div class="pet_name" v-if="current_sess">{{ current_sess.pet_name }}</div> -->
                    <div class="groomers_heading">choose groomer</div>
                    <div class="row groomers_names_cont" v-if="groomers && Array.isArray(groomers) && groomers.length > 0">
                        <div 
                        :class="`${groomer.id === current_sess.groomer_id ? 'groomer groomer_selected' : 'groomer'}`"
                        v-for="groomer in groomers" :key="groomer.id" @click="select_groomer(groomer.id, groomer.name, )">
                            <spinner :black='true' v-if="groomer_id_loading !== null && groomer_id_loading === groomer.id"></spinner>
                            <div v-else>{{ groomer.name }}</div>
                        </div>
                    </div>
                    <div class="row groomer_input" v-if="company === 'pets_club' && groomers && Array.isArray(groomers) && groomers.length > 0">
                        <input type="number" placeholder="Groomer's commission" name="groomer_commission"  class="groomer_commission" v-model="groomer_t">
                        <button class="groomer_commission_btn" @click="add_groomer_total()">
                            <div class="loading" v-if="groomer_c_loading" >
                                <Spinner/>
                            </div>
                            <div class="btn_txt" v-else >Enter</div>
                        </button>
                    </div>
                    <div class="err" v-if="groomer_c_err === true">Error</div>
                    <div class="good" v-if="groomer_c_good === true ">Saved</div>
                </div>
                <hr>
                <!-- <div class="groomers_heading">choose groomer</div> -->
                
                <div class="groomers">
                    <div class="groomers_heading">change status</div>
                    <div class="row groomers_names_cont" v-if="status_ && Array.isArray(status_) && status_.length > 0">
                        <div 
                        :class="`${status === current_sess.status ? 'groomer groomer_selected' : 'groomer'}`"
                        v-for="(status, index) in status_" :key="index" @click="select_status(status)">
                            <spinner :black='true' v-if="status_txt_loading !== null && status_txt_loading === status"></spinner>
                            <div v-else>{{ status }}</div>
                        </div>
                    </div>
                </div>





                

                <hr>
                <div class="groomers">
                    <div class="groomers_heading">change services</div>
                    <div class="row groomers_names_cont" >
                        <div 
                        :class="`${current_sess.shower === true ? 'groomer groomer_selected service' : 'groomer service'}`"
                        @click="select_service('shower')">
                            <spinner :black='true' v-if="service_loading !== null && service_loading === 'shower'"></spinner>
                            <div v-else>shower</div>
                        </div>

                        <div 
                        :class="`${current_sess.medical_shower === true ? 'groomer groomer_selected service' : 'groomer service'}`"
                        @click="select_service('medical_shower')">
                            <spinner :black='true' v-if="service_loading !== null && service_loading === 'medical_shower'"></spinner>
                            <div v-else>medical shower</div>
                        </div>

                        <div v-if="company === 'pets_club'"
                        :class="`${current_sess.chemical_shower === true ? 'groomer groomer_selected service' : 'groomer service'}`"
                        @click="select_service('chemical_shower')">
                            <spinner :black='true' v-if="service_loading !== null && service_loading === 'chemical_shower'"></spinner>
                            <div v-else>chemical shower</div>
                        </div>

                        <div 
                        :class="`${current_sess.hair_cut === true ? 'groomer groomer_selected service' : 'groomer service'}`"
                        @click="select_service('hair_cut')">
                            <spinner :black='true' v-if="service_loading !== null && service_loading === 'hair_cut'"></spinner>
                            <div v-else>hair cut</div>
                        </div>

                        <div 
                        :class="`${current_sess.hair_trimming === true ? 'groomer groomer_selected service' : 'groomer service'}`"
                        @click="select_service('hair_trimming')">
                            <spinner :black='true' v-if="service_loading !== null && service_loading === 'hair_trimming'"></spinner>
                            <div v-else>hair trimming</div>
                        </div>

                        <div 
                        :class="`${current_sess.nail_trimming === true ? 'groomer groomer_selected service' : 'groomer service'}`"
                        @click="select_service('nail_trimming')">
                            <spinner :black='true' v-if="service_loading !== null && service_loading === 'nail_trimming'"></spinner>
                            <div v-else>nail trimming</div>
                        </div>

                        <div v-if="current_sess.pet_type !== null && current_sess.pet_type === 'dog'"
                        :class="`${current_sess.anal_gland === true ? 'groomer groomer_selected service' : 'groomer service'}`"
                        @click="select_service('anal_gland')">
                            <spinner :black='true' v-if="service_loading !== null && service_loading === 'anal_gland'"></spinner>
                            <div v-else>anal gland</div>
                        </div>


                    </div>
                </div>

                <hr v-if="current_sess.pet_type === 'dog' && company !== 'pets_club'">
                <div class="groomers" v-if="current_sess.pet_type === 'dog' && company !== 'pets_club'">
                    <div class="groomers_heading">Breed Size</div>
                    <div class="row groomers_names_cont" >
                        <div 
                        :class="`${current_sess.giant === true ? 'groomer groomer_selected pointer' : 'groomer pointer'}`" @click="select_breed_size(current_sess.giant)">
                            <spinner :black='true' v-if="breed_size_loading !== null && breed_size_loading === true"></spinner>
                            <div v-else>giant</div>
                        </div>
                    </div>
                </div>


            </div>
        </div>

        <div class="sess_info_cont" v-if="show_sess_edit">
            
            <div class=" sess_info">
                <div class="close_cont">
                    <div class="close" @click="close_edit()">close</div>
                </div>
                <!-- <hr> -->
                <div class="groomers">
                    <div class="groomers_heading">parent</div>
                    <input class="edit_cont_input" v-model="temp_sess_update.parent_name" type="text" placeholder="parent name">
                    <input class="edit_cont_input edit_phone_input" v-model="temp_sess_update.parent_phone" type="tel" placeholder="phone">
                </div>
                <hr>
                <div class="groomers">
                    <div class="groomers_heading no_mr">pet</div>
                    <div class="row pet_name_cont">

                        <input v-model="temp_sess_update.pet_name" class="edit_cont_input"  type="text" placeholder="pet name">
                        <div class="coulmn mr">
                            <div class="label" @click="choose_type('dog')">Dog</div>
                            <div @click="choose_type('dog')" :class=" temp_sess_update.pet_type && temp_sess_update.pet_type === 'dog' ? 'label_img_cont selected' : 'label_img_cont' ">
                                <img class="label_img" src="@/assets/dog.svg">
                            </div>
                        </div>
                        <div class="coulmn">
                            <div class="label" @click="choose_type('cat')">Cat</div>
                            <div @click="choose_type('cat')" :class=" temp_sess_update.pet_type && temp_sess_update.pet_type === 'cat' ? 'label_img_cont selected' : 'label_img_cont' ">
                                <img class="label_img" src="@/assets/cat.svg">
                            </div>
                        </div> 
                    </div> 

                                       
                    <div class="row pet_name_cont edit_cont">
                        <input class="edit_cont_input"  v-model="temp_sess_update.pet_breed" type="text" placeholder="breed">
                        <div class="column mr">
                            <div class="label" @click="choose_sex('male')">Male</div>
                            <div @click="choose_sex('male')" :class=" temp_sess_update.pet_sex && temp_sess_update.pet_sex === 'male' ? 'label_img_cont selected' : 'label_img_cont' ">
                                <img class="label_img" src="@/assets/male.svg">
                            </div>
                        </div>
                        <div class="coulmn">
                            <div class="label" @click="choose_sex('female')">Female</div>
                            <div 
                            @click="choose_sex('female')"
                            :class="  temp_sess_update.pet_sex &&  temp_sess_update.pet_sex === 'female' ? 'label_img_cont selected' : 'label_img_cont' ">
                                <img class="label_img" src="@/assets/female.svg">
                            </div>
                        </div>
                    </div>


                    <input class="edit_cont_input edit_cont_input_date"  v-model="temp_sess_update.pet_birthday" type="date" placeholder="pet birthday">
                </div>
                <!-- <div class="error error_msg d_error_msg">error</div> -->
                <div class="error_msg d_error_msg" v-show="d_error">{{ d_error }}</div>

                <div class="row btns_cont">
                    <!-- <button class="btn back_btn">cencel</button> -->
                    <div></div>
                    <!-- <button class="btn btn_first_grooming">update</button> -->

                    <button :class=" update_sess_loading ? 'btn btn_first_grooming no_padding' : 'btn btn_first_grooming' "
                    @click="update_sess()">
                      <div class="loading" v-if="update_sess_loading" >
                        <Spinner/>
                      </div>
                      <div class="btn_txt" v-else >Update</div>
                    </button>

                </div>
            </div>
        </div>


        <!-- show delete cont -->
        <div class="sess_info_cont" v-if="show_sess_delete">
            
            <div class=" sess_info">
                <div class="close_cont">
                    <div class="close" @click="close_delete()">close</div>
                </div>
                <!-- <div class="error error_msg d_error_msg">error</div> -->
                <div class="error_msg d_error_msg" v-show="d_d_error">{{ d_d_error }}</div>
                <div class="delete_title">
                    <div> are you sure you want to delete session number <b>{{ this.sessions.length - this.current_sess_id }}</b> ?</div>
                </div>
                <div class="row btns_cont">
                    <!-- <button class="btn back_btn">cencel</button> -->
                    
                    <!-- <button class="btn btn_first_grooming">update</button> -->
                    <div></div>

                    <button :class=" delete_sess_loading ? 'btn btn_first_grooming delete_btn no_padding' : 'btn btn_first_grooming delete_btn' "
                    @click="delete_sess()">
                      <div class="loading" v-if="delete_sess_loading" >
                        <Spinner/>
                      </div>
                      <div class="btn_txt delete_txt" v-else >Delete</div>
                    </button>

                </div>
            </div>
        </div>



        <!-- show money cont -->
        <div class="sess_info_cont" v-if="show_money_info">
            
            <div class=" sess_info money_info">
                <div class="close_cont">
                    <div class="close" @click="close_money()">close</div>
                </div>
                <div v-if="company !== 'pets_club'">
                    <div v-if=" typeof groomer_money === 'object' && groomer_money !== null && Object.keys(groomer_money).length > 0 " >
                        <div v-for="(money, grommer_name) in groomer_money" :key="grommer_name">
                            <p class="money_txt">
                                {{ grommer_name }} : {{money}}
                            </p>
                        </div>
                    </div>
                    <div v-else class="no_money">you have no money.</div>
                </div>
                <div v-else class="pets_club_money">
                    <div v-if=" pets_club_groomer_money && Array.isArray(pets_club_groomer_money) && pets_club_groomer_money.length > 0 " >
                        <div v-for="(v, i) in pets_club_groomer_money" :key="i">

                            <p class="money_txt" v-for="(money, grommer_name) in v" :key="grommer_name">
                                {{ grommer_name }} : {{money}}
                            </p>
                        </div>
                    </div>
                    <div v-else class="no_money">you have no money.</div>
                </div>



            </div>
        </div>
        <AdminFooter></AdminFooter>
    </div>
</template>

<script>
// @ is an alias to /src
import request from '../axios_request';
import { mapState, mapActions } from 'vuex';
import SpinnerCircle from './SpinnerCircle.vue';
import Spinner from '@/components/Spinner.vue';
import VetHome from '@/components/VetHome.vue';
import AdminFooter from '@/components/AdminFooter.vue';
import dayjs from '../dayjs';
import SocketioService from '@/services/socketio.service.js';
import { useTimeAgo } from '@vueuse/core';
// import request from '../axios_request';

export default {

    name: 'AdminHome',
    components: {
        SpinnerCircle,
        Spinner,
        VetHome,
        AdminFooter
    },
    data: function () {
        return {
            page_loader: true,
            sessions: null,
            show_sess_info: false,
            groomers: null,
            groomer_id_loading: null,
            current_sess_id: null,
            status_: ['waiting', 'showering', 'finished'],
            status_txt_loading: null,
            breed_size_loading: null,
            show_money_info: false,
            timer: null,
            service_loading: null,
            show_sess_edit: false,
            temp_sess_update: null,
            update_sess_loading: false,
            d_error: null,
            show_sess_delete: false,
            d_d_error: null,
            delete_sess_loading: false,


            groomer_c_loading: false,
            groomer_t: null,
            groomer_c_err: false,
            groomer_c_good: false,

            service_t_loading: false,
            service_t: null,
            service_err: false,
            service_good: false,


            super_username: null,
            super_password: null,
            super_role: null,
            super_company: null,
            super_branch: null,
            img: null,
            super_loading: false,
        }
    },
    mounted() {
        // this.logged_in;
        // 
        if( localStorage.getItem('token') ){
            var token = localStorage.getItem('token');
            request.post('/api/system/is_logged', {token})//, config
            .then((response)=>{
            // console.log(response);
                if( response.status === 200 ){
                    // console.log(response.data);
                    this.set_logged_in(true);
                    this.role_(response.data.role);
                    this.admin_name_(response.data.name);
                    this.company_(response.data.company);
                    console.log(this.company);
                    if( response.data.role === 'vet' ){
                        this.page_loader = false;
                        return;
                    }
                    if( response.data.role !== 'crew' || response.data.role !== 'super_admin' ){
                        if( response.data.role === 'customer' ){
                            this.$router.push({name: 'home'});
                        }
                    }
                    //load all sess
                    request.post('/api/session/get_all_today_sess')//, config
                    .then((sess_res) => {
                        // do the time thing
                        if( sess_res.data.length > 0 ){
                            sess_res.data.forEach((sess_) =>{
                                var old_created_at = sess_.createdAt;
                                sess_.createdAt = useTimeAgo(old_created_at);
                            });
                        }
                        this.sessions = sess_res.data;
                        // console.log(sess_res.data);
                        // load all groomers
                        // this.page_loader = false;
                        request
                            .get('/api/groomer/get_all')//, config
                            .then((grommers_res) => {
                                this.groomers = grommers_res.data;
                                // console.log(grommers_res.data);
                                // load all groomers
                                
                                this.page_loader = false;
                            })
                            .catch((grommers_e) => {
                                console.log(grommers_e);
                                this.grommers_e = false;
                                this.page_loader = false;
                            });
                    })
                    .catch((sess_e) => {
                        console.log(sess_e);
                        this.sessions = '';
                        this.page_loader = false;
                    });
                    return;
                }
                //not logged
                this.set_logged_in(false);
                this.role_(null);
                localStorage.clear();
                this.$router.push({name: 'admin_login'});
            }).catch((e) => {
                console.log(e);
                this.set_logged_in(false);
                this.role_(null);
                localStorage.clear();
                this.$router.push({name: 'admin_login'});
            });

            /// use socket
            SocketioService.socket.on('new_session', (data) => {
                console.log(data);
                request.post('/api/session/get_all_today_sess')//, config
                    .then((sess_res) => {
                        // do the time thing
                        if( sess_res.data.length > 0 ){
                            sess_res.data.forEach((sess_) =>{
                                var old_created_at = sess_.createdAt;
                                sess_.createdAt = useTimeAgo(old_created_at);
                            });
                        }
                        this.sessions = sess_res.data;
                    })
                    .catch((sess_e) => {
                        console.log(sess_e);
                        this.sessions = '';
                    });
            });

            /// use socket
            SocketioService.socket.on('sess_update', (data) => {
                console.log('---------','---------');
                console.log(data);
                request.post('/api/session/get_all_today_sess')//, config
                    .then((sess_res) => {
                        // do the time thing
                        if( sess_res.data.length > 0 ){
                            sess_res.data.forEach((sess_) =>{
                                var old_created_at = sess_.createdAt;
                                sess_.createdAt = useTimeAgo(old_created_at);
                            });
                        }
                        this.sessions = sess_res.data;
                    })
                    .catch((sess_e) => {
                        console.log(sess_e);
                        this.sessions = '';
                    });
            });


        }else{
            this.set_logged_in(false);
            this.role_(null);
            localStorage.clear();
            this.$router.push({name: 'admin_login'});
        }


    },
    methods: {
        ...mapActions([
            "role_",
            "set_logged_in",
            'admin_name_',
            'company_'
        ]),
        add_service_total(){
            if( this.service_t_loading === true ){
                return;
            }
            this.service_t_loading = true;
            this.service_err = false;
            this.service_good = false;

            var id = this.current_sess.id;
            var t = this.service_t;
            request
                .post('/api/session/add_services_total', {
                    id,
                    services_total: t
                })//, config
                .then((d) => {
                    if( d && d.status === 200 ){
                        this.service_t_loading = false;
                        this.service_err = false; 
                        this.service_good = true;
                    }

                })
                .catch((sess_n_err) => {
                    console.log(sess_n_err);
                    this.service_t_loading = false;
                    this.service_err = true;
                });
        },
        add_groomer_total(){
            if( this.groomer_c_loading === true ){
                return;
            }
            this.groomer_c_loading = true;
            this.groomer_c_err = false;
            this.groomer_c_good = false;

            var id = this.current_sess.id;
            var t = this.groomer_t;
            request
                .post('/api/session/add_groomer_commission', {
                    id,
                    groomer_commission: t
                })//, config
                .then((d) => {
                    if( d && d.status === 200 ){
                        this.groomer_c_loading = false;
                        this.groomer_c_err = false;
                        this.groomer_c_good = true;
                    }
                })
                .catch((sess_n_err) => {
                    console.log(sess_n_err);
                    this.groomer_c_loading = false;
                    this.groomer_c_err = true;
                });
        },
        useTimeAgo_(d){
            var txt = useTimeAgo(d);
            console.log(txt);
            return txt;
        },
        time_(t) {
            return dayjs(t).fromNow(false);
        },
        table_num(i){
            // if( i === 0 ){
            //     return this.sessions.length;
            // }else if( (i + 1) === this.sessions.length ){
            //     return 1;
            // }else{
            //     return i + 1;
            // }
            return this.sessions.length - i;
        },
        birthday(t){
            var today = new Date();
            var birthDate = new Date(t);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            console.log(m);
            age = age * 12 + m;
            if( age === 0 ){
                const diffInMs   = today - birthDate;
                const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
                return diffInDays;
            }else{
                return age;
            }
        },
        manage_btn(i){
            
            this.show_sess_info = true;
            this.current_sess_id = i;
            console.log(this.current_sess);
            this.service_t = this.current_sess.services_total;
            this.service_err = false;
            this.service_good = false;

            this.groomer_t = this.current_sess.groomer_commission;
            this.groomer_c_err = false;
            this.groomer_c_good = false;
        },
        close(){
            this.show_sess_info = false;
            this.current_sess_id = null;
        },
        select_groomer(id, name){
            if( this.groomer_id_loading !== null ){
                return;
            }
            var sess_id = this.current_sess.id;
            this.groomer_id_loading = id;
            if( this.groomer_id_loading === this.current_sess.groomer_id ){
                console.log('already me.');
                this.groomer_id_loading = null;
                return;
            }



            request
                .post('/api/session/update', {
                    sess_id,
                    groomer_id: id,
                    groomer_name: name
                })//, config
                .then((sess_u) => {
                    console.log(sess_u);
                    //load the sess again
                    request
                        .post('/api/session/get_by_id', {
                            id: sess_id
                        })//, config
                        .then((sess_n) => {
                            // here
                            // this.current_sess = sess_n.data;
                            // do the time thing
                            var old_created_at = sess_n.data.createdAt;
                            sess_n.data.createdAt = useTimeAgo(old_created_at);

                            this.sessions[this.current_sess_id] = sess_n.data;
                            this.groomer_id_loading = null;
                        })
                        .catch((sess_n_err) => {
                            console.log(sess_n_err);
                            this.groomer_id_loading = null;
                        });
                })
                .catch((sess_u_err) => {
                    console.log(sess_u_err);
                    this.groomer_id_loading = null;
                });
        },
        select_status(status){
            console.log(status);
            if( this.status_txt_loading !== null ){
                return;
            }
            var sess_id = this.current_sess.id;
            this.status_txt_loading = status;
            if( this.status_txt_loading === this.current_sess.status ){
                console.log('already same status.');
                this.status_txt_loading = null;
                return;
            }



            request
                .post('/api/session/update', {
                    sess_id,
                    status
                })//, config
                .then((sess_u) => {
                    console.log(sess_u);
                    //load the sess again
                    request
                        .post('/api/session/get_by_id', {
                            id: sess_id
                        })//, config
                        .then((sess_n) => {
                            // here
                            // this.current_sess = sess_n.data;
                            // do the time thing
                            var old_created_at = sess_n.data.createdAt;
                            sess_n.data.createdAt = useTimeAgo(old_created_at);
                            this.sessions[this.current_sess_id] = sess_n.data;
                            this.status_txt_loading = null;
                        })
                        .catch((sess_n_err) => {
                            console.log(sess_n_err);
                            this.status_txt_loading = null;
                        });
                })
                .catch((sess_u_err) => {
                    console.log(sess_u_err);
                    this.status_txt_loading = null;
                });
        },
        select_service(service){
            var sess_id = this.current_sess.id;
            console.log(service, this.current_sess, this.company);
            if( this.service_loading === null ){
                

                // shower select
                if( service === 'shower' && this.current_sess.shower === false ){
                    this.service_loading = service;
                    //deselct medi
                    //and select shower
                    console.log(1);
                    request
                        .post('/api/session/update', {
                            sess_id,
                            service
                        })//, config
                        .then((sess_u) => {
                            console.log(sess_u);
                            //load the sess again
                            request
                                .post('/api/session/get_by_id', {
                                    id: sess_id
                                })//, config
                                .then((sess_n) => {
                                    // here
                                    // this.current_sess = sess_n.data;
                                    // do the time thing
                                    var old_created_at = sess_n.data.createdAt;
                                    sess_n.data.createdAt = useTimeAgo(old_created_at);
                                    this.sessions[this.current_sess_id] = sess_n.data;
                                    this.service_loading = null;
                                })
                                .catch((sess_n_err) => {
                                    console.log(sess_n_err);
                                    this.service_loading = null;
                                });
                        })
                        .catch((sess_u_err) => {
                            console.log(sess_u_err);
                            this.service_loading = null;
                        });

                //remove shower
                }else if( service === 'shower' && this.current_sess.shower === true ){
                    //['shower', 'medical_shower', 'hair_cut', 'hair_trimming', 'nail_trimming', 'anal_gland']
                    if(  this.current_sess.medical_shower === true || 
                    this.current_sess.hair_cut === true ||
                    this.current_sess.hair_trimming === true ||
                    this.current_sess.nail_trimming === true ||
                    this.current_sess.anal_gland === true){

                        this.service_loading = service;
                        var service_r = service;

                        request
                                .post('/api/session/update', {
                                    sess_id,
                                    service_r
                                })//, config
                                .then((sess_u) => {
                                    console.log(sess_u);
                                    //load the sess again
                                    request
                                        .post('/api/session/get_by_id', {
                                            id: sess_id
                                        })//, config
                                        .then((sess_n) => {
                                            // here
                                            // this.current_sess = sess_n.data;
                                            // do the time thing
                                            var old_created_at = sess_n.data.createdAt;
                                            sess_n.data.createdAt = useTimeAgo(old_created_at);
                                            this.sessions[this.current_sess_id] = sess_n.data;
                                            this.service_loading = null;
                                        })
                                        .catch((sess_n_err) => {
                                            console.log(sess_n_err);
                                            this.service_loading = null;
                                        });
                                })
                                .catch((sess_u_err) => {
                                    console.log(sess_u_err);
                                    this.service_loading = null;
                                });


                    }
                }else if( service === 'medical_shower' && this.current_sess.medical_shower === false ){
                    this.service_loading = service;
                    //deselct shower
                    //and select medical_shower
                    request
                        .post('/api/session/update', {
                            sess_id,
                            service
                        })//, config
                        .then((sess_u) => {
                            console.log(sess_u);
                            //load the sess again
                            request
                                .post('/api/session/get_by_id', {
                                    id: sess_id
                                })//, config
                                .then((sess_n) => {
                                    // here
                                    // this.current_sess = sess_n.data;
                                    // do the time thing
                                    var old_created_at = sess_n.data.createdAt;
                                    sess_n.data.createdAt = useTimeAgo(old_created_at);
                                    this.sessions[this.current_sess_id] = sess_n.data;
                                    this.service_loading = null;
                                })
                                .catch((sess_n_err) => {
                                    console.log(sess_n_err);
                                    this.service_loading = null;
                                });
                        })
                        .catch((sess_u_err) => {
                            console.log(sess_u_err);
                            this.service_loading = null;
                        });

                //remove medical_shower
                }else if( service === 'medical_shower' && this.current_sess.medical_shower === true ){
                    //['shower', 'medical_shower', 'hair_cut', 'hair_trimming', 'nail_trimming', 'anal_gland']
                    if(  this.current_sess.shower === true || 
                    this.current_sess.hair_cut === true ||
                    this.current_sess.hair_trimming === true ||
                    this.current_sess.nail_trimming === true ||
                    this.current_sess.anal_gland === true){

                        this.service_loading = service;
                        service_r = service;

                        request
                                .post('/api/session/update', {
                                    sess_id,
                                    service_r
                                })//, config
                                .then((sess_u) => {
                                    console.log(sess_u);
                                    //load the sess again
                                    request
                                        .post('/api/session/get_by_id', {
                                            id: sess_id
                                        })//, config
                                        .then((sess_n) => {
                                            // here
                                            // this.current_sess = sess_n.data;
                                            // do the time thing
                                            var old_created_at = sess_n.data.createdAt;
                                            sess_n.data.createdAt = useTimeAgo(old_created_at);
                                            this.sessions[this.current_sess_id] = sess_n.data;
                                            this.service_loading = null;
                                        })
                                        .catch((sess_n_err) => {
                                            console.log(sess_n_err);
                                            this.service_loading = null;
                                        });
                                })
                                .catch((sess_u_err) => {
                                    console.log(sess_u_err);
                                    this.service_loading = null;
                                });


                    }
                    
   
                }else if( service === 'chemical_shower' && this.current_sess.chemical_shower === false && this.company === 'pets_club' ){
                    this.service_loading = service;
                    //deselct medi
                    //and select shower
                    console.log('xxxxxxxxxxxxxxxxx');
                    request
                        .post('/api/session/update', {
                            sess_id,
                            service
                        })//, config
                        .then((sess_u) => {
                            console.log(sess_u);
                            //load the sess again
                            request
                                .post('/api/session/get_by_id', {
                                    id: sess_id
                                })//, config
                                .then((sess_n) => {
                                    // here
                                    // this.current_sess = sess_n.data;
                                    // do the time thing
                                    var old_created_at = sess_n.data.createdAt;
                                    sess_n.data.createdAt = useTimeAgo(old_created_at);
                                    this.sessions[this.current_sess_id] = sess_n.data;
                                    this.service_loading = null;
                                })
                                .catch((sess_n_err) => {
                                    console.log(sess_n_err);
                                    this.service_loading = null;
                                });
                        })
                        .catch((sess_u_err) => {
                            console.log(sess_u_err);
                            this.service_loading = null;
                        });

                //remove shower
                }else if( service === 'chemical_shower' && this.current_sess.chemical_shower === true && this.company === 'pets_club' ){
                    //['shower', 'medical_shower', 'hair_cut', 'hair_trimming', 'nail_trimming', 'anal_gland']
                    if(  this.current_sess.shower === true || 
                    this.current_sess.medical_shower === true ||
                    this.current_sess.hair_cut === true ||
                    this.current_sess.hair_trimming === true ||
                    this.current_sess.nail_trimming === true ||
                    this.current_sess.anal_gland === true){

                        this.service_loading = service;
                        service_r = service;

                        request
                                .post('/api/session/update', {
                                    sess_id,
                                    service_r
                                })//, config
                                .then((sess_u) => {
                                    console.log(sess_u);
                                    //load the sess again
                                    request
                                        .post('/api/session/get_by_id', {
                                            id: sess_id
                                        })//, config
                                        .then((sess_n) => {
                                            // here
                                            // this.current_sess = sess_n.data;
                                            // do the time thing
                                            var old_created_at = sess_n.data.createdAt;
                                            sess_n.data.createdAt = useTimeAgo(old_created_at);
                                            this.sessions[this.current_sess_id] = sess_n.data;
                                            this.service_loading = null;
                                        })
                                        .catch((sess_n_err) => {
                                            console.log(sess_n_err);
                                            this.service_loading = null;
                                        });
                                })
                                .catch((sess_u_err) => {
                                    console.log(sess_u_err);
                                    this.service_loading = null;
                                });


                    }
                
                
                }else if( service === 'hair_cut' && this.current_sess.hair_cut === false ){
                    this.service_loading = service;
                    //deselct hair_trimming
                    //and select hair_cut
                    request
                        .post('/api/session/update', {
                            sess_id,
                            service
                        })//, config
                        .then((sess_u) => {
                            console.log(sess_u);
                            //load the sess again
                            request
                                .post('/api/session/get_by_id', {
                                    id: sess_id
                                })//, config
                                .then((sess_n) => {
                                    // here
                                    // this.current_sess = sess_n.data;
                                    // do the time thing
                                    var old_created_at = sess_n.data.createdAt;
                                    sess_n.data.createdAt = useTimeAgo(old_created_at);
                                    this.sessions[this.current_sess_id] = sess_n.data;
                                    this.service_loading = null;
                                })
                                .catch((sess_n_err) => {
                                    console.log(sess_n_err);
                                    this.service_loading = null;
                                });
                        })
                        .catch((sess_u_err) => {
                            console.log(sess_u_err);
                            this.service_loading = null;
                        });

                //remove hair_cut
                }else if( service === 'hair_cut' && this.current_sess.hair_cut === true ){
                    //['shower', 'medical_shower', 'hair_cut', 'hair_trimming', 'nail_trimming', 'anal_gland']
                    if(  this.current_sess.shower === true || 
                    this.current_sess.medical_shower === true ||
                    this.current_sess.hair_trimming === true ||
                    this.current_sess.nail_trimming === true ||
                    this.current_sess.anal_gland === true){

                        this.service_loading = service;
                        service_r = service;

                        request
                                .post('/api/session/update', {
                                    sess_id,
                                    service_r
                                })//, config
                                .then((sess_u) => {
                                    console.log(sess_u);
                                    //load the sess again
                                    request
                                        .post('/api/session/get_by_id', {
                                            id: sess_id
                                        })//, config
                                        .then((sess_n) => {
                                            // here
                                            // this.current_sess = sess_n.data;
                                            // do the time thing
                                            var old_created_at = sess_n.data.createdAt;
                                            sess_n.data.createdAt = useTimeAgo(old_created_at);
                                            this.sessions[this.current_sess_id] = sess_n.data;
                                            this.service_loading = null;
                                        })
                                        .catch((sess_n_err) => {
                                            console.log(sess_n_err);
                                            this.service_loading = null;
                                        });
                                })
                                .catch((sess_u_err) => {
                                    console.log(sess_u_err);
                                    this.service_loading = null;
                                });


                    }
                }else if( service === 'hair_trimming' && this.current_sess.hair_trimming === false ){
                    this.service_loading = service;
                    //deselct hair_cut
                    //and select hair_trimming
                    request
                        .post('/api/session/update', {
                            sess_id,
                            service
                        })//, config
                        .then((sess_u) => {
                            console.log(sess_u);
                            //load the sess again
                            request
                                .post('/api/session/get_by_id', {
                                    id: sess_id
                                })//, config
                                .then((sess_n) => {
                                    // here
                                    // this.current_sess = sess_n.data;
                                    // do the time thing
                                    var old_created_at = sess_n.data.createdAt;
                                    sess_n.data.createdAt = useTimeAgo(old_created_at);
                                    this.sessions[this.current_sess_id] = sess_n.data;
                                    this.service_loading = null;
                                })
                                .catch((sess_n_err) => {
                                    console.log(sess_n_err);
                                    this.service_loading = null;
                                });
                        })
                        .catch((sess_u_err) => {
                            console.log(sess_u_err);
                            this.service_loading = null;
                        });

                //remove hair_trimming
                }else if( service === 'hair_trimming' && this.current_sess.hair_trimming === true ){
                    //['shower', 'medical_shower', 'hair_cut', 'hair_trimming', 'nail_trimming', 'anal_gland']
                    if(  this.current_sess.shower === true || 
                    this.current_sess.medical_shower === true ||
                    this.current_sess.hair_cut === true ||
                    this.current_sess.nail_trimming === true ||
                    this.current_sess.anal_gland === true){

                        this.service_loading = service;
                        service_r = service;

                        request
                                .post('/api/session/update', {
                                    sess_id,
                                    service_r
                                })//, config
                                .then((sess_u) => {
                                    console.log(sess_u);
                                    //load the sess again
                                    request
                                        .post('/api/session/get_by_id', {
                                            id: sess_id
                                        })//, config
                                        .then((sess_n) => {
                                            // here
                                            // this.current_sess = sess_n.data;
                                            // do the time thing
                                            var old_created_at = sess_n.data.createdAt;
                                            sess_n.data.createdAt = useTimeAgo(old_created_at);
                                            this.sessions[this.current_sess_id] = sess_n.data;
                                            this.service_loading = null;
                                        })
                                        .catch((sess_n_err) => {
                                            console.log(sess_n_err);
                                            this.service_loading = null;
                                        });
                                })
                                .catch((sess_u_err) => {
                                    console.log(sess_u_err);
                                    this.service_loading = null;
                                });


                    }
                }else if( service === 'nail_trimming' && this.current_sess.nail_trimming === false ){
                    this.service_loading = service;
                    //deselct hair_cut
                    //and select hair_trimming
                    request
                        .post('/api/session/update', {
                            sess_id,
                            service
                        })//, config
                        .then((sess_u) => {
                            console.log(sess_u);
                            //load the sess again
                            request
                                .post('/api/session/get_by_id', {
                                    id: sess_id
                                })//, config
                                .then((sess_n) => {
                                    // here
                                    // this.current_sess = sess_n.data;
                                    // do the time thing
                                    var old_created_at = sess_n.data.createdAt;
                                    sess_n.data.createdAt = useTimeAgo(old_created_at);
                                    this.sessions[this.current_sess_id] = sess_n.data;
                                    this.service_loading = null;
                                })
                                .catch((sess_n_err) => {
                                    console.log(sess_n_err);
                                    this.service_loading = null;
                                });
                        })
                        .catch((sess_u_err) => {
                            console.log(sess_u_err);
                            this.service_loading = null;
                        });

                //remove nail_trimming
                }else if( service === 'nail_trimming' && this.current_sess.nail_trimming === true ){
                    //['shower', 'medical_shower', 'hair_cut', 'hair_trimming', 'nail_trimming', 'anal_gland']
                    if(  this.current_sess.shower === true || 
                    this.current_sess.medical_shower === true ||
                    this.current_sess.hair_cut === true ||
                    this.current_sess.hair_trimming === true ||
                    this.current_sess.anal_gland === true){

                        this.service_loading = service;
                        service_r = service;

                        request
                                .post('/api/session/update', {
                                    sess_id,
                                    service_r
                                })//, config
                                .then((sess_u) => {
                                    console.log(sess_u);
                                    //load the sess again
                                    request
                                        .post('/api/session/get_by_id', {
                                            id: sess_id
                                        })//, config
                                        .then((sess_n) => {
                                            // here
                                            // this.current_sess = sess_n.data;
                                            // do the time thing
                                            var old_created_at = sess_n.data.createdAt;
                                            sess_n.data.createdAt = useTimeAgo(old_created_at);
                                            this.sessions[this.current_sess_id] = sess_n.data;
                                            this.service_loading = null;
                                        })
                                        .catch((sess_n_err) => {
                                            console.log(sess_n_err);
                                            this.service_loading = null;
                                        });
                                })
                                .catch((sess_u_err) => {
                                    console.log(sess_u_err);
                                    this.service_loading = null;
                                });


                    }
                }else if( service === 'anal_gland' && this.current_sess.anal_gland === false ){
                    this.service_loading = service;
                    //deselct hair_cut
                    //and select hair_trimming
                    request
                        .post('/api/session/update', {
                            sess_id,
                            service
                        })//, config
                        .then((sess_u) => {
                            console.log(sess_u);
                            //load the sess again
                            request
                                .post('/api/session/get_by_id', {
                                    id: sess_id
                                })//, config
                                .then((sess_n) => {
                                    // here
                                    // this.current_sess = sess_n.data;
                                    // do the time thing
                                    var old_created_at = sess_n.data.createdAt;
                                    sess_n.data.createdAt = useTimeAgo(old_created_at);
                                    this.sessions[this.current_sess_id] = sess_n.data;
                                    this.service_loading = null;
                                })
                                .catch((sess_n_err) => {
                                    console.log(sess_n_err);
                                    this.service_loading = null;
                                });
                        })
                        .catch((sess_u_err) => {
                            console.log(sess_u_err);
                            this.service_loading = null;
                        });

                //remove anal_gland
                }else if( service === 'anal_gland' && this.current_sess.anal_gland === true ){
                    //['shower', 'medical_shower', 'hair_cut', 'hair_trimming', 'nail_trimming', 'anal_gland']
                    if(  this.current_sess.shower === true || 
                    this.current_sess.medical_shower === true ||
                    this.current_sess.hair_cut === true ||
                    this.current_sess.hair_trimming === true ||
                    this.current_sess.nail_trimming === true){

                        this.service_loading = service;
                        service_r = service;

                        request
                                .post('/api/session/update', {
                                    sess_id,
                                    service_r
                                })//, config
                                .then((sess_u) => {
                                    console.log(sess_u);
                                    //load the sess again
                                    request
                                        .post('/api/session/get_by_id', {
                                            id: sess_id
                                        })//, config
                                        .then((sess_n) => {
                                            // here
                                            // this.current_sess = sess_n.data;
                                            // do the time thing
                                            var old_created_at = sess_n.data.createdAt;
                                            sess_n.data.createdAt = useTimeAgo(old_created_at);
                                            this.sessions[this.current_sess_id] = sess_n.data;
                                            this.service_loading = null;
                                        })
                                        .catch((sess_n_err) => {
                                            console.log(sess_n_err);
                                            this.service_loading = null;
                                        });
                                })
                                .catch((sess_u_err) => {
                                    console.log(sess_u_err);
                                    this.service_loading = null;
                                });


                    }
                }
            }
        },
        close_money(){
            this.show_money_info = false;
        },
        open_money(){
            this.show_money_info = true;
            console.log(this.groomer_money, this.finished_sess);
        },
        select_breed_size(giant){
            var sess_id = this.current_sess.id;
            if( this.breed_size_loading === true ){
                return;
            } 
            console.log(sess_id);
            this.breed_size_loading = true;
            giant = !giant;
            request
                .post('/api/session/update_breed_size', {
                    sess_id,
                    giant
                })//, config
                .then(() => {
                    request
                        .post('/api/session/get_by_id', {
                            id: sess_id
                        })//, config
                        .then((sess_n) => {
                            // here
                            // this.current_sess = sess_n.data;
                            this.sessions[this.current_sess_id] = sess_n.data;
                            this.breed_size_loading = null;
                        })
                        .catch((sess_n_err) => {
                            console.log(sess_n_err);
                            this.breed_size_loading = null;
                        });
                })
                .catch((sess_n_err) => {
                    console.log(sess_n_err);
                    this.breed_size_loading = null;
                });
        },
        open_edit_sess(i){
            this.show_sess_edit = true;
            this.current_sess_id = i;
            var sess_id = this.current_sess.id;
            this.temp_sess_update = JSON.parse(JSON.stringify(this.current_sess));
            console.log(this.temp_sess_update, sess_id);
        },
        close_edit(){
            if( this.update_sess_loading && this.update_sess_loading === true ){
                return;
            }
            this.d_error = null;
            this.show_sess_edit = false;
        },
        //func here
        choose_type(type){
            if( this.temp_sess_update && this.temp_sess_update !== null && this.temp_sess_update.pet_type && this.temp_sess_update.pet_type !== null ){
                this.temp_sess_update.pet_type = type;
            }
            
        },

        choose_sex(sex){
            if( this.temp_sess_update && this.temp_sess_update !== null && this.temp_sess_update.pet_sex && this.temp_sess_update.pet_sex !== null ){
                this.temp_sess_update.pet_sex = sex;
            }
        },
        update_sess(){
            if( this.update_sess_loading && this.update_sess_loading === true ){
                return;
            }
            this.d_error = null;
            this.update_sess_loading = true;

            //check if nothing changed
            if( this.current_sess.parent_name === this.temp_sess_update.parent_name
            && this.current_sess.parent_phone === this.temp_sess_update.parent_phone

            && this.current_sess.pet_name === this.temp_sess_update.pet_name
            && this.current_sess.pet_breed === this.temp_sess_update.pet_breed
            && this.current_sess.pet_type === this.temp_sess_update.pet_type
            && this.current_sess.pet_sex === this.temp_sess_update.pet_sex
            && this.current_sess.pet_birthday === this.temp_sess_update.pet_birthday
             ){
                this.d_error = 'you did not change anything';
                this.update_sess_loading = false;
                return;
             }

             // check if parent_name is empty
             if( !this.temp_sess_update.parent_name
             || this.temp_sess_update.parent_name === null
             || this.temp_sess_update.parent_name === undefined
             || this.temp_sess_update.parent_name === ''
             || this.temp_sess_update.parent_name.length === 0 ){
                this.d_error = 'must enter parent name';
                this.update_sess_loading = false;
                return;
             }

             // check if parent_phone is empty
             if( !this.temp_sess_update.parent_phone
             || this.temp_sess_update.parent_phone === null
             || this.temp_sess_update.parent_phone === undefined
             || this.temp_sess_update.parent_phone === ''
             || this.temp_sess_update.parent_phone.length < 6 ){
                this.d_error = 'must enter phone (more then 6 numbers)';
                this.update_sess_loading = false;
                return;
             }

             // check if pet_name is empty
             if( !this.temp_sess_update.pet_name
             || this.temp_sess_update.pet_name === null
             || this.temp_sess_update.pet_name === undefined
             || this.temp_sess_update.pet_name === ''
             || this.temp_sess_update.pet_name.length === 0 ){
                this.d_error = 'must enter pet name';
                this.update_sess_loading = false;
                return;
             }

             // check if pet_name is empty
             if( !this.temp_sess_update.pet_name
             || this.temp_sess_update.pet_name === null
             || this.temp_sess_update.pet_name === undefined
             || this.temp_sess_update.pet_name === ''
             || this.temp_sess_update.pet_name.length === 0 ){
                this.d_error = 'must enter pet name';
                this.update_sess_loading = false;
                return;
             }

             // check if pet_breed is empty
             if( !this.temp_sess_update.pet_breed
             || this.temp_sess_update.pet_breed === null
             || this.temp_sess_update.pet_breed === undefined
             || this.temp_sess_update.pet_breed === ''
             || this.temp_sess_update.pet_breed.length === 0 ){
                this.d_error = 'must enter pet breed';
                this.update_sess_loading = false;
                return;
             }


             // check if pet_birthday is empty
             if( !this.temp_sess_update.pet_birthday
             || this.temp_sess_update.pet_birthday === null
             || this.temp_sess_update.pet_birthday === undefined
             || this.temp_sess_update.pet_birthday === ''
             || this.temp_sess_update.pet_birthday.length === 0 ){
                this.d_error = 'must enter pet birthday';
                this.update_sess_loading = false;
                return;
             }
            


             //lets goo
             request
                .post('/api/session/update_sess_from_admin', this.temp_sess_update)//, config
                .then(() => {
                    this.d_error = null;
                    this.update_sess_loading = false;
                    this.close_edit();
                })
                .catch((sess_n_err) => {
                    console.log(sess_n_err);
                    this.d_error = 'error';
                    this.update_sess_loading = false;
                });


        },
        open_delete(i){
            //hola
            // console.log(this.current_sess, i);
            // this.show_sess_edit = true;
            this.show_sess_delete = true;
            this.current_sess_id = i;
            console.log(this.current_sess.id,  this.sessions.length - i);
        },
        close_delete(){
            if( this.delete_sess_loading && this.delete_sess_loading === true ){
                return;
            }
            this.d_d_error = null;
            this.show_sess_delete = false;
        },
        delete_sess(){
            if( this.delete_sess_loading && this.delete_sess_loading === true ){
                return;
            }
            this.d_d_error = null;
            this.delete_sess_loading = true;
            request
                .post('/api/session/delete_sess', {id: this.current_sess.id})//, config
                .then(() => {
                    this.d_d_error = null;
                    this.delete_sess_loading = false;
                    this.close_delete();
                })
                .catch((delete_sess_err) => {
                    console.log(delete_sess_err);
                    this.d_d_error = 'error';
                    this.delete_sess_loading = false;
                });
        },

        uploadImage(event) {

            //check file count
            if( event.target.files.length > 1 ){
                console.log('file count max is 1');
                return
            }

            for (let img in  event.target.files) {

                if( img === 'length' ){
                    return;
                }

                // img thatsent to server
                this.img = event.target.files[img];


                let reader = new FileReader
                reader.onload = e => {
                    this.parsed_image = e.target.result;
                }
                reader.readAsDataURL(event.target.files[img]);
            }

        },

        save_user(){
            if( this.super_loading === false ){
                this.super_loading = true;
                if( !this.img ){
                    console.log('no img');
                    return;
                }
                var data = new FormData();
                data.append('name', this.super_username);
                data.append('password', this.super_password);
                data.append('role', this.super_role);
                data.append('img', this.img);
                data.append('company', this.super_company);
                data.append('branch', this.super_branch);
                
                console.log(Array.from(data));
                request
                .put('/api/system/register', data)//, config
                .then(() => {
                    // this.d_d_error = null;
                    // this.delete_sess_loading = false;
                    // this.close_delete();
                })
                .catch((delete_sess_err) => {
                    console.log(delete_sess_err);
                    // this.d_d_error = 'error';
                    // this.delete_sess_loading = false;
                });
                this.super_loading = false;


            }

        }
    },
    computed: {
        ...mapState([
            'role',
            'logged_in',
            'company'
            // 'branch_id',
            // 'socket'
        ]),
        current_sess(){
            if( this.current_sess_id !== null ){
                return this.sessions[this.current_sess_id];
            }
            return null;
        },
        finished_sess(){
            return this.sessions.filter( session => 
                    session.status === 'finished'
                );
        },
        pets_club_groomer_money(){
            var arr = [];
            this.finished_sess.forEach((session) => {
                var groomer_name = session.groomer_name;
                var groomer_commission = session.groomer_commission;
                if( arr.length > 0 ){
                    arr.forEach(function(g) {
                        if( g[groomer_name] && g[groomer_name] !== null ){
                            g[groomer_name] += Number(groomer_commission);
                        }else{
                            g[groomer_name] = Number(groomer_commission);
                        }
                    });
                }else{
                    var obj = {};
                    obj[groomer_name] = groomer_commission;
                    arr.push(obj);
                }
            });
            return arr;
        },
        groomer_money(){
            var obj = {};
            this.finished_sess.forEach((session) => {
                // dog shower
                if( session.pet_type === 'dog' && session.shower === true ){
                    if( session.groomer_name && session.groomer_name !== null ){
                        if(obj[session.groomer_name] && obj[session.groomer_name] !== null ){
                            obj[session.groomer_name] += 20;
                        }else{
                            obj[session.groomer_name] = 20;
                        }
                        //if giant
                        if( session.giant && session.giant === true ){
                            obj[session.groomer_name] += 20;
                        }
                    }
                }

                // dog hair cut
                if( session.pet_type === 'dog' && session.hair_cut === true ){
                    if( session.groomer_name && session.groomer_name !== null ){
                        if(obj[session.groomer_name] && obj[session.groomer_name] !== null ){
                            obj[session.groomer_name] += 20;
                        }else{
                            obj[session.groomer_name] = 20;
                        }
                        //if giant
                        if( session.giant && session.giant === true ){
                            obj[session.groomer_name] += 20;
                        }
                    }
                }

                // dog medical shower
                
                if( session.pet_type === 'dog' && session.medical_shower === true ){
                    if( session.groomer_name && session.groomer_name !== null ){
                        if(obj[session.groomer_name] && obj[session.groomer_name] !== null ){
                            obj[session.groomer_name] += 40;
                        }else{
                            obj[session.groomer_name] = 40;
                        }
                    }
                }

                //herrre
                //hair_trimming
                if( session.pet_type === 'dog' && session.hair_trimming === true ){
                    if( session.groomer_name && session.groomer_name !== null ){
                        if(obj[session.groomer_name] && obj[session.groomer_name] !== null ){
                            obj[session.groomer_name] += 10;
                        }else{
                            obj[session.groomer_name] = 10;
                        }
                    }
                }


                //////-----------------------
                // cat shower
                
                    if( session.pet_type === 'cat' && session.shower === true ){
                        if( session.groomer_name && session.groomer_name !== null ){
                            if(obj[session.groomer_name] && obj[session.groomer_name] !== null ){
                                obj[session.groomer_name] += 20;
                            }else{
                                obj[session.groomer_name] = 20;
                            }
                        }
                    }


                // cat hair cut
                
                    if( session.pet_type === 'cat' && session.hair_cut === true ){
                        if( session.groomer_name && session.groomer_name !== null ){
                            if(obj[session.groomer_name] && obj[session.groomer_name] !== null ){
                                obj[session.groomer_name] += 20;
                            }else{
                                obj[session.groomer_name] = 20;
                            }
                        }
                    }


                // cat medical shower
                if( session.pet_type === 'cat' && session.medical_shower === true ){
                    if( session.groomer_name && session.groomer_name !== null ){
                        if(obj[session.groomer_name] && obj[session.groomer_name] !== null ){
                            obj[session.groomer_name] += 40;
                        }else{
                            obj[session.groomer_name] = 40;
                        }
                    }

                }


                //herrre
                //hair_trimming
                if( session.pet_type === 'cat' && session.hair_trimming === true ){
                    if( session.groomer_name && session.groomer_name !== null ){
                        if(obj[session.groomer_name] && obj[session.groomer_name] !== null ){
                            obj[session.groomer_name] += 10;
                        }else{
                            obj[session.groomer_name] = 10;
                        }
                    }
                }
            });
            return obj;
        }
    }
}
</script>

<style scoped>


.c-admin_order_count_number_count{
    width: 300px;
    height: 150px;
    background-color: #ffffff;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.08)
}

.c-admin_order_count_number_count{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #006837;
    font-size: 100px;
    font-weight: 500
}

.c-admin_order_count_number_count span{
    align-self: center;
    font-family: arial
}

.c-admin_order_count_title{
    font-size: 30px;
    margin-bottom: 10px;
    margin-top: 0px;
    font-weight: 500
}

.stat_cont{
    align-items: center;
}

.accounts_total{
    margin: 20px 0px 40px 0px;
}


.result_table_cont {
    width: 100%;
    border: 1px solid #f2f2f2;
    /* background-color: white; */
    /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08); */
    /* padding: 10px; */
    border-radius: 5px;
    min-width: 940px;
    max-width: 1450px;
}
.result_table_cont table {
    width: 100%;
    border-collapse: collapse;
}
.result_table_cont table tr {
    border-bottom: 1px solid #E6E6E6;
}
.result_table_cont table tr:last-child {
    border-bottom: none;
}
.result_table_cont table tr th {
    text-align: left;
    font-size: 18px;
}
.result_table_cont table tr td {
    font-size: 17px;
    font-weight: 500;
}
.result_table_cont table tr td, .result_table_cont table tr th {
    padding: 10px 0px;
}

.scroll_y {
    overflow-x: auto;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);
    background-color: white;
    border-radius: 5px;
}

.edit_product_table{
    height: 30px;
}

.product_edit_cont{
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
    padding: 0px 10px;
    width: 100vw;
    height: 100vh;
    justify-content: center;
}

.product_edit{
    width: 100%;
    max-width: 500px;
    background-color: white;
    padding: 15px;
    border-radius: 3px;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 8%);
    border: 1px solid #ccc;
}

.product_edit .secondary {
    margin-top: 10px;
}

.no__{
    width: 100%;
    max-width: 500px;
    margin: auto;
    padding: 10px 15px;
    background-color: #cae6ff;
    border-radius: 3px;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
}

.no_result_center{
    padding: 10px;
    border-radius: 3px;
    width: 100%;
    background-color: #cccccc8c;
    /* display: flex; */
    /* width: 100vw; */
    text-align: center;
    /* color: #673AB7; */
    color: #373737;
    font-weight: 500;
}
/* .pageloading_cont{
    height: 200px;
} */

.spinner_cont_page_{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25vh;
}

.spinner_cont_page_ .spinner_circle{
    height: 100px;
}

.manage{
    color: #317BEB;
    cursor: pointer;
    width: fit-content;
    margin: auto;
}

.sess_info_cont{
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000052;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sess_info{
    margin-top: 5%;
    background-color: white;
    width: 90%;
    max-width: 500px;
    padding: 10px;
    border-radius: 3px;
    overflow-y: auto;
    max-height: 85%;
}

.close_cont{
    display: flex;
    flex-direction: column;
}

.close{
    text-align: end;
    color: red;
    font-weight: bold;
    width: fit-content;
    align-self: end;
    cursor: pointer;
}

.groomers_heading{
    margin-bottom: 15px;
    font-weight: bold;
}

.groomer{
    cursor: pointer;
    background-color: #cccccca8;
    padding: 6px 10px;
    border-radius: 50px;
    margin-right: 5px;
    margin-bottom: 7px
}

.service{
    font-size: 20px;
    margin-bottom: 10px;
}

.other{
    border: 1px solid;
    padding: 3px 5px;
    border-radius: 3px;
    background-color: #33333314;
}

hr{
    margin-top: 25px;
    border: 1px solid;
}

.pet_name{
    font-size: 20px;
    text-align: center;
    width: fit-content;
    margin: auto;
    padding: 5px 10px;
    border: 2px solid;
    border-radius: 3px;
}

.pet_info{
    font-size: 20px;
    text-align: center;
    margin-top: 7px;
}

.groomer_selected{
    background-color: #0000ff70;
    cursor: auto;
}

.heading_cont_{
    justify-content: space-between;
    align-items: center;
}
.money{
    color: #317BEB;
    cursor: pointer;
    font-size: 17px;
    font-weight: 500;
}

.money_txt{
    font-size: 25px;
}

.money_info{
    padding: 10px 10px 10px 25px;
}
.groomers_names_cont{
    flex-wrap: wrap;
}

/* tr:nth-child(even){background-color: #f2f2f2;} */

/* .parent_name{
    padding-left: 12px !important;
} */

td, th {
    /* border: 1px solid #ddd; */
    padding: 8px !important;
}

.manage_td{
    margin: auto;
}

tr:hover{
    background-color: #317beb0d;
    /* cursor: pointer; */
}

.not_hover:hover{
    background-color: transparent;
    cursor: auto;
    
}

.no_money{
    text-align: center;
    color: #5d5d5d;
    padding: 15px 0px;
    font-size: 18px;
}

.tr_showering{
    border-left: 5px solid #2196f3;
}

.tr_waiting{
    border-left: 5px solid #ffc107;
}

.tr_finished{
    border-left: 5px solid green;
}

.service{
    cursor: pointer;
}

.pointer{
    cursor: pointer;
}

.edit_icon{
    height: 30px;
    display: flex;
    cursor: pointer;
}

.label_img_cont{
border: 2px solid #365889;
border: 2px solid #c7c7c7;
border-radius: 3px;
display: flex;
align-items: center;
justify-content: center;
height: 40px;
width: 60px;
background-color: lightgrey;
cursor: pointer;
}

.label_img{
height: 20px;
width: auto;
}
.pet_name_cont{
    align-items: end;
    margin-bottom: 5px;
}

.edit_cont_input{
    height: 40px;
    width: 100%;
    display: flex;
}

.edit_cont_input_date{
    width: 100%;
    margin-top: 15px;
}

.mr{
    margin-left: 10px;
    margin-right: 7px;
}

.selected{
    border-color: #365889;
    background-color: #36588942;
}

.back_btn{
    border-width: 2px;
}

.btn_first_grooming{
    font-size: 20px;
    padding: 10px 35px;
    border-radius: 5px;
}

.btns_cont{
    justify-content: space-between;
    margin-top: 10px;
}

.back_btn {
    background-color: transparent;
    border: 1px solid #5d8ced;
    /* font-weight: bold; */
    color: #5d8ced;
}

.edit_phone_input{
    margin-top: 10px;
}

.delete_title{
    font-size: 25px;
    font-weight: 500;
    /* text-align: center; */
    margin: 25px 0px;
}


input{
    display: block;
    width: 100%;
    padding: 5px 10px;
    /* background-color: #d2dbf3; */
    background-color: #fff;
    /* border: 1px solid #647193; */
    border: 2px solid #ccc;
    /* border-right: 0px; */
    border-radius: 5px;
    font-size: 18px;
    /* -webkit-transition: 0.3s; */
    transition: 0.1s;
    outline: none;
    min-height: 40px;
    font-family: inherit;
    /* resize: vertical; */
    /* margin-bottom: 20px; */
    background-color: #cccccc08;
}

input:focus{
    border: 2px solid #317BEB;
    background-color: white;
}

button{
    padding: 5px 15px !important;
    font-size: 18px;
}

.groomers_heading{
    font-size: 20px;
}

.delete_btn{
    background-color: red;
}

.delete_txt{
    color: whitesmoke;
}

.no_mr{
    margin: 0px;
}

.super_form_cont{
    align-items: center;
    margin-bottom: 50px;
}

.super_form_cont input{
    margin-bottom: 10px;
}

/* ---------------------------------------- */
.super_form{
    width: 100%;
    max-width: 500px;
    padding: 10px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid;
}

.groomer_commission{
    border: 1.5px solid #ccc;
    border-radius: 0px;
    background-color: #f8f6f6;
    border-right: 0px;
    border-radius: 5px 0px 0px 5px;
}

.groomer_commission:focus{
    /* border-color: #000; */
    border: 1.5px solid #317beb;
    border-right: 0px;
}

.groomer_commission_btn{
    background-color: #317beb;
    color: #f2f2f2;
    outline: none;
    border: 0px solid #000;
    cursor: pointer;
    border-radius: 0px 5px 5px 0px;
}

.groomer_input{
    margin-top: 5px;
}

.no_poienter{
    cursor: auto;
}

.err{
    font-size: 15px;
    color: red;
}

.good{
    font-size: 15px;
    color: green;  
}
</style>