import axios from 'axios'

const request = axios.create({
  baseURL: process.env.VUE_APP_SERVER_ENDPOINT,
})

var g_token = localStorage.getItem('token');

// request.interceptors.request.use(
//   request => {
//     const token = localStorage.getItem('token')
//     if (token) {
//       request.headers.Authorization = token
//     }
//     return request
//   },
//   error => {
//     return Promise.reject(error)
//   }
// )

if( g_token ){
    request.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
}


export default request