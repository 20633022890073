<template>
    <div class="row home_comp_cont ">
        <div class="page_loader_cont" v-if="page_loader">
            <div class="spinner_height">
                <spinner-circle :black="true"></spinner-circle>
            </div>
        </div>
        <FirststepGroomingView v-if="role === 'crew' || role === 'customer'"></FirststepGroomingView>
    </div>
</template>

<script>
import request from '../axios_request';
// FirststepGroomingView(customer_acc), login(not_logged)
// adminHome(crew_acc), 
import SpinnerCircle from '@/components/SpinnerCircle.vue';
import FirststepGroomingView from '../views/FirststepGroomingView.vue'
// import SocketioService from '@/services/socketio.service.js';
import { mapState, mapActions } from 'vuex';

export default {
    name: "homeComp",
    components: {
        SpinnerCircle,
        FirststepGroomingView
    },
    data: function () {
        return {
            page_loader: false,
            visible: false,
            line: null
        }
    },
    mounted() {
        if( localStorage.getItem('token') ){
            var token = localStorage.getItem('token');
            request.post('/api/system/is_logged', {token})//, config
            .then((response)=>{
            // console.log(response);
                if( response.status === 200 ){
                    // console.log(response.data);
                    this.set_logged_in(true);
                    this.role_(response.data.role);
                    this.admin_name_(response.data.name);
                }else{
                    //not logged
                    this.set_logged_in(false);
                    this.role_(null);
                    localStorage.clear();
                    this.$router.push({name: 'admin_login'});
                }

            }).catch((e) => {
                console.log(e);
                this.set_logged_in(false);
                this.role_(null);
                localStorage.clear();
                this.$router.push({name: 'admin_login'});
            });


        }else{
            this.set_logged_in(false);
            this.role_(null);
            localStorage.clear();
            this.$router.push({name: 'admin_login'});
        }


    },
    methods: {
        ...mapActions([
            "role_",
            "set_logged_in",
            'admin_name_'
        ]),
    },
    computed: {
        ...mapState([
            'role',
            'logged_in',
            // 'branch_id',
            // 'socket'
        ]),
    }
}
</script>


<style scoped>
.btn_home_cont{
    margin-top: 60px;
}

.btn_home_cont button{
    background-color: #317BEB;
    border: 0px;
    padding: 10px 35px;
    color: #F2F2F2;
    font-size: 30px;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500;
    border-radius: 3px;
    cursor: pointer;
}
/* .grooming_btn{ */
    /* margin-right: 15px; */
    /* width: 100%; */
/* } */

.clinic_btn{
    /* display: none; */
    margin-left: 15px;
}

/* h1{
    margin-top: 0px;
    font-size: 40px;
    font-weight: 500;
} */
img{
    height: 200px;
    margin-right: 80px;
}

.waiting_line{
    padding: 10px 20px;
    background-color: #317BEB;
    font-weight: 500;
    font-size: 25px;
    position: fixed;
    left: 10px;
    bottom: 20px;
    border-radius: 3px;
    color: white;

    background-color: transparent;
    color: black;


}

/*Small devices (landscape phones, 576px and up)*/
@media (max-width: 800px) {
    .home_comp_cont{
        flex-direction: column;
    }
    .btn_home_cont{
        margin-bottom: 0px;
    }
    .btn_home_cont{
        margin-top: 0px;
    }
    .hone{
        margin-top: 26px;
    }
}

@media (max-width: 500px) {
    .hone{
        font-size: 27px;
        margin-top: 40px;
    } 

    img{
        height: 170px;
        margin-right: 0px;
    }

    .btn_home_cont button{
        font-size: 20px;
    }
}
</style>