import { createStore } from 'vuex'
import request from '../axios_request';

export default createStore({
  state: {
    phone: null,
    parent_name: null,
    parent_id: null,
    pets: null,
    first_time: null,
    selected_pets: [],
    pet_sessions: [],
    logged_in: null,
    role: null,
    vet_line: null,
    admin_name: null,
    company_logo: null,
    company: null
    // [    {
    //   "id": 19,
    //   hair_cut: true,
    //   other: 'hola'
    // },
    // {
    //   "id": 20,
    //   shower: true
    // }],

   //crud

  },
  getters: {
  },
  mutations: {
    phone(state, data){
      return state.phone = data;
    },
    parent_name(state, data){
      return state.parent_name = data;
    },
    parent_id(state, data){
      return state.parent_id = data;
    },
    pets(state, data){
      return state.pets = data;
    },
    selected_pets(state, data){
      return state.selected_pets = data;
    },
    pet_sessions(state, data){
      return state.pet_sessions = data;
    },
    first_time(state, data){
      return state.first_time = data;
    },
    role(state, data){
      return state.role = data;
    },
    logged_in(state, data){
      return state.logged_in = data;
    },
    vet_line(state, data){
      return state.vet_line = data;
    },
    admin_name(state, data){
      return state.admin_name = data;
    },
    company_logo(state, data){
      return state.company_logo = data;
    },
    company(state, data){
      return state.company = data;
    },
  },
  actions: {
    phone_(context, data){
      context.commit("phone", data);
    },
    parent_name_(context, data){
      context.commit("parent_name", data);
    },
    parent_id_(context, data){
      context.commit("parent_id", data);
    },
    pets_(context, data){
      context.commit("pets", data);
    },
    selected_pets_(context, data){
      context.commit("selected_pets", data);
    },
    pet_sessions_(context, data){
      context.commit("pet_sessions", data);
    },
    first_time_(context, data){
      context.commit("first_time", data);
    },
    role_(context, data){
      context.commit("role", data);
    },
    set_logged_in(context, data){
      context.commit("logged_in", data);
    },
    vet_line_(context, data){
      context.commit("vet_line", data);
    },

    admin_name_(context, data){
      context.commit("admin_name", data);
    },

    company_logo_(context, data){
      context.commit("company_logo", data);
    },

    company_(context, data){
      context.commit("company", data);
    },

    is_logged(context, token){
      console.log('yyy');
      // console.log(axios);

      request.post('/api/system/is_logged', {token})//, config
        .then((response)=>{
          // console.log(response);
          if( response.status === 200 ){
            console.log(response.data);
            context.commit("logged_in", true);
            context.commit("role", response.data.role);
            context.commit("company_logo", response.data.img);
            context.commit("company", response.data.company);
            // context.commit("branch_id", response.data.branch_id);
            // console.log(response.data.branch_id);
            //get shipments
            // request.post('/api/system/shipment/get_all_open_shipments_by_user_id', {token: localStorage.getItem('token')})//, config
            // .then((response_)=>{
            //   if( Number(response_.data.count) > 0 ){
            //     context.commit("show_shipments_link", true);
            //   }else{
            //     context.commit("show_shipments_link", false);
            //   }
            // })
            // .catch( (err) => {
            //   console.log(err);
            // } )
            return;
          }
          //not logged
          context.commit("logged_in", false);
          context.commit("role", null);
          context.commit("img", null);
          context.commit("company", null);
          // context.commit("branch_id", null);
          localStorage.clear();
          // router.push("/");
          // if( route_name ){
          //   router.push({name: 'admin_login'});
          // }
        }).catch((e) => {
          console.log(e);
          context.commit("logged_in", false);
          context.commit("role", null);
          context.commit("img", null);
          context.commit("company", null);
          localStorage.clear();
        });
    }

  },
  modules: {
  }
})
