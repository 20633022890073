<template>
    <div class="home_3">
      <thirdstepLoggedGrooming></thirdstepLoggedGrooming>
    </div>
</template>
  
  <script>
  // @ is an alias to /src
  import thirdstepLoggedGrooming from '@/components/thirdstepLoggedGrooming.vue'
  
  export default {
    name: 'thirdstepLoggedGroomingView',
    components: {
        thirdstepLoggedGrooming
    }
  }
  </script>
  
  <style scoped>
    .home_3{
      display: flex;
      justify-content: center;
      padding-top: 7vh;
      
    }
  </style>
  