<template>
    <div class="home_logged">
        <app-header></app-header>
        <main class="home_main" >
            <AdminManageGroomersComp></AdminManageGroomersComp>
        </main>
        <!-- <main class="" v-if="['crew'].includes(role)">
            <crew-home></crew-home>
        </main> -->
    </div>
  </template>
  
  <script>
//   import { mapState  } from 'vuex';
  // @ is an alias to /src
   import AppHeader from '@/components/AppHeader.vue';
  import AdminManageGroomersComp from '@/components/AdminManageGroomersComp.vue';
  // import HomeLogged from '@/components/HomeLogged.vue'
  // import Test from '@/components/Test.vue'
    import { mapState } from 'vuex';

  
  export default {
    name: 'AdminManageGroomers',
    components: {
        AdminManageGroomersComp,
        AppHeader
      // HomeLogged,
      // Test
    },
    computed: {
      ...mapState([
        // 'logged_in',
        'role'
      ]),
    }
  }
  </script>

<style scoped>

    .home_main{
        padding: 50px 10px;
    }
</style>