<template>
    <div class="row  home_comp_cont">
      <div v-if="show_reset_form && phone__" class="delete_cont">
        <div class="delete_cont_2">
          <div class="delete_heading">If you go back you will lose all the data you have entered !</div>
          <div  class="row btn_del_cont">
            <button @click="close_reset_form_prompt()" class="pop_btn cencel_btn">Cencel</button>
            <button class="pop_btn delete_btn">
              <div @click="reset_form_()">Go Back</div>
            </button>
          </div>
        </div>
      </div>
        <div class="left">
          <img class="row img" :src="linkk + '/' + company_logo" alt="logo">
          <div class=" powered_by_customer">
            powered by 
            <router-link to="/pharaohfrog"  class="ph_link"><b>pharaoh frog</b></router-link>
          </div>
        </div>
        <div class="right">
            <h1 class="hone hone_link">Pet Grooming Service</h1>
            <div class="back_cont">
              <!-- <router-link class="col back_btn_mini" class-active="active" to="/" >back</router-link> -->
              <!-- <div @click="open_reset_form_prompt()" class="col back_btn_mini" class-active="active" to="/" >back</div> -->
              <form class="row btn_home_cont">
                  <input type="tel" placeholder="Phone" class="input" v-model="phone__"   @focus="magic_flag = true" @blur="magic_flag = false">
                  <!-- <button class="btn btn_first_grooming" @click.prevent="from_step_1()" >Next</button> -->
                    <button :class=" loading ? 'btn btn_first_grooming no_padding' : 'btn btn_first_grooming' "
                    @click.prevent="from_step_1()">
                      <div class="loading" v-if="loading" >
                        <Spinner/>
                      </div>
                      <div class="btn_txt" v-else >Next</div>
                    </button>
                  <!-- <button class="btn btn_first_grooming" @click="$router.push('/grooming/step-2')">Next</button> -->
              </form>
              <div v-if="error" class="err_single_line">{{ error }}</div>
            </div>
        </div>
        <div v-if="line !== null && magic_flag === false" class="waiting_line waiting_line__">waiting: {{line}}</div>
        <button  v-if="role === 'customer'" @click.prevent="logout()" class="waiting_line logout_">
            <spinner :black="true" v-if="logout_loading"></spinner>
            <div v-else-if="magic_flag === false">logout</div>
        </button>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
// import { mapActions } from 'vuex';
import request from '../axios_request';
import Spinner from '@/components/Spinner.vue';
import SocketioService from '@/services/socketio.service.js';


export default {
    name: "firststepGrooming",
    components: {
      Spinner
    },
    // props: {
    //   black: Boolean,
    //   flex: {
    //     type: Boolean,
    //     required: false
    //   },
    // },
    data: function () {
      return {
        phone__: null,
        loading: false,
        error: null,
        show_reset_form: false,
        line: null,
        linkk: process.env.VUE_APP_SERVER_ENDPOINT,
        logout_loading: false,
        magic_flag: false
      };
    },
    created() {
      // check if logged in
      if( localStorage.getItem('token') ){
          var token = localStorage.getItem('token');
          request.post('/api/system/is_logged', {token})//, config
          .then((response)=>{
            if( response.status === 200 ){
                this.set_logged_in(true);
                this.role_(response.data.role);
                this.admin_name_(response.data.name);
                this.company_logo_(response.data.img);
                console.log(response);
              }
          }).catch(e => {
              console.log(e);
              //route to login
              this.$router.push({name: 'admin_login'});
          });
      }




      this.phone__ = this.phone;


        // console.log(SocketioService.socket);
        SocketioService.socket.on('new_session', (data) => {
            console.log('new session', data);
            request
                .post('/api/session/line')
                .then((res)=>{
                    if( res.status === 200 ){
                        this.line = parseInt(res.data);
                    }else{
                        console.log('error  ..1');
                    }
                })
                .catch((err)=>{
                    console.log(err);
                });
        });

        // update when status change
        SocketioService.socket.on('waiting_update', (data) => {
            console.log('new session', data);
            request
                .post('/api/session/line')
                .then((res)=>{
                    if( res.status === 200 ){
                        this.line = parseInt(res.data);
                    }else{
                        console.log('error  ..1');
                    }
                })
                .catch((err)=>{
                    console.log(err);
                });
        });

        
        // if( localStorage.getItem('my_pets_count')){
            request
                .post('/api/session/line')
                .then((res)=>{
                    if( res.status === 200 ){
                        console.log(res.data);
                        this.line = parseInt(res.data);
                        // localStorage.removeItem("my_pets_count");

                        // setTimeout(() => {
                        //     this.visible = false;
                        //     // this.$router.push({ name: 'home' });
                        //     }, 7000)


                    }else{
                        console.log('error  ..1');
                    }

                })
                .catch((err)=>{
                    console.log(err);
                });
        // }

    },
    computed: {
      ...mapState([
        'phone',
        'parent_id',
        'first_time',
        'parent_name',
        'role',
        'company_logo'
      ]),
    },
    methods: {
      ...mapActions([
        "phone_",
        "parent_name_",
        "parent_id_",
        "first_time_",
        'set_logged_in',
        'role_',
        'company_logo_',
        'admin_name_'
      ]),
      logout(){
        // add logout logic
        if( this.logout_loading === false ){
          this.logout_loading = true;
          request.post("/api/system/logout")
              .then(()=>{
                  localStorage.clear();
                  this.set_logged_in(false);
                  this.logout_loading = false;
                  this.$router.push({name: 'admin_login'});
                  // this.show_mob_nav_var = false;
              }).catch(e => {
                  console.log(e);
                  localStorage.clear();
                  this.set_logged_in(false);
                  this.$router.push({name: 'admin_login'});
              });
          }
      },
      open_reset_form_prompt(){
        if( this.phone__ ){
          this.show_reset_form = true;
        }else{
          this.first_time_(null);
          this.$router.push({name: 'home'});
        }
        
      },
      close_reset_form_prompt(){
        this.show_reset_form = false;
      },
      reset_form_(){
        // this.$router.go(-1);
        // console.log()
        // window.location.href = window.location.host;
        this.$router.push({name: 'home'})
        .then(() => { this.$router.go('/') })
        .catch(e=>{console.log(e)})
      },
      from_step_1(){
        if( this.loading ){
          return;
        }
        this.error = null;
        if( this.phone__ && this.phone && this.phone__ === this.phone && this.first_time === true ){
          if( this.parent_id && this.parent_name ){
            this.$router.push({ name: 'grooming_step_2' });
            this.loading = false;
            return;
          }
        }
        var patern = /^\d+$/;
        //checkif we have a phone
        if( this.phone__ ){
          //check if its only numbers
          if( patern.test(this.phone__) ){
            //check if lenght is 7 or more
            if( this.phone__.length >= 7 ){
              this.loading = true;
            var data = {
              "phone": this.phone__,
            }
            //send it server
            request.post('/api/parent/is_new_parent', data)
              .then((response)=>{
                this.loading = false;
                if( response.status === 200 ){
                  if( response.data && response.data.new === false ){
                    //change id in store
                    this.phone_(response.data.data.phone);
                    this.parent_name_(response.data.data.name);
                    this.parent_id_(response.data.data.id);
                    this.first_time_(false);
                    this.$router.push({ name: 'step_3_logged' });
                  }else{
                    this.phone_(this.phone__);
                    this.parent_name_(null);
                    this.parent_id_(null);
                    this.first_time_(true);
                    this.$router.push({ name: 'grooming_step_2' });
                  }
                }else{
                  console.log(response, response.status);
                  console.log('error sending data to server.');
                  this.error = "error sending data to server.";
                  this.loading = false;
                }
              })
              .catch(e => {
                console.log(e);
                this.error = "error.";
                this.loading = false;
              });
            }else{
              this.error = "must enter 7 digts or more.";
              this.loading = false;
              console.log('must enter 7 digts or more.');
            }
          }else{
            this.error = "must enter numbers only.";
            this.loading = false;
            console.log('must enter numbers only.');
          }
        }else{
          this.error = "must enter phone number.";
          this.loading = false;
          console.log('must enter phone number.');
        }
      }
    }
}
</script>


<style scoped>
.btn_first_grooming{
    font-size: 20px;
    padding: 10px 35px;
    border-radius: 0px 5px 5px 0px;
}
/* .btn_first_grooming:focus {
  border: 3px solid #555;
} */

  /* .btn_first_grooming{
    outline-color: #fff;
    outline-style: none;
  } */

input{
    /* background-color: #d2dbf3; */
    background-color: #fff;
    /* border: 1px solid #647193; */
    border: 1px solid #ccc;
    border-right: 0px;
    border-radius: 5px 0px 0px 5px;
    padding: 5px 20px;
    font-size: 20px;
    /* -webkit-transition: 0.3s; */
    transition: 0.1s;
    outline: none;
}

.input:focus {
  border: 1px solid #317BEB;
  border-right: 0px;
}

.no_padding{
  padding: 0px 23px;
}

.hone_link{
  margin-bottom: 8px;
}



.err_single_line{
  color:red;
  font-size: 17px;
}

.waiting_line{
    padding: 10px 20px;
    background-color: #317BEB;
    font-weight: 500;
    font-size: 25px;
    position: fixed;
    left: 10px;
    bottom: 20px;
    border-radius: 3px;
    color: white;

    background-color: transparent;
    color: black;


}

.logout_{
  right: 10px;
  margin-top: 100px;
  left: auto;
  color: #317BEB;
  cursor: pointer;
  font-size: 16px;
  border: none;
  outline: none;
}

.logout_:hover{
  text-decoration: underline;
}

/*Small devices (landscape phones, 576px and up)*/
@media (max-width: 800px) {
  .home_comp_cont{
    flex-direction: column;
  }
  .btn_home_cont{
    margin-bottom: 0px;
  }
  .btn_home_cont{
    margin-top: 0px;
  }
  .hone{
    margin-top: 26px;
  }
  input{
    width: 100%;
  }
}

@media (max-width: 500px) {
  .home_comp_cont{
    flex-direction: column;
    padding: 0px 10px;
  }

  .hone{
    font-size: 27px;
    margin-top: 40px;
  } 

  img{
    height: 120px;
    margin-right: 0px;
  }

  .btn_home_cont button{
    font-size: 20px;
  }

  .btn_first_grooming{
    padding: 10px 25px;
  }

  .no_padding{
    padding: 0px 12px;
  }

  .waiting_line__{
    font-size: 20px;
  }

}
</style>