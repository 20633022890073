<template>
    <div class="row home_comp_cont ">
        <div class="page_loader_cont" v-if="page_loader">
            <div class="spinner_height">
                <spinner-circle :black="true"></spinner-circle>
            </div>
        </div>
        <div class="left">
            <!-- <img class="row img" src="@/assets/logo2.png" alt="logo"> -->
            <img class="row img" :src="linkk + '/' + company_logo" alt="logo">
            <div class=" powered_by_customer">
            powered by 
            <router-link to="/pharaohfrog"  class="ph_link"><b>pharaoh frog</b></router-link>
          </div>
            <div class="user_data_cont">
                <div class="name">{{parent_name}}</div>
                <div class="phone">{{phone}}</div>
                <div class="not_my_phone" @click="$router.push({name: 'home'})">go back to phone step?</div>
            </div>
        </div>
        <div class="right">
            <h1 class="hone">Pet Grooming Service</h1>
            <div class="mini_title">Choose A Pet For Grooming</div>
            <div class="row btn_home_cont">
                <div class="row pet_cards_cont" v-if="pets && Array.isArray(pets)">
                    <div v-for="(pet, i) in pets" :key="pet.id"
                    :class=" pet.selected === true ? 'column pet_card selected' : 'column pet_card ' "
                    @click="pet_add_handel(i)"
                    >
                        <img v-if="pet.type === 'dog'" class="label_img" src="@/assets/dog.svg">
                        <img v-else class="label_img" src="@/assets/cat.svg">
                        <div class="pet_name">{{pet.name}}</div>
                    </div>
                    <div class="column pet_card" @click="add_pet()">
                      <img class="label_img" src="@/assets/add.svg">
                      <div class="pet_name">Add Pet</div>
                    </div>
                </div>
            </div>
            <div class="error_msg" v-show="error">{{ error }}</div>
            <div class="row btns_cont">
              <div></div>
                <!-- <button class="btn back_btn btn_first_grooming" @click="$router.push('/grooming')">Back</button> -->
                <!-- <button class="btn btn_first_grooming" @click="$router.push('/grooming/step-2')">Next</button> -->
                <button class="btn btn_first_grooming"  @click="from_step_3_logged()">Next</button>
            </div>
        </div>
    </div>
</template>

<script>
import request from '../axios_request';
// import Spinner from '@/components/Spinner.vue';
import SpinnerCircle from '@/components/SpinnerCircle.vue';
import { mapState, mapActions } from 'vuex';
// import { mapState } from 'vuex';

export default {
    name: "thirdstepLoggedGrooming",
    components: {
        // Spinner,
        SpinnerCircle
    },
    data: function () {
        return {
            page_loader: true,
            // pets: null,
            // selected_pets_local: [],
            error: null,
            linkk: process.env.VUE_APP_SERVER_ENDPOINT,
        }
    },
    created() {
      console.log('hola');
      if( !this.parent_id || this.parent_id === null ){
        this.$router.push({ name: 'grooming_step_3' });
        return;
      }
      // check if logged in
      if( localStorage.getItem('token') ){
          var token = localStorage.getItem('token');
          request.post('/api/system/is_logged', {token})//, config
          .then((response)=>{
            if( response.status === 200 ){
                this.set_logged_in(true);
                this.role_(response.data.role);
                this.admin_name_(response.data.name);
                this.company_logo_(response.data.img);
                console.log(response);
              }
          }).catch(e => {
              console.log(e);
              //route to login
              this.$router.push({name: 'admin_login'});
          });
      }
      //get selected pets full
      var old_pets = this.pets;
      var temp_service_holder = {
        shower: false,
        medical_shower: false,
        hair_cut: false,
        hair_trimming: false,
        nail_trimming: false,
        anal_gland: false,
        other: null,
        current: false,
        selected: false
      }
      //get selected pets full
      request.post('/api/pet/get_by_parent', {parent_id: this.parent_id})
        .then((res)=>{
            if( res.status === 200 ){
              //check if new pets arr not empty
              if( res.data && Array.isArray(res.data) && res.data.length > 0 ){
                res.data.forEach(function (pet) {
                  Object.assign(pet, temp_service_holder);
                  //check if its in old (if its in old merge else add empty services)
                  if( old_pets && Array.isArray(old_pets) && old_pets.length > 0 ){
                    old_pets.forEach(function (pet_old) {
                      if( parseInt(pet.id) === parseInt(pet_old.id) ){
                        Object.assign(pet, pet_old);
                      }
                    });
                  }
                });
              }else{
                res.data = [];
              }
              //save all in store
              this.pets_(res.data);
              console.log(this.pets);
              this.page_loader = false;
            }else{
              // means error
              console.log('error created');
              this.$router.push({ name: 'home' });
            }
        })
        .catch(e => {
          // means error
          console.log(e);
          this.$router.push({ name: 'home' });
        });
    },
    computed: {
        ...mapState([
        'phone',
        'parent_name',
        'parent_id',
        'pets',
        'company_logo'

        // 'selected_pets'
        ]),
    },
    methods: {
      ...mapActions([
        // "selected_pets_",

        'pets_',
        'set_logged_in',
        'role_',
        'admin_name_',
        'company_logo_'
        // 'pet_sessions_'
      ]),
      pet_add_handel(i){
        // console.log(i, this.pets[i]);
        if( this.pets[i] ){
          if( this.pets[i].selected && this.pets[i].selected === true ){
            this.pets[i].selected = false;
          }else{
            this.pets[i].selected = true;
            this.error = null;
          }
        }
      },
      from_step_3_logged(){
        this.error = null;
        var lets_go = false;

        if( this.pets && Array.isArray(this.pets) && this.pets.length > 0 ){
          this.pets.forEach(function (pet) {
            if( pet.selected && pet.selected === true ){
              lets_go = true;
              return;
            }
          });
          if( lets_go === true ){
            this.$router.push({ name: 'grooming_step_4' });
          }else{
            this.error = 'must select pet for grooming.';
          }
        }else{
          this.error = 'must add and select pet for grooming.';
        }
      },
      add_pet(){
        this.$router.push({ name: 'grooming_step_3' });
      },
      go_home_grooming(){
        this.$router.push({name: 'step_3_logged'})
        .then(() => { this.$router.go('/') })
        .catch(e=>{console.log(e)})
      }
    }
}
</script>


<style scoped>
.btn_first_grooming{
    font-size: 20px;
    padding: 10px 35px;
    border-radius: 0px 5px 5px 0px;
}
/* .btn_first_grooming:focus {
  border: 3px solid #555;
} */

  /* .btn_first_grooming{
    outline-color: #fff;
    outline-style: none;
  } */

input{
    /* background-color: #d2dbf3; */
    background-color: #fff;
    /* border: 1px solid #647193; */
    border: 1px solid #ccc;
    border-right: 0px;
    border-radius: 5px 0px 0px 5px;
    padding: 5px 20px;
    font-size: 20px;
    /* -webkit-transition: 0.3s; */
    transition: 0.1s;
    outline: none;
}

.input:focus {
  border: 1px solid #317BEB;
  border-right: 0px;
}

.user_data_cont{
    margin-top: 35px;
}

.btns_cont{
  justify-content: space-between;
  margin-bottom: 50px;
}

.btn_first_grooming{
    font-size: 20px;
    padding: 10px 35px;
    border-radius: 5px;
}

.back_btn{
  border-width: 2px;
}

.pet_cards_cont{
    margin: 15px 0px 30px 0px;
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 120px 120px 120px 120px;
    /* grid-template-rows: 120px 120px 120px 120px; */
    /* column-gap: 10px;
    row-gap: 15px; */
}

.pet_card{
    border: 4px solid #5D8CED;
    border-color: #365889;
    border-radius: 3px;
    height: 120px;
    width: 120px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.pet_card img{
    height: 50px;
    width: auto;
}

.pet_name{
    font-weight: 600;
    color: #365889;
    font-size: 20px;
    margin-top: 5px;
}

.mini_title{
    font-size: 20px;
    font-weight: 500;
}

.selected{
    background-color: #36588942;
}

.not_my_phone{
  color: #5d8ced;
  font-weight: 500;
  /* margin-top: 10px; */
}

.not_my_phone:hover{
  text-decoration: underline;
  cursor: pointer;
}



/*Small devices (landscape phones, 576px and up)*/
@media (max-width: 800px) {
.small_add_pet{
  display: block;
}
.note{
  width: 100%;
}
.big_add_pet{
  display: none;
}
.btns_cont{
  margin-top: 30px;
}
  .home_comp_cont{
    flex-direction: column;
      padding-left: 0px;
    padding-right: 0px;
  }
  .btn_home_cont{
    margin-bottom: 0px;
  }
  .btn_home_cont{
    margin-top: 0px;
  }
  .hone{
    margin-top: 26px;
  }
  input, .next_to_input{
    width: 100%;
  }
  .right{
    margin-left: 0px;
  }
  .left{
    margin-right: 0px;
  }
  .not_my_phone{
    display: inline-block;
    margin-top: 5px;
  }
}

@media (max-width: 500px) {
  .home_comp_cont{
    flex-direction: column;
    width: 100%;
    padding: 0px 10px 25px 10px;
  }

  .hone{
    font-size: 27px;
    margin-top: 40px;
  } 

  img{
    height: 120px;
    margin-right: 0px;
  }

  .btn_home_cont button{
    font-size: 20px;
  }

  .btn_first_grooming{
    padding: 10px 25px;
  }

  .no_padding{
    padding: 0px 12px;
  }
  .left{
    margin-right: 0px;
  }

  .not_my_phone{
    display: inline-block;
    margin-top: 5px;
  }
}



</style>