<template>
    <div class="home_3">
      <SuccessSess></SuccessSess>
    </div>
</template>
  
  <script>
  // @ is an alias to /src
  import SuccessSess from '@/components/SuccessSess.vue'
  
  export default {
    name: 'SuccessSessV',
    components: {
        SuccessSess
    }
  }
  </script>
  
  <style scoped>
    .home_3{
      display: flex;
      justify-content: center;
      padding-top: 7vh;
      
    }
  </style>
  