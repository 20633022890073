<template>
    <main>
        <div class="column ">
            <div class="logoo">
                <img class="pharaohfrog_logo_page" src="@/assets/pharaohfrog.png" alt="pharaohfrog">
            </div>
            <p>pharaoh frog is the first pet grooming system in the world</p>
            <div><b>contact us? </b><a href="tel:+201094602675">+20 109 460 2675</a></div>
        </div>
    </main>

</template>

<script>

export default {
    name: 'AdminFooter',
}
</script>

<style scoped>

main{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 10px;
    font-size: 25px;
}
.footer_cont{
    justify-content: space-between;
    margin-top: 10px;
}

.pharaohfrog_logo{
    height: 25px;
    margin-left: 5px;
}
/* .powered_by{
    margin-top: 5px;
} */

a{
    text-decoration: none;
}

a:hover{
    text-decoration: underline;
}

.pharaohfrog_logo_page{
    height: 150px;
    display: block;
    margin: auto;
}
</style>