<template>
    <div class="home_logged">
        <app-header></app-header>
        <main class="home_main" >
            <AdminLongTimeNoSeeComp></AdminLongTimeNoSeeComp>
        </main>
        <!-- <main class="" v-if="['crew'].includes(role)">
            <crew-home></crew-home>
        </main> -->
    </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue';
import AdminLongTimeNoSeeComp from '@/components/AdminLongTimeNoSeeComp.vue';
// import CrewHome from '@/components/CrewHome.vue';

import { mapState } from 'vuex';

export default {
    name: 'AdminLongTimeNoSeeView',
    components: {
        AppHeader,
        AdminLongTimeNoSeeComp
    },
    computed: {
        ...mapState([
            'role'
        ]),
    }
}
</script>

<style scoped>

    .home_main{
        padding: 50px 10px;
    }
</style>