<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view/>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SocketioService from './services/socketio.service.js';
// import io from "socket.io-client"
export default {
  // data: function () {
  //   return {
  //     socket: {}
  //   }
  // },
  created() {
    SocketioService.setupSocketConnection();
  },
  beforeUnmount() {
    SocketioService.disconnect();
  },

  // created(){
  //   if( localStorage.getItem('token') ){
  //     // this.set_socket(io.connect("http://localhost:3000",
  //     // {
  //     //   auth: {
  //     //     token: localStorage.getItem('token'),
  //     //   },
  //     // }
  //     // ));
      
  //     // this.socket.emit('user_token', localStorage.getItem('token'));
  //   }
  //   // console.log(this.socket);
  // },
  // mounted() {
  //   if (localStorage.token) {
  //     // var route_name = null;
  //     // if(this.$route.name !== 'admin_login'){
  //     //     route_name = this.$route.name;
  //     // }
  //     this.is_logged(localStorage.token);
  //   }else{
  //     this.set_logged_in(false);
  //   }
  //   //get all branches
  //   // this.get_all_branches();
  // },
  methods: {
    ...mapActions([
      'is_logged',
      'set_logged_in',
      // 'set_socket',
      // 'get_all_branches'
    ])
  },
  computed: {
    ...mapState([
        // 'role',
        // 'branch_id',
        // 'socket'
    ])
}
}
</script>


<style>
*, *:before, *:after {
  box-sizing: border-box;
}

body {
  /* font-family: 'Poppins', sans-serif !important; */
  background-color: #F2F2F2;
  overflow-x: hidden;
  margin: 0px;
}

.row{
  display: flex;
  flex-direction: row;
}

.column{
  display: flex;
  flex-direction: column;
}

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: 'Poppins', sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; */
}

/* nav {
  padding: 30px;
  display: none;
} */

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.btn{
  background-color: #317BEB;
  border: 0px;
  padding: 10px 35px;
  color: #F2F2F2;
  font-size: 30px;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
  border-radius: 3px;
  cursor: pointer;
}

.img{
  /* height: 200px; */
  height: 160px;
  /* margin-right: 80px; */
}

.hone{
  margin-top: 0px;
  font-size: 40px;
  font-weight: 500;
}

.input{
  /* height: 70px; */
  height: 50px;
  /* width: 38vw; */
  width: 37vw;
}

.back_btn{
  background-color: transparent;
  border: 1px solid #5d8ced;
  /* font-weight: bold; */
  color: #5d8ced;
}

.back_btn_mini{
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: #317BEB;
  cursor: pointer;
  width: fit-content;
}
.back_btn_mini:hover{
  text-decoration: underline;
}

.error_msg{
  color: red;
  font-size: 20px;
  margin-bottom: 10px;
  border: 1px solid;
  background-color: #ff00002e;
  border-radius: 3px;
  padding: 7px 10px;
}

.left{
  margin-right: 80px;
}

.page_loader_cont{
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0px;
    align-items: center;
    left: 0px;
    background-color: #F2F2F2;
}
.page_loader_cont .spinner_height{
  width: 5vw;
  max-height: 100px;
}

.delete_icon{
  cursor: pointer;
}

.delete_cont{
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: #317beb69;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete_cont_2{
  background-color: #fff;
  width: fit-content;
  max-width: 500px;
  border-radius: 3px;
  padding: 10px 20px;
}

.btn_del_cont{
  justify-content: space-between;
  margin-top: 25px;
}

.delete_heading{
  font-size: 19px;
}

.pop_btn{
  border: none;
  background-color: transparent;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.pop_btn:hover{
  text-decoration: underline;
}

.cencel_btn{
  color: #365889;
}

.delete_btn{
  color: red;
}

.d_error_msg{
  margin-top: 15px;
}

.add_pet_2 {
  cursor: pointer;
  padding: 7px 5px;
  /* font-weight: bold; */
  font-size: 20px;
  font-family: 'Poppins', sans-serif !important;
  color: #317BEB;
  /* font-size: 20px; */
  margin-bottom: 10px;
  border: 2px solid;
  /* background-color: #317beb26; */
  border-radius: 3px;
  /* padding: 7px 10px; */
  margin-top: 30px;
  font-size: 15.3px;
  font-weight: 500;
  padding: 7px 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add_pet_2:hover {
    /* background-color: #317beb40; */
    background-color: #317beb26;
}

.not_my_phone{
  color: #5d8ced;
  font-weight: 500;
}

.not_my_phone:hover{
  text-decoration: underline;
  cursor: pointer;
}

.pharaohfrog_logo_customer{
  height: 25px;
  margin-left: 3px;
}

.powered_by_customer{
  font-size: 10px;
  margin-top: 5px;
}

.ph_link{
  color: black;
  text-decoration: none;
}

.ph_link:hover{
  text-decoration: underline;
}
</style>
